@mixin btn-reg($bg: link, $color: $white) {
	display: inline-block;
    padding: 1em 1.25em;
    text-decoration: none;
	font-family: $font-family-headings;
	font-style: italic;
	font-weight: 900;
	text-transform: uppercase;    
    border: 1px solid darken(theme($bg, base), 10%);
    background-color: theme($bg, base);
    color: $color;
    @include transition(all ease-in-out 200ms);
	&:link, &:visited, &:active {
		display: inline-block;
	    padding: 1em 1.25em;
	    text-decoration: none;
	    text-shadow: 0 1px 1px rgba(0,0,0,0.3);
	    border: 1px solid darken(theme($bg, base), 10%);
	    background-color: theme($bg, base);
	    color: $color;
	    @include transition(all ease-in-out 200ms);
	}
    &:hover,
    &:focus {
    	//text-shadow: 0 1px 1px rgba(0,0,0,0.5);
        background-color: lighten(theme($bg, base), 15%);
        color: $color;
        @include transition(all ease-in-out 200ms);
    }    
}


[class*="btn"] {
	@include btn-reg;
	//border-radius: 3px;
    line-height: 1em;
	cursor: pointer;

	small { display: block; line-height: 1em }

	&:focus {
		outline: 4px solid rgba(0,0,0,0.25);
	}

}

	%btn_text, .btn_text { @include btn-reg(text) }
	%btn_link, .btn_link { @include btn-reg(link) }
	%btn_primary, .btn_primary { @include btn-reg(primary) }
	%btn_secondary, .btn_secondary { @include btn-reg(secondary) }
	%btn_highlight, .btn_highlight { @include btn-reg(highlight) }
	%btn_accent, .btn_accent { @include btn-reg(accent) }
	%btn_success, .btn_success { @include btn-reg(success) }
	%btn_danger, .btn_danger { @include btn-reg(danger) }
	%btn_info, .btn_info { @include btn-reg(info) }
	%btn_review, .btn_review { @include btn-reg(review) }
	%btn_warning, .btn_warning { @include btn-reg(warning) }

	%btn-block, .btn-block { display: block; text-align: center; }

	.btn_close {
		float: right;
		margin: 0 0 0 15px;
		padding: 5px;
		font-size: 24px;
		line-height: 1em;
		text-align: center;
		border-radius: 3px;
		border: 1px solid darken($color-danger, 15%);
		background-color: $color-danger;
		color: #fff;
		opacity: 0.8;
		cursor: pointer;
		.close_img {
			width: 0.75rem;
		}
		&:hover {
			background-color: darken($color-danger, 20%);
		}
	}

	%btn--sm, .btn--sm {
		padding: 0.75em 1em !important;
		font-size: 0.925rem !important;
	}

	%btn--wide, .btn--wide {
		display: block;
		width: 100%;
	}