$mobile-button-bg: 			transparent; //$gray-300;
$toggle-bg: 				theme(secondary, base);
$toggle-color: 				$white;
$toggle-hover-bg: 			theme(secondary, light);

$nav-mobile-bg: 			theme(secondary, base);
$mobile-link-bg: 			transparent;
$mobile-link-color:			$white;
$mobile-hover-bg: 			theme(primary, base);
$mobile-hover-color: 		$white;
$mobile-active-bg: 			$white;
$mobile-active-color: 		theme(primary, base);

@mixin offcanvas {
	display: none;
	&.in { display: block; }
}

.mobile_button {

	line-height: 1em;
	text-align: left;
	background: $mobile-button-bg;

	.mobile_toggle {
		width: 48px; height: 41px;
		padding: 10px 11px 9px;
		background-color: $toggle-bg;
		border: none;
		border-radius: 4px;
		color: $toggle-color;
		&:hover, &:focus {
			outline: none;
			background-color: $toggle-hover-bg;
			cursor: pointer;
		}
	}

		.button-bars {
			display: inline-block;
			height: 100%;
			width: 26px;
		}

			.icon-bar {
				@include transition(transform ease-in-out 300ms);
				display: block;
				width: 100%;
				height: 3px;
				border-radius: 4px;
				background-color: $toggle-color;
				&:nth-child(2) { margin: 5px 0; }
			}

	b { display: none; }

	.canvas-slid & {
		.icon-bar {
			&:nth-child(2) { visibility: hidden; }
			&:nth-child(1),
			&:nth-child(3) {
				@include transition(transform ease-in-out 300ms);
			}
			&:nth-child(1) { transform: rotate(45deg); margin-top: 9px; }
			&:nth-child(3) { transform: rotate(-45deg); margin-top: -16px; }
		}
	}

}



.nav_mobile {

	@include offcanvas;
	position: fixed;
	z-index: 999999;
	top: 0; right: auto;
	bottom: 0; left: 0;
	overflow-y: auto;
	width: 90%;
	max-width: 320px;
	height: 100%;
	padding: 30px 15px;
	border-right: 3px solid $white;
	box-shadow: 2px 0 5px 0 rgba(0,0,0,0.5);
	background-color: $nav-mobile-bg;
	color: $white;

	@include media-breakpoint-up(md) { display: none !important; }

	h4 {
		font-size: 1.2em;
		font-weight: 800;
		text-transform: uppercase;
	}

	.nav_menu > .menu_item:not(:last-child) { border-bottom: 1px solid rgba(0,0,0,0.3); }
	.nav_menu > .menu_item:not(:first-child) { border-top: 1px solid rgba(255,255,255,0.2); }

	.nav_menu > .menu_item {

		&.active .menu_link {
			background-color: $mobile-active-bg;
			color: $mobile-active-color;
			&:hover, &:focus {
				outline: none;
				background-color: $mobile-active-bg;
				color: $mobile-active-color;
			}
		}

		&.open {
			> .menu_link { background-color: rgba(255,255,255,0.2); }
			.dropdown_menu { background-color: rgba(255,255,255,0.2); }
		}

	}	

		.nav_menu > .menu_item > .menu_link {
			padding: 12px 10px;
			font-weight: 600;
			background-color: $mobile-link-bg;
			color: $mobile-link-color;
			&:hover, &:focus {
				outline: none;
				background-color: $mobile-hover-bg;
				color: $mobile-hover-color;
			}
		}

	.dropdown_menu {

		.menu_item { font-size: 0.875em; }

			.menu_link {
				background-color: transparent;
				color: $white;
				&:hover, &:focus {
					outline: none;
					background-color: rgba(0,0,0,0.2);
					color: $white;					
				}				
			}

	}

	.mobile-toggle {

		font-size: 1.2em;
		color: #fff;
		text-decoration: none;

		img {
			display: inline-block;
			width: 0.8em;
			margin-right: 10px;
		}

	}

}

