.front_jumbo {

	padding: 2em 0;
	background-color: theme(primary, base);
	background-image: url("/_/images/layout/jumbo_bg-sm.jpg");
	background-repeat: no-repeat;
	background-position: center center;
	background-size: cover;

	@include media-breakpoint-up(lg) {
		background-image: url("/_/images/layout/jumbo_bg-lg.jpg");
		.jumbo_marketing { @include make-col(8) }
		.jumbo_conversion { @include make-col(4); padding-left: 0 }
	}

}

	.jumbo_marketing {
		margin-bottom: 2em;
		font-weight: 700;
		.slideshow {
			text-align: center;
    		border: 3px solid $white;
			box-shadow: 0 20px 35px -20px rgba(0,0,0,0.8);
		}
		@include media-breakpoint-up(sm) { .caption_title { font-size: 1.5rem } }
		@include media-breakpoint-up(lg) {
			margin-bottom: 0;
			.caption_title { font-size: 1.7rem }
		}
	}


	.jumbo_conversion {

		.form_quote {
			overflow: hidden;
    		border: 3px solid $white;
			background-color: rgba(255,255,255,0.9);
			box-shadow: 0 20px 35px -20px rgba(0,0,0,0.8);
		}

		.form_title { margin-bottom: 0.5em }

		.form_group { padding: 0 0.5em 0.1em }

		@include media-breakpoint-only(md) {
			.form_title {
				small {
					display: inline;
					font-size: inherit;
					&:before {
						display: inline-block;
						padding-right: 0.25em;
						content: "-";
					}
				}
			}
			.form_left {
				width: 50%;
				padding-right: 0.25em;
				.form_group { padding-right: 0; }
			}
			.form_right {
				width: 50%;
				padding-left: 0.25em;
				.form_group { padding-left: 0; }
			}
			.form_comments textarea { height: 133px }
		}

		@include media-breakpoint-up(lg) {
			.btn_submit { display: block; width: 100% }
		}

		@include media-breakpoint-only(lg) {
			.form_quote { padding-bottom: 0.4em; }
			.form_comments textarea { height: 114px; min-height: 0 }
			.form_spam {
				height: 75px;
				.g-recaptcha {
					top: -3px;
					left: -5px;
					transform: scale(0.915);
				}
			}
		}

		@include media-breakpoint-up(xl) {
			.form_quote { padding-bottom: 0.4em; }
			.form_comments textarea { height: 113px; min-height: 0 }
		}

	}