.front_welcome {
	padding: 2.5em 0;
	background-color: $white;
	text-align: center;
	background: linear-gradient(165deg, #fcfcfc 50%, #f2f2f2 50%);
}

	.welcome_title {
		h1 {
			margin: 0;
			font-size: 1.6rem;
			line-height: 1em;
			color: theme(primary, base);
			small {
				margin-top: 0.125em;
				font-size: 70%;
				line-height: 1em;
				letter-spacing: 0.125em;
				word-spacing: 0.175em;
				text-transform: uppercase;
				color: $gray-600;
			}
			&:before {
				content: "Top-Rated ";
				color: theme(secondary, base);
			}
		}
		p {
			margin: 0.5em 0 0;
			font-size: 1rem;
			font-weight: 700;
			font-family: $font-family-headings;
			text-transform: uppercase;
			color: $gray-500;
		}
		@include media-breakpoint-up(md) { h1 { font-size: 1.9rem } }
		@include media-breakpoint-up(lg) {
			h1 {
				font-size: 2.2rem;
				small { font-size: 67.25% }
			}
		}
	}

	.welcome_social {

		@include media-breakpoint-up(md) {
			justify-content: center;
			.wrap { @include make-col(10) }
		}
		@include media-breakpoint-up(xl) {
			.wrap { @include make-col(8) }
		}

		.wrap {
			margin: 2em 15px 0;
			border-top: 1px solid #ccc;
			border-bottom: 1px solid #ccc;
		}

		ul {
			display: block;
			width: 100%;
			margin: 0 auto;
			> li {
				display: block;
				float: left;
				width: percentage(1/3);
				margin: 0 !important;
				padding: 0 10px;
				a {
					@include transition(all ease-in-out 150ms);
					display: block;
					margin: 0 auto;
					padding: 0.5em;
					max-width: 100px;
					&:hover {
						@include transition(all ease-in-out 150ms);
						padding: 0;
					}
				}
			}
		}

	}




	.welcome_reviews {

		.wreview_title {
			margin: 2.5em 0 1.5em;
		}

		.review_slider_wrapper {
			position: relative;
		}

		.review_bg {
			display: none;
			max-width: 170px;
			transform: rotate(10deg);
			@include media-breakpoint-up(sm) {
				display: block;
				position: absolute;
				top: 5px; left: 33%;
				opacity: 0.07;
			}
			@include media-breakpoint-up(md) { left: 37% }
			@include media-breakpoint-up(lg) {
				max-width: 150px;
				top: 0; left: 42%;
			}
			@include media-breakpoint-up(xl) { left: 43% }
		}

		.review {

			position: relative;

			p {
				max-width: 740px;
				margin: 0 auto;
				padding: 0 30px;
			}

				.review_stars {
					margin: 0 auto 0.5em;
				}

			@include media-breakpoint-down(sm) {
				.review_stars { margin-top: 1.5em; }
				&.review_first .review_stars { margin-top: 0 }
			}

			@include media-breakpoint-up(md) { padding: 1em }
			
			@include media-breakpoint-up(lg) {
				padding: 1em 1em 0;
				p { font-size: 1.2rem }
			}

			@include media-breakpoint-up(xl) { p { max-width: 768px } }

		}


		.reviews_more { padding-top: 1em; }

	}