blockquote {

	margin: 0 0 1.5em;
	padding: 0.5em 0.5em 0.5em 1em;
	font-size: 120%;
	border-left: 0.5rem solid;
	//background: linear-gradient(to right, $white 25%, transparent);

	p { margin-bottom: 0.5em; line-height: 1.2em; }

	>*:last-child { margin-bottom: 0; }

	.bq_title {
		font-size: 1.325rem;
		font-weight: 800;
		letter-spacing: -0.0275em;
		text-transform: uppercase;
	}

	&.bq--text { border-color: $color-text; }
	&.bq--primary { border-color: $color-primary; }
	&.bq--secondary { border-color: $color-secondary; }
	&.bq--highlight { border-color: $color-highlight; }
	&.bq--accent { border-color: $color-accent; }
	&.bq--success { border-color: $color-success; }
	&.bq--danger { border-color: $color-danger; }
	&.bq--warning { border-color: $color-warning; }
	&.bq--info { border-color: $color-info; }

	&.review {
		padding: 0;
		margin: 0 0 1em;
		font-size: 1rem;
		border: none;
	}

	@include media-breakpoint-up(md) {
		font-size: 125%;
	}

}