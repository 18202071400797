$banner_bg: 							#f2f2f2 !default; //$gray-100 !default;
$banner_color: 							$color-text !default;

.banner {

	padding: 10px 0 1.5em;
	position: relative;
	z-index: 8000;
	text-align: center;
	border-bottom: 10px solid theme(primary, base);
	background-color: $banner_bg;
	background: linear-gradient(145deg, #fcfcfc 50%, $banner_bg 50%);
	color: $banner_color;

	.mobile_button { padding-left: 10px; }

	.branding_logo {
		max-width: 320px;
		margin-bottom: 0.5em;
	}

	.banner_contact { position: relative; }
		
	@include media-breakpoint-only(sm) {
		.branding_logo { margin-top: -30px; }
	}

	@include media-breakpoint-down(sm) {
		box-shadow: 0 1px 15px 0 rgba(0,0,0,0.9);
	}

	@include media-breakpoint-up(md) {
		padding: 1em 0;
		border: none;
		background: linear-gradient(168deg, #fefefe 49.5%, $banner_bg 49.5%);
		.branding_logo {
			display: inline-block;
			margin: 0;
		}
		.banner_contact { text-align: right }	
		.branding_slogan {
			margin: 0 auto;
		}
	}

	@include media-breakpoint-up(lg) {
		padding: 1em 0 1em;
		background: linear-gradient(170deg, #fefefe 45%, $banner_bg 45%);
		.banner_branding { text-align: left }
		.branding_logo { max-width: 260px }
	}

}


