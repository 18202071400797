$form_quote_bg: 				$white;
$form_quote_border: 			1px solid #ddd;
$form_quote_title_bg: 			theme(primary, base);
$form_quote_title_color: 		$white;


.form_quote {

	@extend %card;
	padding: 0 0 0.75em;
	border: $form_quote_border;
	background-color: $form_quote_bg;

	.form_title {
		margin: 0 0 0.75em;
		padding: 0.5em 0.75em; 
		background-color: $form_quote_title_bg;
		color: $form_quote_title_color;
		small {
			color: $form_quote_title_color;
		}
	}

	body.page_front & {
		.form_title { background-color: theme(primary, base); }
	}

	body.page_contact & {
		padding: 0;
		border: none;
		background-color: transparent;
		box-shadow: none;

		@include media-breakpoint-up(md) {

			.form_group { padding: 0 0.25em 0.1em }

			.form_left,
			.form_right { width: 50%; }

			.form_left { float: left; clear: both; }
			
			.form_right { float: right; }
			
			.form_clear { clear: both; }

			.form_state { width: 20%; clear: none; }
			.form_zip { width: 30%; }

		}
		
	}

}
