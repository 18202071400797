figure {
	border: 1px solid #ccc;
	background-color: $white;
	margin: 0;
	padding: 0.5em;
	figcaption {
		margin: 0.5em 0.5em 0;
		font-size: 0.9rem;
		line-height: 1.275em;
	}
}


img {
	display: block;
	width: 100%;
	border-style: none;
}

	.img--captioned {
		max-width: 380px;
		padding: 10px;
		border: 1px solid #ccc;
		background-color: $white;
		margin-bottom: 15px;
		img {
			padding: 0;
			border: none;
			margin-bottom: 0;
		}
		span {
			display: block;
			max-width: 360px;
			margin: 5px 0 0;
			font-size: 0.95rem;
			line-height: 1.275em;
			color: theme(text, light);
		}
	}

	.img--main { margin: 0 0 15px; max-width: 360px; }

	.img--thumbnail {
		padding: 5px;
		border: 1px solid #ddd;
		background-color: #fff;
	}

	.img--right {
		@include media-breakpoint-up(md) {
			clear: both;
			float: right;
			width: 45%;
			margin: 0 0 15px 20px;
		}
	}

	.img--left {
		@include media-breakpoint-up(md) {
			clear: both;
			float: left;
			width: 45%;
			margin: 0 20px 15px 0;
		}		
	}

	.img--wide {
		margin: 0 0 15px;
		max-width: none;
	}

	.img--coupon { max-width: 640px; }


.embed-responsive {
    position: relative;
    display: block;
    height: 0;
    padding: 0;
    overflow: hidden;

	&.embed-responsive-16by9 { padding-bottom: 56.25%; }
	&.embed-responsive-4by3 { padding-bottom: 75%; }

		.embed-responsive-item, embed, iframe, object, video {
			position: absolute;
			top: 0;
			left: 0;
			bottom: 0;
			height: 100%;
			width: 100%;
			border: 0;
		}
}

	.location_map {
		margin-bottom: 1em;
		padding: 0.5em;
		border: 1px solid #ccc;
		background-color: $white;
	}