$site_info_padding: 	2em 0;
$site_info_font_size:	0.95em;
$site_info_bg: 			theme(primary, dark);
$site_info_border:		5px solid $white;
$site_info_color: 		$white !default;


.site_info {

	padding: $site_info_padding;
	font-size: $site_info_font_size;
	border-top: $site_info_border;
	background-color: $site_info_bg;
	color: $site_info_color;

}

