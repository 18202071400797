hr {
	display: block;
	margin: 1.5em 0;
	&[class*="hr--fade"] { 
		border: 0;
		height: 1px;
		background-color: theme(text, base);
		background: linear-gradient(to right, transparent, theme(text, base), transparent);
	}
	&[class*="hr--dotted"] {
		border: 0;
		border-bottom: 1px dashed $white;
		background: theme(text, base);
	}
	&[class*="hr--shadow"] {
	    height: 12px;
	    border: 0;
	    box-shadow: inset 0 12px 12px -12px rgba(0, 0, 0, 0.5);		
	}

	&[class*="hr--engraved"] {
		border: 0;
		height: 0;
		border-top: 1px solid rgba(0, 0, 0, 0.12);
		border-bottom: 2px solid $white;		
	}

}