.reviews_slider {

	.slick-prev,
	.slick-next {
		width: 30px;
		background-color: transparent;
		background-size: 50%;
		opacity: 0.3;
		&:hover {
			background-color: #f90;
			opacity: 0.8;
		}
	}

	.slick-prev:hover { background-image: url("/_/images/icons/angle-left-white.svg") }
	.slick-next:hover { background-image: url("/_/images/icons/angle-right-white.svg") }	

	@include media-breakpoint-up(lg) {

		.slick-prev,
		.slick-next {
			width: 44px;
			background-size: 40%;
		}

	}

}