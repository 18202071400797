.slideshow_jumbo {

    margin: 0 auto;
    line-height: 0px;


    .slide {
    	z-index: 9500;
    }
    
	    [class*="slide_placeholder"] {
	    	display: none;
	    	background-position: center center;
	    	background-size: cover;
	    }

	    .slide_caption {
	        padding: 15px;
	        line-height: 1em;
	        background-color: rgba( theme(secondary, base), 0.8 );
	        color: $white;
	    }

		    .caption_title {
		        margin: 0;
		        font-size: 1rem;
		        font-style: normal;
		        font-weight: 900;
		        font-family: $font-family-headings;
		        line-height: 1em;
		        letter-spacing: 0;
		        text-transform: uppercase;
		    }

    @include media-breakpoint-down(sm) {
    	.slide_placeholder--sm { display: block; }
    }

    @include media-breakpoint-only(md) {
    	.slide_placeholder--md { display: block; }
    }

    @include media-breakpoint-only(lg) {
    	.slide_placeholder--lg { display: block; }
    }

    @include media-breakpoint-up(xl) {
    	.slide_placeholder--xl { display: block; }
    }


    .slide--01 .slide_placeholder--sm { background-image: url("/_/images/slides/sm_01.jpg"); }
    .slide--01 .slide_placeholder--md { background-image: url("/_/images/slides/md_01.jpg"); }
    .slide--01 .slide_placeholder--lg { background-image: url("/_/images/slides/lg_01.jpg"); }
    .slide--01 .slide_placeholder--xl { background-image: url("/_/images/slides/xl_01.jpg"); }

    .slide--02 .slide_placeholder--sm { background-image: url("/_/images/slides/sm_02.jpg"); }
    .slide--02 .slide_placeholder--md { background-image: url("/_/images/slides/md_02.jpg"); }
    .slide--02 .slide_placeholder--lg { background-image: url("/_/images/slides/lg_02.jpg"); }
    .slide--02 .slide_placeholder--xl { background-image: url("/_/images/slides/xl_02.jpg"); }

    .slide--03 .slide_placeholder--sm { background-image: url("/_/images/slides/sm_03.jpg"); }
    .slide--03 .slide_placeholder--md { background-image: url("/_/images/slides/md_03.jpg"); }
    .slide--03 .slide_placeholder--lg { background-image: url("/_/images/slides/lg_03.jpg"); }
    .slide--03 .slide_placeholder--xl { background-image: url("/_/images/slides/xl_03.jpg"); }

    .slide--04 .slide_placeholder--sm { background-image: url("/_/images/slides/sm_04.jpg"); }
    .slide--04 .slide_placeholder--md { background-image: url("/_/images/slides/md_04.jpg"); }
    .slide--04 .slide_placeholder--lg { background-image: url("/_/images/slides/lg_04.jpg"); }
    .slide--04 .slide_placeholder--xl { background-image: url("/_/images/slides/xl_04.jpg"); }

    .slide--05 .slide_placeholder--sm { background-image: url("/_/images/slides/sm_05.jpg"); }
    .slide--05 .slide_placeholder--md { background-image: url("/_/images/slides/md_05.jpg"); }
    .slide--05 .slide_placeholder--lg { background-image: url("/_/images/slides/lg_05.jpg"); }
    .slide--05 .slide_placeholder--xl { background-image: url("/_/images/slides/xl_05.jpg"); }

    .slide--06 .slide_placeholder--sm { background-image: url("/_/images/slides/sm_06.jpg"); }
    .slide--06 .slide_placeholder--md { background-image: url("/_/images/slides/md_06.jpg"); }
    .slide--06 .slide_placeholder--lg { background-image: url("/_/images/slides/lg_06.jpg"); }
    .slide--06 .slide_placeholder--xl { background-image: url("/_/images/slides/xl_06.jpg"); }

    .slide--07 .slide_placeholder--sm { background-image: url("/_/images/slides/sm_07.jpg"); }
    .slide--07 .slide_placeholder--md { background-image: url("/_/images/slides/md_07.jpg"); }
    .slide--07 .slide_placeholder--lg { background-image: url("/_/images/slides/lg_07.jpg"); }
    .slide--07 .slide_placeholder--xl { background-image: url("/_/images/slides/xl_07.jpg"); }

    .slide--08 .slide_placeholder--sm { background-image: url("/_/images/slides/sm_08.jpg"); }
    .slide--08 .slide_placeholder--md { background-image: url("/_/images/slides/md_08.jpg"); }
    .slide--08 .slide_placeholder--lg { background-image: url("/_/images/slides/lg_08.jpg"); }
    .slide--08 .slide_placeholder--xl { background-image: url("/_/images/slides/xl_08.jpg"); }

    .slide--09 .slide_placeholder--sm { background-image: url("/_/images/slides/sm_09.jpg"); }
    .slide--09 .slide_placeholder--md { background-image: url("/_/images/slides/md_09.jpg"); }
    .slide--09 .slide_placeholder--lg { background-image: url("/_/images/slides/lg_09.jpg"); }
    .slide--09 .slide_placeholder--xl { background-image: url("/_/images/slides/xl_09.jpg"); }

    .slide--10 .slide_placeholder--sm { background-image: url("/_/images/slides/sm_10.jpg"); }
    .slide--10 .slide_placeholder--md { background-image: url("/_/images/slides/md_10.jpg"); }
    .slide--10 .slide_placeholder--lg { background-image: url("/_/images/slides/lg_10.jpg"); }
    .slide--10 .slide_placeholder--xl { background-image: url("/_/images/slides/xl_10.jpg"); }

}