.widget {
	@extend %card;
	margin: 0 0 15px;
	padding: 15px;
	font-size: 0.95rem;
	border: 1px solid #ddd;
	background-color: #fff;

	> *:last-child { margin-bottom: 0; }

	&.widget-default { .widget-title { color: $color-text; } }
	&.widget-text { border-color: $color-text; .widget-title { background-color: $color-text; } }
	&.widget-primary { border-color: $color-primary; .widget-title { background-color: $color-primary; } }
	&.widget-secondary { border-color: $color-secondary; .widget-title { background-color: $color-secondary; } }	
	&.widget-highlight { border-color: $color-highlight; .widget-title { background-color: $color-highlight; } }
	&.widget-link { border-color: $color-link; .widget-title { background-color: $color-link; } }
	&.widget-review { border-color: $color-review; .widget-title { background-color: $color-review; } }
	&.widget-success { border-color: $color-success; .widget-title { background-color: $color-success; } }
	&.widget-danger { border-color: $color-danger; .widget-title { background-color: $color-danger; } }
	&.widget-info { border-color: $color-info; .widget-title { background-color: $color-info; } }
	&.widget-warning { border-color: $color-warning; .widget-title { background-color: $color-warning; } }

}

	.widget-title {
		font-size: 1.125rem;
		font-weight: 800;	
		text-transform: uppercase;
	}

	.widget-title:not(.plain) {
		margin: -15px -15px 15px;
		padding: 12px 15px;
		background-color: #ddd;
		color: #fff;
	}

	.widget-title.plain { margin: 0 0 5px; }

.widget_plain {
	padding: 0;
	border: none;
	box-shadow: none;
	background-color: transparent;
	.widget-title {
		margin: 0 0 15px;
		padding: 0px;
		background-color: transparent;
		color: inherit;
	}
}

.widget_titled {
	padding: 0;
	.widget-title {
		margin: 0
	}
}

	.widget-rwreviews {

		@include media-breakpoint-only(md) {
			overflow: hidden;
			p {
				float: left;
				width: 49%;
				&:nth-child(odd) { clear: both; }
				&:nth-child(even) { float: right;; }
			}
		}

	}

	.widget_hours {
		li {
			margin: 0!important;
    		padding: 0.6em 0.5em;
    		line-height: 1em;
    		&:hover {
    			background-color: rgba(0,0,0,0.1);
    		}
		}
		b { display: inline-block; width: 110px; }
		li:not(:last-child) { border-bottom: 1px solid #ccc; }
	}


	.widget_map {
		padding: 5px 5px 10px;
		border: 1px solid #ccc;
		background-color: $white;

		.embed-responsive { padding-bottom: 56.27% }

		address {
			margin: 0;
			padding: 10px 5px 5px;
			line-height: 1.2em;
			strong,
			.company_street { display: block }
			strong {
				margin-bottom: 5px;
				font-size: 1.1rem;
				font-weight: 800;
				letter-spacing: -0.0325em;
				text-transform: uppercase
			}
		}

		p { padding: 0 5px }

		.btn {
			background-color: #EA4335;
			border-color: darken(#EA4335, 10%);
			&:hover,
			&:focus {
				background-color: #619DE5;
				border-color: darken(#619DE5, 10%);
			}
		}

		@include media-breakpoint-only(sm) {
			overflow: hidden;
			address {
				display: inline-block;
				margin-right: 1em;
				float: left;
			}
			p { margin-top: 1.25em }
		}

		@include media-breakpoint-only(md) {
			overflow: hidden;
			address {
				display: inline-block;
				margin-right: 1em;
				float: left;
			}
			p { margin-top: 1.25em }
		}

		@include media-breakpoint-up(lg) {
			.embed-responsive { padding-bottom: 75% }
		}

	}

	.widget_sm {
		@include media-breakpoint-down(md) {
			max-width: 360px;
			margin: 0 auto 1em;
		}		
	}

	.widget_feedback {
		text-align: center;
		.widget-title {
			background-color: theme(text, base);
		}

		p {
			line-height: 1em;
			margin-bottom: 0.5em;
			small {
				display: block;
				margin: 0.25em 0 0;
				font-size: 0.85rem;
				font-weight: 700;
				text-transform: uppercase;
			}
		}

		.btn { margin-bottom: 0 !important; }
		
		>*:last-child { margin-bottom: 0 !important }

	}