.gallery_featured {
    padding: 1em;
    line-height: 1em;
    border: 1px solid #ccc;
    background-color: $white;

    p { line-height: 1.6em }

    @include media-breakpoint-down(xs) {
        margin-bottom: 1em;
    }

    @include media-breakpoint-up(sm) {
        padding-right: 60px;
    }

    @include media-breakpoint-up(md) {
        margin-bottom: -7px;
        overflow: hidden;
        .gallery_cover {
            float: left;
            width: 55%;
            padding-right: 0.5em;
        }
        .gallery_info {
            float: left;
            width: 45%;
            padding-left: 0.5em;
        }
        .cover_thumbs { margin-bottom: 0 }
    }

}

    .gallery_title {
        margin: 0.5em 0 0.5em;
        font-size: 1.5rem;
        font-weight: 800;
        font-style: italic;
        letter-spacing: -0.0375em;
        line-height: 1em;
        text-transform: uppercase;
    }

    .cover_thumbs {

        overflow: hidden;
        margin: 1% 0 1em;

        > a {
            @include transition(opacity ease-in 300ms);
            display: block;
            float: left;
            width: (percentage(1/4)) - 0.75%;
            margin-right: 1%;
            opacity: 0.5;
            &:last-child { margin-right: 0; }
            &:hover {
                @include transition(opacity ease-in 300ms);
                opacity: 1.0;
            }
        }

    }