.content {
	padding: 2em 0;
}

/* .page_front .content {
	padding-top: 2em;
	@include media-breakpoint-up(lg) {
		.main { padding-top: 1em; }
	}
} */

.main {

/* 	@include media-breakpoint-only(lg) {
		.img--right,
		.img--left {
			float: none;
			margin-left: 0;
			width: auto;
		}
	} */

}