$form_focus_border: 			$yellow;
$form_focus_bg: 				lighten($yellow, 40%);

.form {
	font-size: 1em;

	&.form_career textarea {
		height: 180px;
	}

}

	.form_group-wrap {
		box-shadow: none;
		border: 2px solid theme(secondary, base);
		margin: 0 0 1.25em;
		padding: 0 0.25em 0.75em;

		p { padding: 0 0.75em }

	}

		.group_title {
			display: block;
			width: 70%;
			padding: 0.5em 0.75em;
			font-size: 1.1rem;
			font-family: $font-family-headings;
			font-style: italic;
			font-weight: 900;
			text-transform: uppercase;
			background: $body-bg;
			color: theme(primary, base);
		}

	.form_security { overflow: hidden; margin-bottom: 1em; }

	.form_title {
		margin: 0 0.75em 0.5em;
		font-size: 1.4rem;
		small {
			display: block;
			font-size: 1rem;
			font-weight: 400;
			letter-spacing: 0;
			text-transform: uppercase;
		}
	}

	.form_group {
		padding: 0 0.75em 0.1em;
		
		&.form_hp { display: none; }
	}

		.form_error {
			border: 1px solid $color-danger;
			background-color: lighten($color-danger, 40%);
		}

		.error-message {
			margin: 0 0 0.5em;
			padding: 0 0 0 3px !important;
			font-size: 0.7em;
			font-weight: 800;
			line-height: 1em;
			text-transform: uppercase;
			color: darken($color-danger, 5%);
		}

		.form_control {
			display: block;
			width: 100%;
			margin: 0 0 5px;
			padding: 9px 7px;
			font-size: 1rem;
			border: 1px solid #ddd;
			outline: none;
			background-color: $white;
			&:focus {
				border-color: $form_focus_border;
				background-color: $form_focus_bg;
			}
		}

		textarea.form_control {
			min-height: 100px;
		}



	.form_spam {
		@include media-breakpoint-down(xs) {
			position: relative;
			overflow: hidden;
			height: 75px;
			.g-recaptcha {
				@include transform(scale(0.8));
				position: absolute;
				top: 0;
				left: -18px;
			}
		}
	}

		.fix-lg .form_spam {
			@include media-breakpoint-only(lg) {
				position: relative;
				overflow: hidden;
				height: 80px;
				.g-recaptcha {
					@include transform(scale(0.9));
					position: absolute;
					top: 0;
					left: -2px;
				}
			}			
		}

	.form_submit {
		margin-top: 0.25rem;
		.btn_submit {
			font-family: $font-family-headings;
			font-style: italic;
			font-weight: 900;
			text-transform: uppercase;
		}
	}



.form_lr {
	@include media-breakpoint-up(md) {

		.form_group { padding: 0 0.25em 0.1em }

		.form_left,
		.form_right { width: 50%; }

		.form_left { float: left; clear: both; }
		
		.form_right { float: right; }
		
		.form_clear { clear: both; }

		.form_state { width: 20%; clear: none; }
		.form_zip { width: 30%; }

	}
}

.form_lr--sm {
	@include media-breakpoint-only(md) {

		.form_left,
		.form_right { width: 50%; }
		
		.form_left { float: left; padding-right: 0.25em; }
		
		.form_right { float: right; padding-left: 0.25em; }
		
		.form_clear { clear: both; }

	}
}
