.slideshow {

    .slide_caption {
        line-height: 1em;
        padding: 15px;
        text-shadow: 0 1px 1px rgba(0, 0, 0, 0.3);
        background-color: theme(text, dark);
        color: $white;
    }

        .caption_wrap {
            max-width: 1100px;
            margin: 0 auto;
        }

        .caption_title {
            margin: 0 0 0.5em;
            font-size: 1.2rem;
            font-weight: 800;
            line-height: 1em;
        }

        .caption_text {
            margin: 0;
            font-size: 0.95rem;
            line-height: 1.2em;
        }
            .caption_text+.caption_text { margin-top: 0.5em }

    @include media-breakpoint-down(xs) {
        .slick-prev,
        .slick-next { visibility: hidden; }
    }

    @include media-breakpoint-up(sm) {
        .slide_caption {
            padding: 15px 70px;
            padding: 15px 85px; // 55 Next/Prev + 30
        }
            .caption_title { font-size: 1.4rem; }
            .caption_text { font-size: 1rem; }
    }

}