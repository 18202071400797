.banner_visit {
	font-size: 15px;
	line-height: 1em;
}

	.visit_address {
		line-height: 1.2em;
		a {
			display: inline-block;
			padding: 0 1.75em;
			text-decoration: none;
				background-image: 	url("/_/images/icons/map-marker-alt.svg"),
									url("/_/images/icons/angle-right.svg");
				background-size: 1.2em, 1.2em;
				background-repeat: no-repeat, no-repeat;
				background-position: left center, right center;
			color: theme(text, base);
			&:hover {
				background-image: 	url("/_/images/icons/map-marker-alt.svg"),
									url("/_/images/icons/angle-right-hover.svg");
				background-size: 1.2em, 1.2em;
				background-repeat: no-repeat, no-repeat;
				background-position: left center, right center;
			}
		}
		.visit_street { display: block; }
	}

