.front_about {

	padding: 2em 0 1em;

	.about_img .embed-responsive { padding-bottom: 56.27% }

	@include media-breakpoint-up(lg) {
		padding: 1.5em 0;
		.row { align-items: center }	
		.about_img {
			@include make-col(5);
			padding-right: 0;
			.embed-responsive { padding-bottom: 75% }
		}
		.about_txt {
			@include make-col(7);
			padding-left: 0;
		}
	}

	@include media-breakpoint-up(xl) {
		.about_img { @include make-col(6) }
		.about_txt { @include make-col(6) }
	}


}

	.about_img {

		@include media-breakpoint-down(xs) {
			.about_map { margin-bottom: 1em; }
		}

		@include media-breakpoint-up(sm) {
			position: relative;
			.btn {
				position: absolute;
				top: 10px;
				right: 25px;
				background-color: #EA4335;
				border-color: darken(#EA4335, 10%);
				&:hover,
				&:focus {
					background-color: lighten(#EA4335, 10%);
					background-color: #619DE5;
					border-color: darken(#619DE5, 10%);
				}
			}
		}

		@include media-breakpoint-up(lg) {
			.btn { top: auto; right: auto; bottom: 22px; left: 20px }
		}

	}

	.about_txt {

		.icar_tech {
			max-width: 200px;
			margin: 0 0 1em;
			padding: 1em 1.5em;
			border: 1px solid #006CB4;
			background-color: $white;
			font-size: 0.925rem;
			font-weight: 800;
			line-height: 1em;
			letter-spacing: -0.0325em;
			text-align: center;
			text-transform: uppercase;
			img {
				max-width: 195px;
				margin: 0 auto;
			}
		}

		ul > li {
			font-weight: 600;
			letter-spacing: -0.0225em
		}

		@include media-breakpoint-up(sm) {
			.icar_tech {
				float: right;
				margin: 0 0 0.5em 0.5em;
			}
		}

		@include media-breakpoint-only(md) {
			.about_title br { display: none; }
			.icar_tech {
				max-width: none;
				width: 300px;
				margin: 3.5em 0 0 0;
			}
		}
		
		@include media-breakpoint-up(md) { text-align: left; }
		
		@include media-breakpoint-up(lg) {	
			.about_wrap { background-image: none; padding-left: 30px }
			.about_title { margin-top: 0 }
			p { margin-bottom: 0 }
			ul > li { font-size: 1.15rem; }
		}

		@include media-breakpoint-up(xl) {
			ul > li { font-size: 1.25rem }
		}

	}

		.about_title {
			margin: 1em 0 0.5em;
			font-size: 1.4rem;
			font-weight: 800;
			font-style: italic;
			letter-spacing: -0.0475em;
			text-transform: uppercase;
			@include media-breakpoint-up(sm) { font-size: 1.7rem; }
		}
