.gallery_slider {

	.slick-prev {
		display: none !important;
	}

	.slick-prev,
	.slick-next {
		width: 45px;
		background-color: theme(primary, light);
		background-size: 50%;
		opacity: 1;
		&:hover {
			background-color: theme(primary, dark);
			opacity: 1;
		}
	}

}