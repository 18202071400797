.front_title {
	padding: 1em 0;
	border-top: 3px solid $white;
	background: theme(primary, base);

	@include media-breakpoint-up(md) {
		background-image: linear-gradient(
			to right,
			theme(primary, dark),
			theme(primary, light) 25%,
			theme(primary, light) 75%,
			theme(primary, dark)
		);
	}	

	&.front_emergency {
		background-color: theme(danger, base);
		@include media-breakpoint-up(md) {
			background-image: linear-gradient(
				to right,
				darken(theme(danger, dark), 5%),
				theme(danger, base) 25%,
				theme(danger, base) 75%,
				darken(theme(danger, dark), 5%)
			);
		}	
	}

}

	.front_estimate .estimate_title span {
		display: none;
		@include media-breakpoint-up(lg) { display: inline; }
	}

	.title_title {
		margin: 0;
		font-family: $font-family-headings;
		font-weight: 900;
		color: $gray-100;
		p {
			margin: 0;
			font-size: 1.1rem;
			line-height: 1em;
			text-align: center;
			text-transform: uppercase;
		}
		strong {
			display: block;
			margin: 0 0 0.325em;
			font-size: 1.3rem;
			line-height: 1em;
			font-weight: 900;
		}
		.phone { color: inherit }

		small {
			display: block;
			margin: 0.5em 0 0;
			font-weight: 700;
			line-height: 1em;
		}
		@include media-breakpoint-up(md) {
			p { font-size: 1.3rem; }
			strong {
				font-size: inherit;
				display: inline;
				margin: 0;
			}
			small { margin-top: 0.25em }
		}
		@include media-breakpoint-up(lg) {
			p { font-size: 1.5rem; }
		}
	}