$services_bg:				$gray-200;

.front_services {

	padding: 2em 0;
	text-align: center;
	border-bottom: 1px solid #ccc;
	background-color: $services_bg;

}

	.services_title {
		text-align: center;

		h2 {
			display: block;
			margin: 0;
			font-size: 1.75rem;
			font-weight: 800;
			text-transform: uppercase;
			letter-spacing: -0.0375em;
			line-height: 1em;
		}



	}

		.feature {

			padding: 60px 0;
			background-size: cover;
			background-position: center center;
			@include transition(all ease-in-out 300ms);

			&:hover { @include transition(all ease-in-out 300ms); }

			h3 {
				margin: 0;
				padding: 0.5em;
				font-size: 1.275rem;
				font-weight: 600;
				letter-spacing: 0;
				line-height: 1em;
				text-shadow: 0 1px 3px rgba(0,0,0,0.7);
				text-transform: uppercase;
				background-color: rgba(theme(primary, dark), 0.9);
				color: $white;
				strong {
					display: block;
					font-size: 120%;
					font-weight: 800;
					letter-spacing: -0.0325em;
					line-height: 1em;
				}
			}

			@include media-breakpoint-down(sm) { margin-bottom: 15px; }

			@include media-breakpoint-only(md) {
				h3 {
					font-size: 1.125rem;
					strong { font-size: 110%; letter-spacing: -0.0425em }
				}
			}

			@include media-breakpoint-up(md) {
				@include make-col(4);
				padding: 60px 0;
				border: 3px solid $services_bg;
				border-top: none;
				border-bottom: none;
			}

			@include media-breakpoint-up(lg) { padding: 90px 0; }
			@include media-breakpoint-up(xl) { padding: 110px 0; }

		}

			.feature_1 {
				background-image: url("/_/images/services/feat_1.jpg");
				@include media-breakpoint-only(sm) {
					margin-bottom: 0;
					padding: 90px 0;
					border-bottom: 10px solid $services_bg;
					h3 {
						font-size: 1.425rem;
						letter-spacing: 0.015em;
						strong { font-size: 130% }
					}
				}
				@include media-breakpoint-up(sm) {
					background-image: url("/_/images/services/feat_1-lg.jpg");
				}
				@include media-breakpoint-up(md) {
					margin-bottom: 0;
				}
			}

			.feature_2 {
				background-image: url("/_/images/services/feat_2.jpg");
				h3 { background-color: rgba(theme(secondary, base), 0.8); }
				@include media-breakpoint-only(sm) {
					@include make-col(6);
					border-right: 5px solid $services_bg;
				}
				@include media-breakpoint-up(sm) {
					margin-bottom: 0;
					background-image: url("/_/images/services/feat_2-lg.jpg");
				}
			}

			.feature_3 {
				background-image: url("/_/images/services/feat_3.jpg");
				@include media-breakpoint-only(sm) {
					@include make-col(6);
					border-left: 5px solid $services_bg;
				}
				@include media-breakpoint-up(sm) {
					margin-bottom: 0;
					background-image: url("/_/images/services/feat_3-lg.jpg");
				}
			}		

	.services_more {
		p {
			margin: 1em 0 0;
		}
	}

