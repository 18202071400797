$announce-bg: 				theme(secondary, base) !default;
$announce-color: 			$white !default;

.announce {

	margin: 1em 0 0;	
	font-size: 1rem;

	p.greview {
		margin: 0 0 1em;
		line-height: 1em;
		text-align: center;
		img {
			display: inline-block;
			width: 140px;
			margin: 0;
		}
		a {
			text-decoration: none;
			color: #333;			
		}
		small {
			display: inline-block;
			margin-top: 5px;
			font-size: 14px;
			font-weight: 600;
			line-height: 1em;
		}
	}

	.announce-wrap p {
		margin-bottom: 1em;
		font-size: 16px;
		font-weight: 700;
		line-height: 1em;
		text-transform: uppercase;
		&:last-child { margin-bottom: 0; }
		span {	
			padding: 0.25em 0.5em;
			background-color: theme(secondary, base);
			color: $announce-color;
		}
		&:first-child span { background-color: theme(secondary, dark) }
		&:last-child span { background-color: theme(secondary, light) }	
	}

	@include media-breakpoint-up(sm) {
		overflow: hidden;
		.announce-wrap p {
			display: inline-block;
			margin-bottom: 0.65em;
		}
		p.greview {
			white-space: nowrap;
			br { display: none; }
			img {
				width: 120px;
				margin: 0;
				vertical-align: middle;
			}
		}		
	}


	@include media-breakpoint-up(md) {
		margin-top: 0;
		.announce-wrap p span { padding: 0.5em 0.75em }
	}

	@include media-breakpoint-up(lg) {
		margin-top: -30px;
		p.greview { margin-bottom: 1.25em }
		.announce-wrap p:not(:last-child) { margin-right: 0.5em; }
	}

}