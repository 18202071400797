.info_copyright {

	font-size: 0.85rem;
	line-height: 1.3em;
	text-align: center; 

	a {
		font-size: 1rem;
		font-weight: 600;
		line-height: 1em;
		text-decoration: none;
		color: $white;
	}

	@include media-breakpoint-up(md) {
		ul > li {
			display: inline-block;
			margin-bottom: 0 !important;
			&:nth-child(2) { padding: 0 1em }
		}
	}

	.page_front & {
		padding-top: 1em;
		@include media-breakpoint-up(sm) { text-align: right }
		@include media-breakpoint-up(md) {
			padding-top: 0;
			ul > li {
				display: block;
				margin-bottom: 0.25em !important;
				&:nth-child(2) { padding: 0 }
			}
		}	
	}

}