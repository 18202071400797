.btn_3d {
	
	padding: 15px 15px !important;
	border-width: 1px !important;
	border-bottom-width: 5px !important;
    @include transition(all ease-in-out 150ms);

	&:hover {
		padding: 18px 15px 16px !important;
		border-bottom-width: 1px !important;
    	@include transition(all ease-in-out 150ms);
	}

}