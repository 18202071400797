h1, h2, h3, h4, h5, h6,
.h1, .h2, .h3, .h4, .h5, .h6 {
	margin: 1.5em 0 0.5em;
	font-family: $font-family-headings;
	font-weight: 900;
	font-style: italic;
	line-height: 1em;
	text-transform: uppercase;
	small {
		display: block;
		font-weight: 400;
		color: #777;
	}
}

h1, .h1 {
	font-size: 1.8rem;
	letter-spacing: -0.0275em;
	small { font-weight: 400 }
	@include media-breakpoint-up(lg) { font-size: 2.0rem; }
	@include media-breakpoint-up(xl) { font-size: 2.2rem; }
}

h2, .h2 {
	font-size: 1.6rem;
	font-weight: 700;
	letter-spacing: -0.02em;
	@include media-breakpoint-up(xl) { font-size: 1.8rem; }
}

h3, .h3 {
	font-size: 1.4rem;
	letter-spacing: -0.0175em;
	@include media-breakpoint-up(xl) { font-size: 1.6rem; }
}

h4, .h4 {
	font-size: 1.2rem;
	font-weight: 700;
	letter-spacing: -0.015em;
	@include media-breakpoint-up(xl) { font-size: 1.4rem; }
}

h5, .h5 { font-size: 1rem; letter-spacing: -0.0125em }

h6, .h6 { font-size: 1rem; letter-spacing: -0.0125em }
