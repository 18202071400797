.coverage {

	@extend %card;
	clear: both;
	padding: 30px;

	.coverage_title {
		margin: 0 0 0.75rem;
		font-size: 1rem;
		font-weight: 800;
		text-transform: uppercase;
	}

	p { 
		margin-bottom: 0.5rem;
		line-height: 1.4em;
		&:last-of-type { margin-bottom: 0; }
	}

		.coverage_county {
			font-size: 0.95rem;
			font-weight: 600;
		}

		.coverage_city {
			font-size: 0.9rem;
		}

}

.block.coverage {
	border: none;
	padding: 2em 0 1.75em;
	text-align: center;
	box-shadow: none;

	.row { justify-content: center; }

	.coverage_map { padding-bottom: 1.5em }

	@include media-breakpoint-up(lg) { .wrap { @include make-col(9) } }
	@include media-breakpoint-up(xl) { .wrap { @include make-col(8) } }

	.page_front & {
		@include media-breakpoint-up(lg) {
			.row { align-items: center; }
			.coverage_map { @include make-col(6); padding-bottom: 0 }
			.coverage_text { @include make-col(6); text-align: left }
		}
 		@include media-breakpoint-up(xl) {
			.coverage_map { @include make-col(7) }
			.coverage_text { @include make-col(5); text-align: left }
		}
	}

}