$contact_title_color: 				theme(secondary, base);
$contact_phone_color: 				theme(primary, base);
$contact_phone_weight: 				800;

.banner_contact {}

	.contact_bg {
		max-width: 60px;
		margin: 0 auto 0.5em;
	}

	.contact_call {
		margin: 0;
		font-size: 1rem;
		line-height: 1em;
	}

		.contact_title {
			display: block;
			margin-bottom: 0.125em;
			font-size: 1rem;
			font-weight: 800;
			line-height: 1em;
			letter-spacing: -0.0225em;
			text-transform: uppercase;
			color: $contact_title_color;
		}

		.contact_phone {
			display: inline-block;
			font-size: 1.8rem;
			font-weight: $contact_phone_weight;
			line-height: 1em;
			letter-spacing: -0.04em;
			color: $contact_phone_color;
			&:active { color: $contact_phone_color; }
			&:hover, &:focus { color: $contact_phone_color; outline: none; }
		}

		.contact_note {
			display: block;
			margin: 0.125em 0 0;
			font-size: 0.95rem;
			font-weight: 400;
			font-style: italic;
			line-height: 1.1em;
			letter-spacing: -0.0125em;
			b {
				display: block;
			}
		}
