ul, ol {
	margin: 0 0 1em;
	padding: 0 0 0 1.2em;
}

%list, .list {
	li:not(:last-child) { margin-bottom: 0.25em; }
}

%list_unstyled, .list_unstyled {
	@extend %list;
	margin-left: 0;
	padding-left: 0;
	list-style-type: none;
	list-style-image: url(data:image/gif;base64,R0lGODlhAQABAIAAAAAAAP///yH5BAEAAAAALAAAAAABAAEAAAIBRAA7);
}

%list_inline, .list_inline {
	@extend %list_unstyled;
	li { display: inline-block; margin: 0 0.5rem 0.5rem 0 !important; }
}

.list_buttons {
	a {
		@extend %btn_link;
		@extend %btn--sm;
		border-radius: 3px;
	}
}

.list_icon {

	//@extend %list;
	margin-left: 0;
	padding-left: 0;

	> li {
		list-style-type: none;
		line-height: 1em;
		&:not(:last-child) {
			margin-bottom: 0.75em;
		}
	}

	li > ul {
		margin: 1em 0;
		list-style-type: square;
		> li:not(:last-child) { margin-bottom: 0.5em }
	}

	> li {
		padding-left: 1.75em;
		font-size: 1.125rem;
		background-repeat: no-repeat;
		background-size: 1.25rem;
		background-position: left 0.1em;
	}

}

	.icon_check-primary > li { background-image: url("/_/images/icons/cm_primary.svg") }
	.icon_check-secondary > li { background-image: url("/_/images/icons/cm_secondary.svg") }
	.icon_check-success > li { background-image: url("/_/images/icons/cm_success.svg") }


.sa_review_list {

	margin-left: 0;
	padding-left: 0;
	list-style-type: none;
	list-style-image: url(data:image/gif;base64,R0lGODlhAQABAIAAAAAAAP///yH5BAEAAAAALAAAAAABAAEAAAIBRAA7);

	li:not(:last-child) { margin-bottom: 0.25em; }

	span {
		display: inline-block;
		padding: 0.25em 0.6em;
		font-size: 1.1rem;
		font-weight: 800;
		text-transform: uppercase;
		letter-spacing: -0.015em;
		background-color: theme(primary, base);
		color: $white;
	}

	@include media-breakpoint-up(sm) {
		li {
			display: inline-block;
			margin: 0 0.5em 0 0 !important;
		}		
	}

}