.page-title {
	padding: 0;
	color: $white;
	border-bottom: 5px solid $white;
	text-shadow: 0 1px 2px rgba(0,0,0,0.4);

	.title-wrap {
		padding: 2em 0;
		background-image: linear-gradient( 45deg, theme(secondary, dark), theme(secondary, base) 40%, theme(secondary, base) 60%, theme(secondary, light) );
	}

	
	h1 {
		margin: 0;
		line-height: 1em;
		small {
			font-size: 80%;
			line-height: 1em;
			letter-spacing: 0.0175em;
			color: $white;
		}
	}

	@include media-breakpoint-up(md) {
		background-size: cover;
		background-position: center center;
		background-repeat: no-repeat;
		padding-top: 11em;
		.title-wrap {
			padding: 1em 0;
			background-image: linear-gradient( 
				45deg, rgba(theme(secondary, dark), 0.7), 
				rgba(theme(secondary, base), 0.7) 40%,
				rgba(theme(secondary, base), 0.7) 60%,
				rgba(theme(secondary, light), 0.7)
			);
		}	
	}

	@include media-breakpoint-up(lg) {
		padding-top: 14em;
	}

	@include media-breakpoint-up(xl) {
		padding-top: 17em;
		background-size: 1400px auto;
	}

	.no_pt_img & {
		@include media-breakpoint-up(md) {
			background-image: linear-gradient( 45deg, theme(secondary, dark), theme(secondary, base) 40%, theme(secondary, base) 60%, theme(secondary, light) );
			.title-wrap {
				background-image: none;
				background-color: rgba(theme(text,dark), 0.2);
			}
		}
	}


	.service_septic_pumping & {
		@include media-breakpoint-up(md) {
			background-image: url("/_/images/page-titles/septic-cleaning.jpg")
		}
	}
	.service_septic_installation & {
		@include media-breakpoint-up(md) {
			background-image: url("/_/images/page-titles/septic-installation.jpg")
		}
	}
	.service_septic_inspection & {
		@include media-breakpoint-up(md) {
			background-image: url("/_/images/page-titles/septic-inspection.jpg")
		}
	}

	.page_reviews & {
		@include media-breakpoint-up(md) {
			background-image: url("/_/images/page-titles/reviews.jpg")
		}
	}

	.page_about & {
		@include media-breakpoint-up(md) {
			background-image: url("/_/images/page-titles/about.jpg")
		}
	}

	.page_contact & {
		@include media-breakpoint-up(md) {
			background-image: url("/_/images/page-titles/contact.jpg")
		}
	}

	.sa_north_port & { @include media-breakpoint-up(md) { background-image: url("/_/images/page-titles/sa_north_port.jpg") } }
	.sa_port_charlotte & { @include media-breakpoint-up(md) { background-image: url("/_/images/page-titles/sa_port_charlotte.jpg") } }
	.sa_punta_gorda & { @include media-breakpoint-up(md) { background-image: url("/_/images/page-titles/sa_punta_gorda.jpg") } }

}