.review {

	p {
		padding: 1em;
		font-size: 1rem;
	}

		.review_stars {
			display: block;
			margin: 0 0 0.5em;
			max-width: 150px;
		}

		cite {
			display: block;
			margin-top: 0.5em;
			font-weight: 600;
		}


	.main_reviews & {

		margin: 0 0 15px;
		border: 3px solid theme(primary, base);
		background-color: $white;

		p { margin: 0 }

		&:nth-child(even) {
			border-color: theme(secondary, base);
		} 

	}

}

