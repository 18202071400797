@import url("https://use.typekit.net/mix2nev.css");
.roll_item, .form_quote, .coverage, .widget {
  padding: 0.5em;
  border: 1px solid #ddd;
  background-color: #fff;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.1); }

@-ms-viewport {
  width: device-width; }

html {
  position: relative;
  min-height: 100%; }

body {
  min-height: 100%;
  margin: 0;
  background-color: #fcfcfc;
  color: #495057; }

abbr[title] {
  border-bottom: none;
  text-decoration: underline;
  text-decoration: underline dotted; }

sub,
sup {
  font-size: 75%;
  line-height: 0;
  position: relative;
  vertical-align: baseline; }

sub {
  bottom: -0.25em; }

sup {
  top: -0.5em; }

button,
input,
optgroup,
select,
textarea {
  font-family: inherit;
  font-size: 100%;
  line-height: 1.15;
  margin: 0; }

button,
input {
  overflow: visible; }

button,
select {
  text-transform: none; }

button,
[type="button"],
[type="reset"],
[type="submit"] {
  -webkit-appearance: button; }

button::-moz-focus-inner,
[type="button"]::-moz-focus-inner,
[type="reset"]::-moz-focus-inner,
[type="submit"]::-moz-focus-inner {
  border-style: none;
  padding: 0; }

button:-moz-focusring,
[type="button"]:-moz-focusring,
[type="reset"]:-moz-focusring,
[type="submit"]:-moz-focusring {
  outline: 1px dotted ButtonText; }

fieldset {
  padding: 0.35em 0.75em 0.625em; }

legend {
  box-sizing: border-box;
  color: inherit;
  display: table;
  max-width: 100%;
  padding: 0;
  white-space: normal; }

progress {
  vertical-align: baseline; }

textarea {
  overflow: auto; }

[type="checkbox"],
[type="radio"] {
  box-sizing: border-box;
  padding: 0; }

[type="number"]::-webkit-inner-spin-button,
[type="number"]::-webkit-outer-spin-button {
  height: auto; }

[type="search"] {
  -webkit-appearance: textfield;
  outline-offset: -2px; }

[type="search"]::-webkit-search-decoration {
  -webkit-appearance: none; }

::-webkit-file-upload-button {
  -webkit-appearance: button;
  font: inherit; }

details {
  display: block; }

summary {
  display: list-item; }

template {
  display: none; }

[hidden] {
  display: none; }

.container {
  margin-right: auto;
  margin-left: auto;
  padding-right: 15px;
  padding-left: 15px;
  width: 100%; }
  @media (min-width: 576px) {
    .container {
      max-width: 540px; } }
  @media (min-width: 768px) {
    .container {
      max-width: 720px; } }
  @media (min-width: 992px) {
    .container {
      max-width: 960px; } }
  @media (min-width: 1200px) {
    .container {
      max-width: 1140px; } }

.container-fluid {
  width: 100%;
  margin-right: auto;
  margin-left: auto;
  padding-right: 15px;
  padding-left: 15px;
  width: 100%; }

.row {
  display: flex;
  flex-wrap: wrap;
  margin-right: -15px;
  margin-left: -15px; }

.no-gutters {
  margin-right: 0;
  margin-left: 0; }
  .no-gutters > .wrap {
    padding-right: 0;
    padding-left: 0; }

html {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  -ms-box-sizing: border-box;
  -o-box-sizing: border-box;
  box-sizing: border-box;
  -ms-overflow-style: scrollbar; }

*,
*:before,
*:after {
  -webkit-box-sizing: inherit;
  -moz-box-sizing: inherit;
  -ms-box-sizing: inherit;
  -o-box-sizing: inherit;
  box-sizing: inherit; }

.wrap {
  position: relative;
  width: 100%;
  min-height: 1px;
  padding-right: 15px;
  padding-left: 15px; }

.col_container {
  box-sizing: border-box; }
  @media (min-width: 576px) {
    .col_container [class*="col"] {
      padding: 0.5em; }
    .col_container .col-sm-12 {
      float: left;
      width: 100%; }
    .col_container .col-sm-11 {
      float: left;
      width: 91.66667%; }
    .col_container .col-sm-10 {
      float: left;
      width: 83.33333%; }
    .col_container .col-sm-9 {
      float: left;
      width: 75%; }
    .col_container .col-sm-8 {
      float: left;
      width: 66.66667%; }
    .col_container .col-sm-7 {
      float: left;
      width: 58.33333%; }
    .col_container .col-sm-6 {
      float: left;
      width: 50%; }
    .col_container .col-sm-5 {
      float: left;
      width: 41.66667%; }
    .col_container .col-sm-4 {
      float: left;
      width: 33.33333%; }
    .col_container .col-sm-3 {
      float: left;
      width: 25%; }
    .col_container .col-sm-2 {
      float: left;
      width: 16.66667%; }
    .col_container .col-sm-1 {
      float: left;
      width: 8.33333%; } }
  @media (min-width: 768px) {
    .col_container .col-md-break {
      float: none;
      width: 100%; }
    .col_container .col-md-12 {
      float: left;
      width: 100%; }
    .col_container .col-md-11 {
      float: left;
      width: 91.66667%; }
    .col_container .col-md-10 {
      float: left;
      width: 83.33333%; }
    .col_container .col-md-9 {
      float: left;
      width: 75%; }
    .col_container .col-md-8 {
      float: left;
      width: 66.66667%; }
    .col_container .col-md-7 {
      float: left;
      width: 58.33333%; }
    .col_container .col-md-6 {
      float: left;
      width: 50%; }
    .col_container .col-md-5 {
      float: left;
      width: 41.66667%; }
    .col_container .col-md-4 {
      float: left;
      width: 33.33333%; }
    .col_container .col-md-3 {
      float: left;
      width: 25%; }
    .col_container .col-md-2 {
      float: left;
      width: 16.66667%; }
    .col_container .col-md-1 {
      float: left;
      width: 8.33333%; } }
  @media (min-width: 992px) {
    .col_container .col-lg-break {
      float: none;
      width: 100%; }
    .col_container .col-lg-12 {
      float: left;
      width: 100%; }
    .col_container .col-lg-11 {
      float: left;
      width: 91.66667%; }
    .col_container .col-lg-10 {
      float: left;
      width: 83.33333%; }
    .col_container .col-lg-9 {
      float: left;
      width: 75%; }
    .col_container .col-lg-8 {
      float: left;
      width: 66.66667%; }
    .col_container .col-lg-7 {
      float: left;
      width: 58.33333%; }
    .col_container .col-lg-6 {
      float: left;
      width: 50%; }
    .col_container .col-lg-5 {
      float: left;
      width: 41.66667%; }
    .col_container .col-lg-4 {
      float: left;
      width: 33.33333%; }
    .col_container .col-lg-3 {
      float: left;
      width: 25%; }
    .col_container .col-lg-2 {
      float: left;
      width: 16.66667%; }
    .col_container .col-lg-1 {
      float: left;
      width: 8.33333%; } }
  @media (min-width: 1200px) {
    .col_container .col-xl-break {
      float: none;
      width: 100%; }
    .col_container .col-xl-12 {
      float: left;
      width: 100%; }
    .col_container .col-xl-11 {
      float: left;
      width: 91.66667%; }
    .col_container .col-xl-10 {
      float: left;
      width: 83.33333%; }
    .col_container .col-xl-9 {
      float: left;
      width: 75%; }
    .col_container .col-xl-8 {
      float: left;
      width: 66.66667%; }
    .col_container .col-xl-7 {
      float: left;
      width: 58.33333%; }
    .col_container .col-xl-6 {
      float: left;
      width: 50%; }
    .col_container .col-xl-5 {
      float: left;
      width: 41.66667%; }
    .col_container .col-xl-4 {
      float: left;
      width: 33.33333%; }
    .col_container .col-xl-3 {
      float: left;
      width: 25%; }
    .col_container .col-xl-2 {
      float: left;
      width: 16.66667%; }
    .col_container .col-xl-1 {
      float: left;
      width: 8.33333%; } }

.banner {
  position: relative;
  z-index: 10;
  /* 
		@include media-breakpoint-up(sm) {}

		@include media-breakpoint-up(md) {
			.row { align-items: center }
			.banner_branding { order: 1; @include make-col(6) }
			.banner_contact { order: 2; @include make-col(6) }
			.banner_slogan { order: 3; }
		} */ }
  @media (min-width: 768px) {
    .banner .row {
      align-items: center; }
    .banner .banner_branding {
      flex: 0 0 33.33333%;
      max-width: 33.33333%; }
    .banner .banner_slogan {
      flex: 0 0 33.33333%;
      max-width: 33.33333%; }
    .banner .banner_contact {
      flex: 0 0 33.33333%;
      max-width: 33.33333%; } }

.banner_menu {
  position: relative;
  z-index: 8; }

.jumbo {
  position: relative;
  z-index: 5; }

@media (min-width: 992px) {
  .content .main {
    flex: 0 0 66.66667%;
    max-width: 66.66667%; }
  .content .sidebar {
    flex: 0 0 33.33333%;
    max-width: 33.33333%; } }

@media (min-width: 576px) {
  .page_front .site_info .row {
    align-items: center; }
  .page_front .site_info .wrap {
    flex: 0 0 50%;
    max-width: 50%; } }

@media (min-width: 768px) {
  body:not(.page_front) {
    height: 100%;
    padding-bottom: 83px; }
    body:not(.page_front) .site_info {
      position: absolute;
      bottom: 0;
      width: 100%; } }

html {
  font-size: 16px;
  font-family: "Roboto", "Helvetica Neue", Helvetica, Arial, sans-serif;
  line-height: 1.525em;
  -webkit-text-size-adjust: 100%; }
  @media (min-width: 768px) and (max-width: 991px) {
    html {
      font-size: 18px; } }
  @media (min-width: 992px) {
    html {
      font-size: 16px; } }
  @media (min-width: 1200px) {
    html {
      font-size: 17px; } }

body {
  font-size: 1rem;
  line-height: inherit; }

p {
  margin: 0 0 1em; }
  p.lead {
    font-size: 1.175rem;
    line-height: 1.53em; }

.b0 {
  font-weight: 400; }

b, strong, .b1 {
  font-weight: 700; }

.bolder, .strong, .b2 {
  font-weight: 700; }

address {
  margin-bottom: 1em;
  font-style: normal; }

blockquote {
  margin: 0 0 1.5em;
  padding: 0.5em 0.5em 0.5em 1em;
  font-size: 120%;
  border-left: 0.5rem solid; }
  blockquote p {
    margin-bottom: 0.5em;
    line-height: 1.2em; }
  blockquote > *:last-child {
    margin-bottom: 0; }
  blockquote .bq_title {
    font-size: 1.325rem;
    font-weight: 800;
    letter-spacing: -0.0275em;
    text-transform: uppercase; }
  blockquote.bq--text {
    border-color: #495057; }
  blockquote.bq--primary {
    border-color: #2b3990; }
  blockquote.bq--secondary {
    border-color: #f26622; }
  blockquote.bq--highlight {
    border-color: #28a1c5; }
  blockquote.bq--accent {
    border-color: #9C81BB; }
  blockquote.bq--success {
    border-color: #28a745; }
  blockquote.bq--danger {
    border-color: #dc3545; }
  blockquote.bq--warning {
    border-color: #ffc107; }
  blockquote.bq--info {
    border-color: #6610f2; }
  blockquote.review {
    padding: 0;
    margin: 0 0 1em;
    font-size: 1rem;
    border: none; }
  @media (min-width: 768px) {
    blockquote {
      font-size: 125%; } }

h1, h2, h3, h4, h5, h6,
.h1, .h2, .h3, .h4, .h5, .h6 {
  margin: 1.5em 0 0.5em;
  font-family: "Montserrat", "Helvetica Neue", Helvetica, Arial, sans-serif;
  font-weight: 900;
  font-style: italic;
  line-height: 1em;
  text-transform: uppercase; }
  h1 small, h2 small, h3 small, h4 small, h5 small, h6 small,
  .h1 small, .h2 small, .h3 small, .h4 small, .h5 small, .h6 small {
    display: block;
    font-weight: 400;
    color: #777; }

h1, .h1 {
  font-size: 1.8rem;
  letter-spacing: -0.0275em; }
  h1 small, .h1 small {
    font-weight: 400; }
  @media (min-width: 992px) {
    h1, .h1 {
      font-size: 2.0rem; } }
  @media (min-width: 1200px) {
    h1, .h1 {
      font-size: 2.2rem; } }

h2, .h2 {
  font-size: 1.6rem;
  font-weight: 700;
  letter-spacing: -0.02em; }
  @media (min-width: 1200px) {
    h2, .h2 {
      font-size: 1.8rem; } }

h3, .h3 {
  font-size: 1.4rem;
  letter-spacing: -0.0175em; }
  @media (min-width: 1200px) {
    h3, .h3 {
      font-size: 1.6rem; } }

h4, .h4 {
  font-size: 1.2rem;
  font-weight: 700;
  letter-spacing: -0.015em; }
  @media (min-width: 1200px) {
    h4, .h4 {
      font-size: 1.4rem; } }

h5, .h5 {
  font-size: 1rem;
  letter-spacing: -0.0125em; }

h6, .h6 {
  font-size: 1rem;
  letter-spacing: -0.0125em; }

hr {
  display: block;
  margin: 1.5em 0; }
  hr[class*="hr--fade"] {
    border: 0;
    height: 1px;
    background-color: #495057;
    background: linear-gradient(to right, transparent, #495057, transparent); }
  hr[class*="hr--dotted"] {
    border: 0;
    border-bottom: 1px dashed #fff;
    background: #495057; }
  hr[class*="hr--shadow"] {
    height: 12px;
    border: 0;
    box-shadow: inset 0 12px 12px -12px rgba(0, 0, 0, 0.5); }
  hr[class*="hr--engraved"] {
    border: 0;
    height: 0;
    border-top: 1px solid rgba(0, 0, 0, 0.12);
    border-bottom: 2px solid #fff; }

a {
  font-style: normal;
  text-decoration: underline;
  color: #17a2b8; }
  a:hover, a:focus {
    color: #0f6674;
    text-decoration: none; }
  a:focus {
    outline: 1px solid #f9b99a; }

.phone {
  text-decoration: none;
  cursor: default;
  color: #2b3990; }
  .phone:hover {
    text-decoration: none;
    color: #2b3990;
    outline: none;
    cursor: default; }

.phone.mask {
  color: #495057; }
  .phone.mask:hover {
    outline: none;
    color: #495057; }

ul, ol {
  margin: 0 0 1em;
  padding: 0 0 0 1.2em; }

.list_inline li:not(:last-child), .list_unstyled li:not(:last-child), .list li:not(:last-child) {
  margin-bottom: 0.25em; }

.list_inline, .list_unstyled {
  margin-left: 0;
  padding-left: 0;
  list-style-type: none;
  list-style-image: url(data:image/gif;base64,R0lGODlhAQABAIAAAAAAAP///yH5BAEAAAAALAAAAAABAAEAAAIBRAA7); }

.list_inline li {
  display: inline-block;
  margin: 0 0.5rem 0.5rem 0 !important; }

.list_buttons a {
  border-radius: 3px; }

.list_icon {
  margin-left: 0;
  padding-left: 0; }
  .list_icon > li {
    list-style-type: none;
    line-height: 1em; }
    .list_icon > li:not(:last-child) {
      margin-bottom: 0.75em; }
  .list_icon li > ul {
    margin: 1em 0;
    list-style-type: square; }
    .list_icon li > ul > li:not(:last-child) {
      margin-bottom: 0.5em; }
  .list_icon > li {
    padding-left: 1.75em;
    font-size: 1.125rem;
    background-repeat: no-repeat;
    background-size: 1.25rem;
    background-position: left 0.1em; }

.icon_check-primary > li {
  background-image: url("/_/images/icons/cm_primary.svg"); }

.icon_check-secondary > li {
  background-image: url("/_/images/icons/cm_secondary.svg"); }

.icon_check-success > li {
  background-image: url("/_/images/icons/cm_success.svg"); }

.sa_review_list {
  margin-left: 0;
  padding-left: 0;
  list-style-type: none;
  list-style-image: url(data:image/gif;base64,R0lGODlhAQABAIAAAAAAAP///yH5BAEAAAAALAAAAAABAAEAAAIBRAA7); }
  .sa_review_list li:not(:last-child) {
    margin-bottom: 0.25em; }
  .sa_review_list span {
    display: inline-block;
    padding: 0.25em 0.6em;
    font-size: 1.1rem;
    font-weight: 800;
    text-transform: uppercase;
    letter-spacing: -0.015em;
    background-color: #2b3990;
    color: #fff; }
  @media (min-width: 576px) {
    .sa_review_list li {
      display: inline-block;
      margin: 0 0.5em 0 0 !important; } }

figure {
  border: 1px solid #ccc;
  background-color: #fff;
  margin: 0;
  padding: 0.5em; }
  figure figcaption {
    margin: 0.5em 0.5em 0;
    font-size: 0.9rem;
    line-height: 1.275em; }

img {
  display: block;
  width: 100%;
  border-style: none; }

.img--captioned {
  max-width: 380px;
  padding: 10px;
  border: 1px solid #ccc;
  background-color: #fff;
  margin-bottom: 15px; }
  .img--captioned img {
    padding: 0;
    border: none;
    margin-bottom: 0; }
  .img--captioned span {
    display: block;
    max-width: 360px;
    margin: 5px 0 0;
    font-size: 0.95rem;
    line-height: 1.275em;
    color: #606a73; }

.img--main {
  margin: 0 0 15px;
  max-width: 360px; }

.img--thumbnail {
  padding: 5px;
  border: 1px solid #ddd;
  background-color: #fff; }

@media (min-width: 768px) {
  .img--right {
    clear: both;
    float: right;
    width: 45%;
    margin: 0 0 15px 20px; } }

@media (min-width: 768px) {
  .img--left {
    clear: both;
    float: left;
    width: 45%;
    margin: 0 20px 15px 0; } }

.img--wide {
  margin: 0 0 15px;
  max-width: none; }

.img--coupon {
  max-width: 640px; }

.embed-responsive {
  position: relative;
  display: block;
  height: 0;
  padding: 0;
  overflow: hidden; }
  .embed-responsive.embed-responsive-16by9 {
    padding-bottom: 56.25%; }
  .embed-responsive.embed-responsive-4by3 {
    padding-bottom: 75%; }
  .embed-responsive .embed-responsive-item, .embed-responsive embed, .embed-responsive iframe, .embed-responsive object, .embed-responsive video {
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    height: 100%;
    width: 100%;
    border: 0; }

.location_map {
  margin-bottom: 1em;
  padding: 0.5em;
  border: 1px solid #ccc;
  background-color: #fff; }

.hidden {
  display: none !important; }

@media (max-width: 575px) {
  .hidden--xs {
    display: none !important; } }

@media (min-width: 576px) and (max-width: 767px) {
  .hidden--sm {
    display: none !important; } }

@media (min-width: 768px) and (max-width: 991px) {
  .hidden--md {
    display: none !important; } }

@media (min-width: 992px) and (max-width: 1199px) {
  .hidden--lg {
    display: none !important; } }

@media (min-width: 1200px) {
  .hidden--xl {
    display: none !important; } }

[class*="visible-"] {
  display: none; }

@media (max-width: 575px) {
  .visible--xs {
    display: block !important; } }

@media (min-width: 576px) and (max-width: 767px) {
  .visible--sm {
    display: block !important; } }

@media (min-width: 768px) and (max-width: 991px) {
  .visible--md {
    display: block !important; } }

@media (min-width: 992px) and (max-width: 1199px) {
  .visible--lg {
    display: block !important; } }

@media (min-width: 1200px) {
  .visible--xl {
    display: block !important; } }

.db {
  display: block; }

.mt0 {
  margin-top: 0 !important; }

.mb0 {
  margin-bottom: 0 !important; }

.mt15 {
  margin-top: 15px !important; }

.mb15 {
  margin-bottom: 15px !important; }

.notrfm {
  transform: none !important; }

.sr-only {
  position: absolute;
  width: 1px;
  height: 1px;
  margin: -1px;
  padding: 0;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  border: 0; }

.txt--up {
  text-transform: uppercase; }

.txt--cap {
  text-transform: capitalize; }

.txt--lg {
  font-size: 115%; }

.txt--md {
  font-size: inherit; }

.txt--sm {
  font-size: 85%; }

.lh1 {
  line-height: 1.1em; }

.lh125 {
  line-height: 1.25em; }

.txt--left {
  text-align: left; }

.txt--right {
  text-align: right; }

.txt--center {
  text-align: center; }

.txt--wb0 {
  font-weight: 400; }

.txt--wb1 {
  font-weight: 700; }

.txt--wb2 {
  font-weight: 700; }

.color--text {
  color: #495057; }

.color--white {
  color: #fff; }

.color--primary {
  color: #2b3990; }

.color--secondary {
  color: #f26622; }

.color--highlight {
  color: #28a1c5; }

.color--link {
  color: #17a2b8; }

.color--review {
  color: #f90; }

.color--success {
  color: #28a745; }

.color--danger {
  color: #dc3545; }

.color--warning {
  color: #ffc107; }

.color--info {
  color: #6610f2; }

.bg--text {
  background-color: #495057; }

.bg--white {
  background-color: #fff; }

.bg--primary {
  background-color: #2b3990; }

.bg--secondary {
  background-color: #f26622; }

.bg--highlight {
  background-color: #28a1c5; }

.bg--link {
  background-color: #17a2b8; }

.bg--review {
  background-color: #f90; }

.bg--success {
  background-color: #28a745; }

.bg--danger {
  background-color: #dc3545; }

.bg--warning {
  background-color: #ffc107; }

.bg--info {
  background-color: #6610f2; }

.gdfancybg--text {
  background: #495057;
  background: linear-gradient(45deg, rgba(0, 0, 0, 0.1) 0%, transparent 25%, transparent 75%, rgba(0, 0, 0, 0.1) 100%), linear-gradient(135deg, rgba(0, 0, 0, 0.1) 0%, rgba(0, 0, 0, 0.1) 10%, transparent 45%, transparent 55%, rgba(0, 0, 0, 0.1) 90%, rgba(0, 0, 0, 0.1) 100%), linear-gradient(45deg, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 0) 20%, rgba(255, 255, 255, 0.22) 35%, rgba(255, 255, 255, 0) 35%, rgba(255, 255, 255, 0) 100%), linear-gradient(45deg, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 0) 60%, rgba(255, 255, 255, 0.22) 75%, rgba(255, 255, 255, 0) 75%, rgba(255, 255, 255, 0) 100%), linear-gradient(135deg, rgba(255, 255, 255, 0) 0, rgba(255, 255, 255, 0) 15%, rgba(255, 255, 255, 0.3) 50%, rgba(255, 255, 255, 0) 90%, rgba(255, 255, 255, 0) 100%), #495057; }

.gdfancybg--white {
  background: #fff;
  background: linear-gradient(45deg, rgba(0, 0, 0, 0.1) 0%, transparent 25%, transparent 75%, rgba(0, 0, 0, 0.1) 100%), linear-gradient(135deg, rgba(0, 0, 0, 0.1) 0%, rgba(0, 0, 0, 0.1) 10%, transparent 45%, transparent 55%, rgba(0, 0, 0, 0.1) 90%, rgba(0, 0, 0, 0.1) 100%), linear-gradient(45deg, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 0) 20%, rgba(255, 255, 255, 0.22) 35%, rgba(255, 255, 255, 0) 35%, rgba(255, 255, 255, 0) 100%), linear-gradient(45deg, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 0) 60%, rgba(255, 255, 255, 0.22) 75%, rgba(255, 255, 255, 0) 75%, rgba(255, 255, 255, 0) 100%), linear-gradient(135deg, rgba(255, 255, 255, 0) 0, rgba(255, 255, 255, 0) 15%, rgba(255, 255, 255, 0.3) 50%, rgba(255, 255, 255, 0) 90%, rgba(255, 255, 255, 0) 100%), #fff; }

.gdfancybg--primary {
  background: #2b3990;
  background: linear-gradient(45deg, rgba(0, 0, 0, 0.1) 0%, transparent 25%, transparent 75%, rgba(0, 0, 0, 0.1) 100%), linear-gradient(135deg, rgba(0, 0, 0, 0.1) 0%, rgba(0, 0, 0, 0.1) 10%, transparent 45%, transparent 55%, rgba(0, 0, 0, 0.1) 90%, rgba(0, 0, 0, 0.1) 100%), linear-gradient(45deg, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 0) 20%, rgba(255, 255, 255, 0.22) 35%, rgba(255, 255, 255, 0) 35%, rgba(255, 255, 255, 0) 100%), linear-gradient(45deg, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 0) 60%, rgba(255, 255, 255, 0.22) 75%, rgba(255, 255, 255, 0) 75%, rgba(255, 255, 255, 0) 100%), linear-gradient(135deg, rgba(255, 255, 255, 0) 0, rgba(255, 255, 255, 0) 15%, rgba(255, 255, 255, 0.3) 50%, rgba(255, 255, 255, 0) 90%, rgba(255, 255, 255, 0) 100%), #2b3990; }

.gdfancybg--secondary {
  background: #f26622;
  background: linear-gradient(45deg, rgba(0, 0, 0, 0.1) 0%, transparent 25%, transparent 75%, rgba(0, 0, 0, 0.1) 100%), linear-gradient(135deg, rgba(0, 0, 0, 0.1) 0%, rgba(0, 0, 0, 0.1) 10%, transparent 45%, transparent 55%, rgba(0, 0, 0, 0.1) 90%, rgba(0, 0, 0, 0.1) 100%), linear-gradient(45deg, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 0) 20%, rgba(255, 255, 255, 0.22) 35%, rgba(255, 255, 255, 0) 35%, rgba(255, 255, 255, 0) 100%), linear-gradient(45deg, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 0) 60%, rgba(255, 255, 255, 0.22) 75%, rgba(255, 255, 255, 0) 75%, rgba(255, 255, 255, 0) 100%), linear-gradient(135deg, rgba(255, 255, 255, 0) 0, rgba(255, 255, 255, 0) 15%, rgba(255, 255, 255, 0.3) 50%, rgba(255, 255, 255, 0) 90%, rgba(255, 255, 255, 0) 100%), #f26622; }

.gdfancybg--highlight {
  background: #28a1c5;
  background: linear-gradient(45deg, rgba(0, 0, 0, 0.1) 0%, transparent 25%, transparent 75%, rgba(0, 0, 0, 0.1) 100%), linear-gradient(135deg, rgba(0, 0, 0, 0.1) 0%, rgba(0, 0, 0, 0.1) 10%, transparent 45%, transparent 55%, rgba(0, 0, 0, 0.1) 90%, rgba(0, 0, 0, 0.1) 100%), linear-gradient(45deg, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 0) 20%, rgba(255, 255, 255, 0.22) 35%, rgba(255, 255, 255, 0) 35%, rgba(255, 255, 255, 0) 100%), linear-gradient(45deg, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 0) 60%, rgba(255, 255, 255, 0.22) 75%, rgba(255, 255, 255, 0) 75%, rgba(255, 255, 255, 0) 100%), linear-gradient(135deg, rgba(255, 255, 255, 0) 0, rgba(255, 255, 255, 0) 15%, rgba(255, 255, 255, 0.3) 50%, rgba(255, 255, 255, 0) 90%, rgba(255, 255, 255, 0) 100%), #28a1c5; }

.gdfancybg--review {
  background: #f90;
  background: linear-gradient(45deg, rgba(0, 0, 0, 0.1) 0%, transparent 25%, transparent 75%, rgba(0, 0, 0, 0.1) 100%), linear-gradient(135deg, rgba(0, 0, 0, 0.1) 0%, rgba(0, 0, 0, 0.1) 10%, transparent 45%, transparent 55%, rgba(0, 0, 0, 0.1) 90%, rgba(0, 0, 0, 0.1) 100%), linear-gradient(45deg, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 0) 20%, rgba(255, 255, 255, 0.22) 35%, rgba(255, 255, 255, 0) 35%, rgba(255, 255, 255, 0) 100%), linear-gradient(45deg, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 0) 60%, rgba(255, 255, 255, 0.22) 75%, rgba(255, 255, 255, 0) 75%, rgba(255, 255, 255, 0) 100%), linear-gradient(135deg, rgba(255, 255, 255, 0) 0, rgba(255, 255, 255, 0) 15%, rgba(255, 255, 255, 0.3) 50%, rgba(255, 255, 255, 0) 90%, rgba(255, 255, 255, 0) 100%), #f90; }

.gdfancybg--success {
  background: #28a745;
  background: linear-gradient(45deg, rgba(0, 0, 0, 0.1) 0%, transparent 25%, transparent 75%, rgba(0, 0, 0, 0.1) 100%), linear-gradient(135deg, rgba(0, 0, 0, 0.1) 0%, rgba(0, 0, 0, 0.1) 10%, transparent 45%, transparent 55%, rgba(0, 0, 0, 0.1) 90%, rgba(0, 0, 0, 0.1) 100%), linear-gradient(45deg, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 0) 20%, rgba(255, 255, 255, 0.22) 35%, rgba(255, 255, 255, 0) 35%, rgba(255, 255, 255, 0) 100%), linear-gradient(45deg, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 0) 60%, rgba(255, 255, 255, 0.22) 75%, rgba(255, 255, 255, 0) 75%, rgba(255, 255, 255, 0) 100%), linear-gradient(135deg, rgba(255, 255, 255, 0) 0, rgba(255, 255, 255, 0) 15%, rgba(255, 255, 255, 0.3) 50%, rgba(255, 255, 255, 0) 90%, rgba(255, 255, 255, 0) 100%), #28a745; }

.gdfancybg--danger {
  background: #dc3545;
  background: linear-gradient(45deg, rgba(0, 0, 0, 0.1) 0%, transparent 25%, transparent 75%, rgba(0, 0, 0, 0.1) 100%), linear-gradient(135deg, rgba(0, 0, 0, 0.1) 0%, rgba(0, 0, 0, 0.1) 10%, transparent 45%, transparent 55%, rgba(0, 0, 0, 0.1) 90%, rgba(0, 0, 0, 0.1) 100%), linear-gradient(45deg, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 0) 20%, rgba(255, 255, 255, 0.22) 35%, rgba(255, 255, 255, 0) 35%, rgba(255, 255, 255, 0) 100%), linear-gradient(45deg, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 0) 60%, rgba(255, 255, 255, 0.22) 75%, rgba(255, 255, 255, 0) 75%, rgba(255, 255, 255, 0) 100%), linear-gradient(135deg, rgba(255, 255, 255, 0) 0, rgba(255, 255, 255, 0) 15%, rgba(255, 255, 255, 0.3) 50%, rgba(255, 255, 255, 0) 90%, rgba(255, 255, 255, 0) 100%), #dc3545; }

.gdfancybg--warning {
  background: #ffc107;
  background: linear-gradient(45deg, rgba(0, 0, 0, 0.1) 0%, transparent 25%, transparent 75%, rgba(0, 0, 0, 0.1) 100%), linear-gradient(135deg, rgba(0, 0, 0, 0.1) 0%, rgba(0, 0, 0, 0.1) 10%, transparent 45%, transparent 55%, rgba(0, 0, 0, 0.1) 90%, rgba(0, 0, 0, 0.1) 100%), linear-gradient(45deg, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 0) 20%, rgba(255, 255, 255, 0.22) 35%, rgba(255, 255, 255, 0) 35%, rgba(255, 255, 255, 0) 100%), linear-gradient(45deg, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 0) 60%, rgba(255, 255, 255, 0.22) 75%, rgba(255, 255, 255, 0) 75%, rgba(255, 255, 255, 0) 100%), linear-gradient(135deg, rgba(255, 255, 255, 0) 0, rgba(255, 255, 255, 0) 15%, rgba(255, 255, 255, 0.3) 50%, rgba(255, 255, 255, 0) 90%, rgba(255, 255, 255, 0) 100%), #ffc107; }

.gdfancybg--info {
  background: #6610f2;
  background: linear-gradient(45deg, rgba(0, 0, 0, 0.1) 0%, transparent 25%, transparent 75%, rgba(0, 0, 0, 0.1) 100%), linear-gradient(135deg, rgba(0, 0, 0, 0.1) 0%, rgba(0, 0, 0, 0.1) 10%, transparent 45%, transparent 55%, rgba(0, 0, 0, 0.1) 90%, rgba(0, 0, 0, 0.1) 100%), linear-gradient(45deg, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 0) 20%, rgba(255, 255, 255, 0.22) 35%, rgba(255, 255, 255, 0) 35%, rgba(255, 255, 255, 0) 100%), linear-gradient(45deg, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 0) 60%, rgba(255, 255, 255, 0.22) 75%, rgba(255, 255, 255, 0) 75%, rgba(255, 255, 255, 0) 100%), linear-gradient(135deg, rgba(255, 255, 255, 0) 0, rgba(255, 255, 255, 0) 15%, rgba(255, 255, 255, 0.3) 50%, rgba(255, 255, 255, 0) 90%, rgba(255, 255, 255, 0) 100%), #6610f2; }

.table {
  width: 100%;
  max-width: 100%;
  margin-bottom: 15px;
  font-size: 14px;
  line-height: 1em;
  text-align: left;
  background-color: #fff;
  border-spacing: 0;
  border-collapse: collapse;
  -webkit-box-sizing: border-box;
  box-sizing: border-box; }
  .table * {
    -webkit-box-sizing: border-box;
    box-sizing: border-box; }
  .table th,
  .table td {
    padding: 0.5em;
    vertical-align: top;
    line-height: 1em;
    border-top: 1px solid #ccc; }
  .table thead th {
    font-size: 16px;
    line-height: 1em;
    vertical-align: middle;
    border-bottom: 2px solid #ccc; }
  .table tbody + tbody {
    border-top: 2px solid #ccc; }

.table-bordered {
  border: 1px solid #ccc; }
  .table-bordered th, .table-bordered td {
    border: 1px solid #ccc; }
  .table-bordered thead th, .table-bordered thead td {
    border-bottom-width: 2px; }

.table-striped tbody tr:nth-of-type(odd) {
  background-color: #f3f3f3; }

.table-hover tbody tr:hover {
  background-color: #fff4d3;
  cursor: pointer; }

@media (max-width: 767px) {
  .table-responsive {
    display: block;
    width: 100%;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
    -ms-overflow-style: -ms-autohiding-scrollbar; }
    .table-responsive > .table-bordered {
      border: 0; } }

.site_alert > .alert:first-of-type {
  margin-top: 1em; }

.site_alert > .alert:last-of-type {
  margin-bottom: 0; }

.alert {
  border: 1px solid #c4c4c4;
  background-color: white;
  color: #495057;
  margin-bottom: 1em;
  padding: 1em;
  border-radius: 3px; }
  .alert strong, .alert a {
    color: #b7b7b7; }
  .alert strong {
    font-weight: 800; }

.alert-primary {
  border: 1px solid #1f2969;
  background-color: #a3ace4;
  color: #495057; }
  .alert-primary strong, .alert-primary a {
    color: #192255; }

.alert-secondary {
  border: 1px solid #d54e0d;
  background-color: #fdebe3;
  color: #495057; }
  .alert-secondary strong, .alert-secondary a {
    color: #bc450b; }

.alert-highlight {
  border: 1px solid #1f7e9b;
  background-color: #c6e9f3;
  color: #495057; }
  .alert-highlight strong, .alert-highlight a {
    color: #1b6d85; }

.alert-accent {
  border: 1px solid #8260a9;
  background-color: white;
  color: #495057; }
  .alert-accent strong, .alert-accent a {
    color: #75549c; }

.alert-success {
  border: 1px solid #1e7e34;
  background-color: #afecbd;
  color: #495057; }
  .alert-success strong, .alert-success a {
    color: #19692c; }

.alert-danger {
  border: 1px solid #bd2130;
  background-color: #fae3e5;
  color: #495057; }
  .alert-danger strong, .alert-danger a {
    color: #a71d2a; }

.alert-info {
  border: 1px solid #510bc4;
  background-color: #e2d1fd;
  color: #495057; }
  .alert-info strong, .alert-info a {
    color: #4709ac; }

.alert-warning {
  border: 1px solid #d39e00;
  background-color: #fff4d3;
  color: #495057; }
  .alert-warning strong, .alert-warning a {
    color: #ba8b00; }

.alert-link {
  border: 1px solid #117a8b;
  background-color: #a7e9f4;
  color: #495057; }
  .alert-link strong, .alert-link a {
    color: #0f6674; }

.announce {
  margin: 1em 0 0;
  font-size: 1rem; }
  .announce p.greview {
    margin: 0 0 1em;
    line-height: 1em;
    text-align: center; }
    .announce p.greview img {
      display: inline-block;
      width: 140px;
      margin: 0; }
    .announce p.greview a {
      text-decoration: none;
      color: #333; }
    .announce p.greview small {
      display: inline-block;
      margin-top: 5px;
      font-size: 14px;
      font-weight: 600;
      line-height: 1em; }
  .announce .announce-wrap p {
    margin-bottom: 1em;
    font-size: 16px;
    font-weight: 700;
    line-height: 1em;
    text-transform: uppercase; }
    .announce .announce-wrap p:last-child {
      margin-bottom: 0; }
    .announce .announce-wrap p span {
      padding: 0.25em 0.5em;
      background-color: #f26622;
      color: #fff; }
    .announce .announce-wrap p:first-child span {
      background-color: #d54e0d; }
    .announce .announce-wrap p:last-child span {
      background-color: #f58752; }
  @media (min-width: 576px) {
    .announce {
      overflow: hidden; }
      .announce .announce-wrap p {
        display: inline-block;
        margin-bottom: 0.65em; }
      .announce p.greview {
        white-space: nowrap; }
        .announce p.greview br {
          display: none; }
        .announce p.greview img {
          width: 120px;
          margin: 0;
          vertical-align: middle; } }
  @media (min-width: 768px) {
    .announce {
      margin-top: 0; }
      .announce .announce-wrap p span {
        padding: 0.5em 0.75em; } }
  @media (min-width: 992px) {
    .announce {
      margin-top: -30px; }
      .announce p.greview {
        margin-bottom: 1.25em; }
      .announce .announce-wrap p:not(:last-child) {
        margin-right: 0.5em; } }

.roll_item {
  margin: 0 0 2em;
  padding: 1.25em; }

.roll_header a {
  -webkit-transition: background ease-in-out 300ms;
  -moz-transition: background ease-in-out 300ms;
  -ms-transition: background ease-in-out 300ms;
  -o-transition: background ease-in-out 300ms;
  transition: background ease-in-out 300ms;
  overflow: hidden;
  display: block;
  margin: -1.25em -1.25em 1em;
  padding: 0.5em 1.25em;
  background-color: #f26622;
  color: #fff;
  text-decoration: none; }
  .roll_header a:hover {
    -webkit-transition: background ease-in-out 300ms;
    -moz-transition: background ease-in-out 300ms;
    -ms-transition: background ease-in-out 300ms;
    -o-transition: background ease-in-out 300ms;
    transition: background ease-in-out 300ms;
    background-color: #f47f46; }
    .roll_header a:hover .roll_time {
      -webkit-transition: background ease-in-out 300ms;
      -moz-transition: background ease-in-out 300ms;
      -ms-transition: background ease-in-out 300ms;
      -o-transition: background ease-in-out 300ms;
      transition: background ease-in-out 300ms;
      background-color: transparent; }

.roll_time {
  -webkit-transition: background ease-in-out 300ms;
  -moz-transition: background ease-in-out 300ms;
  -ms-transition: background ease-in-out 300ms;
  -o-transition: background ease-in-out 300ms;
  transition: background ease-in-out 300ms;
  margin: -0.6em -1.6em 0.5em;
  padding: 0.5em 1.5em;
  font-size: 0.8em;
  line-height: 1.2em;
  background-color: rgba(0, 0, 0, 0.3); }
  .roll_time strong {
    display: block;
    text-transform: uppercase; }
  @media (min-width: 576px) {
    .roll_time strong {
      display: inline-block; }
      .roll_time strong:after {
        content: ":"; } }

.roll_title {
  margin: 0;
  font-size: 1.3rem; }

[class*="btn"] {
  display: inline-block;
  padding: 1em 1.25em;
  text-decoration: none;
  font-family: "Montserrat", "Helvetica Neue", Helvetica, Arial, sans-serif;
  font-style: italic;
  font-weight: 900;
  text-transform: uppercase;
  border: 1px solid #117a8b;
  background-color: #17a2b8;
  color: #fff;
  -webkit-transition: all ease-in-out 200ms;
  -moz-transition: all ease-in-out 200ms;
  -ms-transition: all ease-in-out 200ms;
  -o-transition: all ease-in-out 200ms;
  transition: all ease-in-out 200ms;
  line-height: 1em;
  cursor: pointer; }
  [class*="btn"]:link, [class*="btn"]:visited, [class*="btn"]:active {
    display: inline-block;
    padding: 1em 1.25em;
    text-decoration: none;
    text-shadow: 0 1px 1px rgba(0, 0, 0, 0.3);
    border: 1px solid #117a8b;
    background-color: #17a2b8;
    color: #fff;
    -webkit-transition: all ease-in-out 200ms;
    -moz-transition: all ease-in-out 200ms;
    -ms-transition: all ease-in-out 200ms;
    -o-transition: all ease-in-out 200ms;
    transition: all ease-in-out 200ms; }
  [class*="btn"]:hover, [class*="btn"]:focus {
    background-color: #36cee6;
    color: #fff;
    -webkit-transition: all ease-in-out 200ms;
    -moz-transition: all ease-in-out 200ms;
    -ms-transition: all ease-in-out 200ms;
    -o-transition: all ease-in-out 200ms;
    transition: all ease-in-out 200ms; }
  [class*="btn"] small {
    display: block;
    line-height: 1em; }
  [class*="btn"]:focus {
    outline: 4px solid rgba(0, 0, 0, 0.25); }

.btn_text {
  display: inline-block;
  padding: 1em 1.25em;
  text-decoration: none;
  font-family: "Montserrat", "Helvetica Neue", Helvetica, Arial, sans-serif;
  font-style: italic;
  font-weight: 900;
  text-transform: uppercase;
  border: 1px solid #32373b;
  background-color: #495057;
  color: #fff;
  -webkit-transition: all ease-in-out 200ms;
  -moz-transition: all ease-in-out 200ms;
  -ms-transition: all ease-in-out 200ms;
  -o-transition: all ease-in-out 200ms;
  transition: all ease-in-out 200ms; }
  .btn_text:link, .btn_text:visited, .btn_text:active {
    display: inline-block;
    padding: 1em 1.25em;
    text-decoration: none;
    text-shadow: 0 1px 1px rgba(0, 0, 0, 0.3);
    border: 1px solid #32373b;
    background-color: #495057;
    color: #fff;
    -webkit-transition: all ease-in-out 200ms;
    -moz-transition: all ease-in-out 200ms;
    -ms-transition: all ease-in-out 200ms;
    -o-transition: all ease-in-out 200ms;
    transition: all ease-in-out 200ms; }
  .btn_text:hover, .btn_text:focus {
    background-color: #6c7681;
    color: #fff;
    -webkit-transition: all ease-in-out 200ms;
    -moz-transition: all ease-in-out 200ms;
    -ms-transition: all ease-in-out 200ms;
    -o-transition: all ease-in-out 200ms;
    transition: all ease-in-out 200ms; }

.list_buttons a, .btn_link {
  display: inline-block;
  padding: 1em 1.25em;
  text-decoration: none;
  font-family: "Montserrat", "Helvetica Neue", Helvetica, Arial, sans-serif;
  font-style: italic;
  font-weight: 900;
  text-transform: uppercase;
  border: 1px solid #117a8b;
  background-color: #17a2b8;
  color: #fff;
  -webkit-transition: all ease-in-out 200ms;
  -moz-transition: all ease-in-out 200ms;
  -ms-transition: all ease-in-out 200ms;
  -o-transition: all ease-in-out 200ms;
  transition: all ease-in-out 200ms; }
  .list_buttons a:link, .list_buttons a:visited, .list_buttons a:active, .btn_link:link, .btn_link:visited, .btn_link:active {
    display: inline-block;
    padding: 1em 1.25em;
    text-decoration: none;
    text-shadow: 0 1px 1px rgba(0, 0, 0, 0.3);
    border: 1px solid #117a8b;
    background-color: #17a2b8;
    color: #fff;
    -webkit-transition: all ease-in-out 200ms;
    -moz-transition: all ease-in-out 200ms;
    -ms-transition: all ease-in-out 200ms;
    -o-transition: all ease-in-out 200ms;
    transition: all ease-in-out 200ms; }
  .list_buttons a:hover, .list_buttons a:focus, .btn_link:hover, .btn_link:focus {
    background-color: #36cee6;
    color: #fff;
    -webkit-transition: all ease-in-out 200ms;
    -moz-transition: all ease-in-out 200ms;
    -ms-transition: all ease-in-out 200ms;
    -o-transition: all ease-in-out 200ms;
    transition: all ease-in-out 200ms; }

.btn_primary {
  display: inline-block;
  padding: 1em 1.25em;
  text-decoration: none;
  font-family: "Montserrat", "Helvetica Neue", Helvetica, Arial, sans-serif;
  font-style: italic;
  font-weight: 900;
  text-transform: uppercase;
  border: 1px solid #1f2969;
  background-color: #2b3990;
  color: #fff;
  -webkit-transition: all ease-in-out 200ms;
  -moz-transition: all ease-in-out 200ms;
  -ms-transition: all ease-in-out 200ms;
  -o-transition: all ease-in-out 200ms;
  transition: all ease-in-out 200ms; }
  .btn_primary:link, .btn_primary:visited, .btn_primary:active {
    display: inline-block;
    padding: 1em 1.25em;
    text-decoration: none;
    text-shadow: 0 1px 1px rgba(0, 0, 0, 0.3);
    border: 1px solid #1f2969;
    background-color: #2b3990;
    color: #fff;
    -webkit-transition: all ease-in-out 200ms;
    -moz-transition: all ease-in-out 200ms;
    -ms-transition: all ease-in-out 200ms;
    -o-transition: all ease-in-out 200ms;
    transition: all ease-in-out 200ms; }
  .btn_primary:hover, .btn_primary:focus {
    background-color: #4154c6;
    color: #fff;
    -webkit-transition: all ease-in-out 200ms;
    -moz-transition: all ease-in-out 200ms;
    -ms-transition: all ease-in-out 200ms;
    -o-transition: all ease-in-out 200ms;
    transition: all ease-in-out 200ms; }

.btn_secondary {
  display: inline-block;
  padding: 1em 1.25em;
  text-decoration: none;
  font-family: "Montserrat", "Helvetica Neue", Helvetica, Arial, sans-serif;
  font-style: italic;
  font-weight: 900;
  text-transform: uppercase;
  border: 1px solid #d54e0d;
  background-color: #f26622;
  color: #fff;
  -webkit-transition: all ease-in-out 200ms;
  -moz-transition: all ease-in-out 200ms;
  -ms-transition: all ease-in-out 200ms;
  -o-transition: all ease-in-out 200ms;
  transition: all ease-in-out 200ms; }
  .btn_secondary:link, .btn_secondary:visited, .btn_secondary:active {
    display: inline-block;
    padding: 1em 1.25em;
    text-decoration: none;
    text-shadow: 0 1px 1px rgba(0, 0, 0, 0.3);
    border: 1px solid #d54e0d;
    background-color: #f26622;
    color: #fff;
    -webkit-transition: all ease-in-out 200ms;
    -moz-transition: all ease-in-out 200ms;
    -ms-transition: all ease-in-out 200ms;
    -o-transition: all ease-in-out 200ms;
    transition: all ease-in-out 200ms; }
  .btn_secondary:hover, .btn_secondary:focus {
    background-color: #f6986a;
    color: #fff;
    -webkit-transition: all ease-in-out 200ms;
    -moz-transition: all ease-in-out 200ms;
    -ms-transition: all ease-in-out 200ms;
    -o-transition: all ease-in-out 200ms;
    transition: all ease-in-out 200ms; }

.btn_highlight {
  display: inline-block;
  padding: 1em 1.25em;
  text-decoration: none;
  font-family: "Montserrat", "Helvetica Neue", Helvetica, Arial, sans-serif;
  font-style: italic;
  font-weight: 900;
  text-transform: uppercase;
  border: 1px solid #1f7e9b;
  background-color: #28a1c5;
  color: #fff;
  -webkit-transition: all ease-in-out 200ms;
  -moz-transition: all ease-in-out 200ms;
  -ms-transition: all ease-in-out 200ms;
  -o-transition: all ease-in-out 200ms;
  transition: all ease-in-out 200ms; }
  .btn_highlight:link, .btn_highlight:visited, .btn_highlight:active {
    display: inline-block;
    padding: 1em 1.25em;
    text-decoration: none;
    text-shadow: 0 1px 1px rgba(0, 0, 0, 0.3);
    border: 1px solid #1f7e9b;
    background-color: #28a1c5;
    color: #fff;
    -webkit-transition: all ease-in-out 200ms;
    -moz-transition: all ease-in-out 200ms;
    -ms-transition: all ease-in-out 200ms;
    -o-transition: all ease-in-out 200ms;
    transition: all ease-in-out 200ms; }
  .btn_highlight:hover, .btn_highlight:focus {
    background-color: #5cc0de;
    color: #fff;
    -webkit-transition: all ease-in-out 200ms;
    -moz-transition: all ease-in-out 200ms;
    -ms-transition: all ease-in-out 200ms;
    -o-transition: all ease-in-out 200ms;
    transition: all ease-in-out 200ms; }

.btn_accent {
  display: inline-block;
  padding: 1em 1.25em;
  text-decoration: none;
  font-family: "Montserrat", "Helvetica Neue", Helvetica, Arial, sans-serif;
  font-style: italic;
  font-weight: 900;
  text-transform: uppercase;
  border: 1px solid #8260a9;
  background-color: #9C81BB;
  color: #fff;
  -webkit-transition: all ease-in-out 200ms;
  -moz-transition: all ease-in-out 200ms;
  -ms-transition: all ease-in-out 200ms;
  -o-transition: all ease-in-out 200ms;
  transition: all ease-in-out 200ms; }
  .btn_accent:link, .btn_accent:visited, .btn_accent:active {
    display: inline-block;
    padding: 1em 1.25em;
    text-decoration: none;
    text-shadow: 0 1px 1px rgba(0, 0, 0, 0.3);
    border: 1px solid #8260a9;
    background-color: #9C81BB;
    color: #fff;
    -webkit-transition: all ease-in-out 200ms;
    -moz-transition: all ease-in-out 200ms;
    -ms-transition: all ease-in-out 200ms;
    -o-transition: all ease-in-out 200ms;
    transition: all ease-in-out 200ms; }
  .btn_accent:hover, .btn_accent:focus {
    background-color: #c3b3d6;
    color: #fff;
    -webkit-transition: all ease-in-out 200ms;
    -moz-transition: all ease-in-out 200ms;
    -ms-transition: all ease-in-out 200ms;
    -o-transition: all ease-in-out 200ms;
    transition: all ease-in-out 200ms; }

.btn_success {
  display: inline-block;
  padding: 1em 1.25em;
  text-decoration: none;
  font-family: "Montserrat", "Helvetica Neue", Helvetica, Arial, sans-serif;
  font-style: italic;
  font-weight: 900;
  text-transform: uppercase;
  border: 1px solid #1e7e34;
  background-color: #28a745;
  color: #fff;
  -webkit-transition: all ease-in-out 200ms;
  -moz-transition: all ease-in-out 200ms;
  -ms-transition: all ease-in-out 200ms;
  -o-transition: all ease-in-out 200ms;
  transition: all ease-in-out 200ms; }
  .btn_success:link, .btn_success:visited, .btn_success:active {
    display: inline-block;
    padding: 1em 1.25em;
    text-decoration: none;
    text-shadow: 0 1px 1px rgba(0, 0, 0, 0.3);
    border: 1px solid #1e7e34;
    background-color: #28a745;
    color: #fff;
    -webkit-transition: all ease-in-out 200ms;
    -moz-transition: all ease-in-out 200ms;
    -ms-transition: all ease-in-out 200ms;
    -o-transition: all ease-in-out 200ms;
    transition: all ease-in-out 200ms; }
  .btn_success:hover, .btn_success:focus {
    background-color: #48d368;
    color: #fff;
    -webkit-transition: all ease-in-out 200ms;
    -moz-transition: all ease-in-out 200ms;
    -ms-transition: all ease-in-out 200ms;
    -o-transition: all ease-in-out 200ms;
    transition: all ease-in-out 200ms; }

.btn_danger {
  display: inline-block;
  padding: 1em 1.25em;
  text-decoration: none;
  font-family: "Montserrat", "Helvetica Neue", Helvetica, Arial, sans-serif;
  font-style: italic;
  font-weight: 900;
  text-transform: uppercase;
  border: 1px solid #bd2130;
  background-color: #dc3545;
  color: #fff;
  -webkit-transition: all ease-in-out 200ms;
  -moz-transition: all ease-in-out 200ms;
  -ms-transition: all ease-in-out 200ms;
  -o-transition: all ease-in-out 200ms;
  transition: all ease-in-out 200ms; }
  .btn_danger:link, .btn_danger:visited, .btn_danger:active {
    display: inline-block;
    padding: 1em 1.25em;
    text-decoration: none;
    text-shadow: 0 1px 1px rgba(0, 0, 0, 0.3);
    border: 1px solid #bd2130;
    background-color: #dc3545;
    color: #fff;
    -webkit-transition: all ease-in-out 200ms;
    -moz-transition: all ease-in-out 200ms;
    -ms-transition: all ease-in-out 200ms;
    -o-transition: all ease-in-out 200ms;
    transition: all ease-in-out 200ms; }
  .btn_danger:hover, .btn_danger:focus {
    background-color: #e77681;
    color: #fff;
    -webkit-transition: all ease-in-out 200ms;
    -moz-transition: all ease-in-out 200ms;
    -ms-transition: all ease-in-out 200ms;
    -o-transition: all ease-in-out 200ms;
    transition: all ease-in-out 200ms; }

.btn_info {
  display: inline-block;
  padding: 1em 1.25em;
  text-decoration: none;
  font-family: "Montserrat", "Helvetica Neue", Helvetica, Arial, sans-serif;
  font-style: italic;
  font-weight: 900;
  text-transform: uppercase;
  border: 1px solid #510bc4;
  background-color: #6610f2;
  color: #fff;
  -webkit-transition: all ease-in-out 200ms;
  -moz-transition: all ease-in-out 200ms;
  -ms-transition: all ease-in-out 200ms;
  -o-transition: all ease-in-out 200ms;
  transition: all ease-in-out 200ms; }
  .btn_info:link, .btn_info:visited, .btn_info:active {
    display: inline-block;
    padding: 1em 1.25em;
    text-decoration: none;
    text-shadow: 0 1px 1px rgba(0, 0, 0, 0.3);
    border: 1px solid #510bc4;
    background-color: #6610f2;
    color: #fff;
    -webkit-transition: all ease-in-out 200ms;
    -moz-transition: all ease-in-out 200ms;
    -ms-transition: all ease-in-out 200ms;
    -o-transition: all ease-in-out 200ms;
    transition: all ease-in-out 200ms; }
  .btn_info:hover, .btn_info:focus {
    background-color: #9459f6;
    color: #fff;
    -webkit-transition: all ease-in-out 200ms;
    -moz-transition: all ease-in-out 200ms;
    -ms-transition: all ease-in-out 200ms;
    -o-transition: all ease-in-out 200ms;
    transition: all ease-in-out 200ms; }

.btn_review {
  display: inline-block;
  padding: 1em 1.25em;
  text-decoration: none;
  font-family: "Montserrat", "Helvetica Neue", Helvetica, Arial, sans-serif;
  font-style: italic;
  font-weight: 900;
  text-transform: uppercase;
  border: 1px solid #cc7a00;
  background-color: #f90;
  color: #fff;
  -webkit-transition: all ease-in-out 200ms;
  -moz-transition: all ease-in-out 200ms;
  -ms-transition: all ease-in-out 200ms;
  -o-transition: all ease-in-out 200ms;
  transition: all ease-in-out 200ms; }
  .btn_review:link, .btn_review:visited, .btn_review:active {
    display: inline-block;
    padding: 1em 1.25em;
    text-decoration: none;
    text-shadow: 0 1px 1px rgba(0, 0, 0, 0.3);
    border: 1px solid #cc7a00;
    background-color: #f90;
    color: #fff;
    -webkit-transition: all ease-in-out 200ms;
    -moz-transition: all ease-in-out 200ms;
    -ms-transition: all ease-in-out 200ms;
    -o-transition: all ease-in-out 200ms;
    transition: all ease-in-out 200ms; }
  .btn_review:hover, .btn_review:focus {
    background-color: #ffb84d;
    color: #fff;
    -webkit-transition: all ease-in-out 200ms;
    -moz-transition: all ease-in-out 200ms;
    -ms-transition: all ease-in-out 200ms;
    -o-transition: all ease-in-out 200ms;
    transition: all ease-in-out 200ms; }

.btn_warning {
  display: inline-block;
  padding: 1em 1.25em;
  text-decoration: none;
  font-family: "Montserrat", "Helvetica Neue", Helvetica, Arial, sans-serif;
  font-style: italic;
  font-weight: 900;
  text-transform: uppercase;
  border: 1px solid #d39e00;
  background-color: #ffc107;
  color: #fff;
  -webkit-transition: all ease-in-out 200ms;
  -moz-transition: all ease-in-out 200ms;
  -ms-transition: all ease-in-out 200ms;
  -o-transition: all ease-in-out 200ms;
  transition: all ease-in-out 200ms; }
  .btn_warning:link, .btn_warning:visited, .btn_warning:active {
    display: inline-block;
    padding: 1em 1.25em;
    text-decoration: none;
    text-shadow: 0 1px 1px rgba(0, 0, 0, 0.3);
    border: 1px solid #d39e00;
    background-color: #ffc107;
    color: #fff;
    -webkit-transition: all ease-in-out 200ms;
    -moz-transition: all ease-in-out 200ms;
    -ms-transition: all ease-in-out 200ms;
    -o-transition: all ease-in-out 200ms;
    transition: all ease-in-out 200ms; }
  .btn_warning:hover, .btn_warning:focus {
    background-color: #ffd454;
    color: #fff;
    -webkit-transition: all ease-in-out 200ms;
    -moz-transition: all ease-in-out 200ms;
    -ms-transition: all ease-in-out 200ms;
    -o-transition: all ease-in-out 200ms;
    transition: all ease-in-out 200ms; }

.btn-block {
  display: block;
  text-align: center; }

.btn_close {
  float: right;
  margin: 0 0 0 15px;
  padding: 5px;
  font-size: 24px;
  line-height: 1em;
  text-align: center;
  border-radius: 3px;
  border: 1px solid #a71d2a;
  background-color: #dc3545;
  color: #fff;
  opacity: 0.8;
  cursor: pointer; }
  .btn_close .close_img {
    width: 0.75rem; }
  .btn_close:hover {
    background-color: #921925; }

.list_buttons a, .btn--sm {
  padding: 0.75em 1em !important;
  font-size: 0.925rem !important; }

.btn--wide {
  display: block;
  width: 100%; }

.btn_3d {
  padding: 15px 15px !important;
  border-width: 1px !important;
  border-bottom-width: 5px !important;
  -webkit-transition: all ease-in-out 150ms;
  -moz-transition: all ease-in-out 150ms;
  -ms-transition: all ease-in-out 150ms;
  -o-transition: all ease-in-out 150ms;
  transition: all ease-in-out 150ms; }
  .btn_3d:hover {
    padding: 18px 15px 16px !important;
    border-bottom-width: 1px !important;
    -webkit-transition: all ease-in-out 150ms;
    -moz-transition: all ease-in-out 150ms;
    -ms-transition: all ease-in-out 150ms;
    -o-transition: all ease-in-out 150ms;
    transition: all ease-in-out 150ms; }

[class*="btn_ghost"] {
  border-color: #495057;
  background: linear-gradient(to left, #fff 50%, #495057 50%);
  background-size: 200% 100%;
  background-position: top right;
  color: #495057;
  -webkit-transition: all ease-in-out 300ms;
  -moz-transition: all ease-in-out 300ms;
  -ms-transition: all ease-in-out 300ms;
  -o-transition: all ease-in-out 300ms;
  transition: all ease-in-out 300ms;
  border-width: 2px; }
  [class*="btn_ghost"]:hover {
    background-position: top left;
    color: #fff;
    -webkit-transition: all ease-in-out 300ms;
    -moz-transition: all ease-in-out 300ms;
    -ms-transition: all ease-in-out 300ms;
    -o-transition: all ease-in-out 300ms;
    transition: all ease-in-out 300ms; }

.btn_ghost-default {
  border-color: #17a2b8;
  background: linear-gradient(to left, #fff 50%, #17a2b8 50%);
  background-size: 200% 100%;
  background-position: top right;
  color: #17a2b8;
  -webkit-transition: all ease-in-out 300ms;
  -moz-transition: all ease-in-out 300ms;
  -ms-transition: all ease-in-out 300ms;
  -o-transition: all ease-in-out 300ms;
  transition: all ease-in-out 300ms; }
  .btn_ghost-default:hover {
    background-position: top left;
    color: #fff;
    -webkit-transition: all ease-in-out 300ms;
    -moz-transition: all ease-in-out 300ms;
    -ms-transition: all ease-in-out 300ms;
    -o-transition: all ease-in-out 300ms;
    transition: all ease-in-out 300ms; }

.btn_ghost-primary {
  border-color: #2b3990;
  background: linear-gradient(to left, #fff 50%, #2b3990 50%);
  background-size: 200% 100%;
  background-position: top right;
  color: #2b3990;
  -webkit-transition: all ease-in-out 300ms;
  -moz-transition: all ease-in-out 300ms;
  -ms-transition: all ease-in-out 300ms;
  -o-transition: all ease-in-out 300ms;
  transition: all ease-in-out 300ms; }
  .btn_ghost-primary:hover {
    background-position: top left;
    color: #fff;
    -webkit-transition: all ease-in-out 300ms;
    -moz-transition: all ease-in-out 300ms;
    -ms-transition: all ease-in-out 300ms;
    -o-transition: all ease-in-out 300ms;
    transition: all ease-in-out 300ms; }

.btn_ghost-secondary {
  border-color: #f26622;
  background: linear-gradient(to left, #fff 50%, #f26622 50%);
  background-size: 200% 100%;
  background-position: top right;
  color: #f26622;
  -webkit-transition: all ease-in-out 300ms;
  -moz-transition: all ease-in-out 300ms;
  -ms-transition: all ease-in-out 300ms;
  -o-transition: all ease-in-out 300ms;
  transition: all ease-in-out 300ms; }
  .btn_ghost-secondary:hover {
    background-position: top left;
    color: #fff;
    -webkit-transition: all ease-in-out 300ms;
    -moz-transition: all ease-in-out 300ms;
    -ms-transition: all ease-in-out 300ms;
    -o-transition: all ease-in-out 300ms;
    transition: all ease-in-out 300ms; }

.btn_ghost-highlight {
  border-color: #28a1c5;
  background: linear-gradient(to left, #fff 50%, #28a1c5 50%);
  background-size: 200% 100%;
  background-position: top right;
  color: #28a1c5;
  -webkit-transition: all ease-in-out 300ms;
  -moz-transition: all ease-in-out 300ms;
  -ms-transition: all ease-in-out 300ms;
  -o-transition: all ease-in-out 300ms;
  transition: all ease-in-out 300ms; }
  .btn_ghost-highlight:hover {
    background-position: top left;
    color: #fff;
    -webkit-transition: all ease-in-out 300ms;
    -moz-transition: all ease-in-out 300ms;
    -ms-transition: all ease-in-out 300ms;
    -o-transition: all ease-in-out 300ms;
    transition: all ease-in-out 300ms; }

.btn_ghost-accent {
  border-color: #9C81BB;
  background: linear-gradient(to left, #fff 50%, #9C81BB 50%);
  background-size: 200% 100%;
  background-position: top right;
  color: #9C81BB;
  -webkit-transition: all ease-in-out 300ms;
  -moz-transition: all ease-in-out 300ms;
  -ms-transition: all ease-in-out 300ms;
  -o-transition: all ease-in-out 300ms;
  transition: all ease-in-out 300ms; }
  .btn_ghost-accent:hover {
    background-position: top left;
    color: #fff;
    -webkit-transition: all ease-in-out 300ms;
    -moz-transition: all ease-in-out 300ms;
    -ms-transition: all ease-in-out 300ms;
    -o-transition: all ease-in-out 300ms;
    transition: all ease-in-out 300ms; }

.btn_ghost-success {
  border-color: #28a745;
  background: linear-gradient(to left, #fff 50%, #28a745 50%);
  background-size: 200% 100%;
  background-position: top right;
  color: #28a745;
  -webkit-transition: all ease-in-out 300ms;
  -moz-transition: all ease-in-out 300ms;
  -ms-transition: all ease-in-out 300ms;
  -o-transition: all ease-in-out 300ms;
  transition: all ease-in-out 300ms; }
  .btn_ghost-success:hover {
    background-position: top left;
    color: #fff;
    -webkit-transition: all ease-in-out 300ms;
    -moz-transition: all ease-in-out 300ms;
    -ms-transition: all ease-in-out 300ms;
    -o-transition: all ease-in-out 300ms;
    transition: all ease-in-out 300ms; }

.btn_ghost-danger {
  border-color: #dc3545;
  background: linear-gradient(to left, #fff 50%, #dc3545 50%);
  background-size: 200% 100%;
  background-position: top right;
  color: #dc3545;
  -webkit-transition: all ease-in-out 300ms;
  -moz-transition: all ease-in-out 300ms;
  -ms-transition: all ease-in-out 300ms;
  -o-transition: all ease-in-out 300ms;
  transition: all ease-in-out 300ms; }
  .btn_ghost-danger:hover {
    background-position: top left;
    color: #fff;
    -webkit-transition: all ease-in-out 300ms;
    -moz-transition: all ease-in-out 300ms;
    -ms-transition: all ease-in-out 300ms;
    -o-transition: all ease-in-out 300ms;
    transition: all ease-in-out 300ms; }

.btn_ghost-info {
  border-color: #6610f2;
  background: linear-gradient(to left, #fff 50%, #6610f2 50%);
  background-size: 200% 100%;
  background-position: top right;
  color: #6610f2;
  -webkit-transition: all ease-in-out 300ms;
  -moz-transition: all ease-in-out 300ms;
  -ms-transition: all ease-in-out 300ms;
  -o-transition: all ease-in-out 300ms;
  transition: all ease-in-out 300ms; }
  .btn_ghost-info:hover {
    background-position: top left;
    color: #fff;
    -webkit-transition: all ease-in-out 300ms;
    -moz-transition: all ease-in-out 300ms;
    -ms-transition: all ease-in-out 300ms;
    -o-transition: all ease-in-out 300ms;
    transition: all ease-in-out 300ms; }

.btn_ghost-review {
  border-color: #f90;
  background: linear-gradient(to left, #fff 50%, #f90 50%);
  background-size: 200% 100%;
  background-position: top right;
  color: #f90;
  -webkit-transition: all ease-in-out 300ms;
  -moz-transition: all ease-in-out 300ms;
  -ms-transition: all ease-in-out 300ms;
  -o-transition: all ease-in-out 300ms;
  transition: all ease-in-out 300ms; }
  .btn_ghost-review:hover {
    background-position: top left;
    color: #fff;
    -webkit-transition: all ease-in-out 300ms;
    -moz-transition: all ease-in-out 300ms;
    -ms-transition: all ease-in-out 300ms;
    -o-transition: all ease-in-out 300ms;
    transition: all ease-in-out 300ms; }

.btn_ghost-warning {
  border-color: #ffc107;
  background: linear-gradient(to left, #fff 50%, #ffc107 50%);
  background-size: 200% 100%;
  background-position: top right;
  color: #ffc107;
  -webkit-transition: all ease-in-out 300ms;
  -moz-transition: all ease-in-out 300ms;
  -ms-transition: all ease-in-out 300ms;
  -o-transition: all ease-in-out 300ms;
  transition: all ease-in-out 300ms; }
  .btn_ghost-warning:hover {
    background-position: top left;
    color: #fff;
    -webkit-transition: all ease-in-out 300ms;
    -moz-transition: all ease-in-out 300ms;
    -ms-transition: all ease-in-out 300ms;
    -o-transition: all ease-in-out 300ms;
    transition: all ease-in-out 300ms; }

.faq_item {
  margin-bottom: 1em;
  border: 1px solid #ccc;
  background-color: #fff; }
  .faq_item .faq_title h2 {
    margin: 0;
    padding: 0.5em;
    font-size: 1.2rem;
    letter-spacing: 0; }
    .faq_item .faq_title h2:hover {
      background-color: #f26622;
      color: #fff;
      cursor: pointer; }
  .faq_item.open .faq_title h2 {
    background-color: #f26622;
    color: #fff; }
  .faq_item .faq_body {
    padding: 1em 1em 0.5em; }
    .faq_item .faq_body p, .faq_item .faq_body ul, .faq_item .faq_body ol {
      margin-bottom: 0.5em;
      line-height: 1.4em; }
    .faq_item .faq_body ul li:not(:last-child), .faq_item .faq_body ol li:not(:last-child) {
      margin-bottom: 0.25em; }

.form {
  font-size: 1em; }
  .form.form_career textarea {
    height: 180px; }

.form_group-wrap {
  box-shadow: none;
  border: 2px solid #f26622;
  margin: 0 0 1.25em;
  padding: 0 0.25em 0.75em; }
  .form_group-wrap p {
    padding: 0 0.75em; }

.group_title {
  display: block;
  width: 70%;
  padding: 0.5em 0.75em;
  font-size: 1.1rem;
  font-family: "Montserrat", "Helvetica Neue", Helvetica, Arial, sans-serif;
  font-style: italic;
  font-weight: 900;
  text-transform: uppercase;
  background: #fcfcfc;
  color: #2b3990; }

.form_security {
  overflow: hidden;
  margin-bottom: 1em; }

.form_title {
  margin: 0 0.75em 0.5em;
  font-size: 1.4rem; }
  .form_title small {
    display: block;
    font-size: 1rem;
    font-weight: 400;
    letter-spacing: 0;
    text-transform: uppercase; }

.form_group {
  padding: 0 0.75em 0.1em; }
  .form_group.form_hp {
    display: none; }

.form_error {
  border: 1px solid #dc3545;
  background-color: #fae3e5; }

.error-message {
  margin: 0 0 0.5em;
  padding: 0 0 0 3px !important;
  font-size: 0.7em;
  font-weight: 800;
  line-height: 1em;
  text-transform: uppercase;
  color: #d32535; }

.form_control {
  display: block;
  width: 100%;
  margin: 0 0 5px;
  padding: 9px 7px;
  font-size: 1rem;
  border: 1px solid #ddd;
  outline: none;
  background-color: #fff; }
  .form_control:focus {
    border-color: #ffc107;
    background-color: #fff4d3; }

textarea.form_control {
  min-height: 100px; }

@media (max-width: 575px) {
  .form_spam {
    position: relative;
    overflow: hidden;
    height: 75px; }
    .form_spam .g-recaptcha {
      -webkit-transform: scale(0.8);
      -moz-transform: scale(0.8);
      -o-transform: scale(0.8);
      transform: scale(0.8);
      position: absolute;
      top: 0;
      left: -18px; } }

@media (min-width: 992px) and (max-width: 1199px) {
  .fix-lg .form_spam {
    position: relative;
    overflow: hidden;
    height: 80px; }
    .fix-lg .form_spam .g-recaptcha {
      -webkit-transform: scale(0.9);
      -moz-transform: scale(0.9);
      -o-transform: scale(0.9);
      transform: scale(0.9);
      position: absolute;
      top: 0;
      left: -2px; } }

.form_submit {
  margin-top: 0.25rem; }
  .form_submit .btn_submit {
    font-family: "Montserrat", "Helvetica Neue", Helvetica, Arial, sans-serif;
    font-style: italic;
    font-weight: 900;
    text-transform: uppercase; }

@media (min-width: 768px) {
  .form_lr .form_group {
    padding: 0 0.25em 0.1em; }
  .form_lr .form_left,
  .form_lr .form_right {
    width: 50%; }
  .form_lr .form_left {
    float: left;
    clear: both; }
  .form_lr .form_right {
    float: right; }
  .form_lr .form_clear {
    clear: both; }
  .form_lr .form_state {
    width: 20%;
    clear: none; }
  .form_lr .form_zip {
    width: 30%; } }

@media (min-width: 768px) and (max-width: 991px) {
  .form_lr--sm .form_left,
  .form_lr--sm .form_right {
    width: 50%; }
  .form_lr--sm .form_left {
    float: left;
    padding-right: 0.25em; }
  .form_lr--sm .form_right {
    float: right;
    padding-left: 0.25em; }
  .form_lr--sm .form_clear {
    clear: both; } }

.form_newsletter {
  border: none;
  overflow: hidden; }
  @media (min-width: 576px) {
    .form_newsletter .form-email {
      float: left;
      width: 75%;
      padding-left: 0; }
      .form_newsletter .form-email .form-control {
        padding: 11px 6px; }
    .form_newsletter .form-submit {
      float: right;
      width: 25%;
      padding-left: 0; }
    .form_newsletter .btn {
      margin-top: 6px;
      padding: 8px 16px; }
      .form_newsletter .btn:hover {
        padding: 10px 16px; } }

.form_quote {
  padding: 0 0 0.75em;
  border: 1px solid #ddd;
  background-color: #fff; }
  .form_quote .form_title {
    margin: 0 0 0.75em;
    padding: 0.5em 0.75em;
    background-color: #2b3990;
    color: #fff; }
    .form_quote .form_title small {
      color: #fff; }
  body.page_front .form_quote .form_title {
    background-color: #2b3990; }
  body.page_contact .form_quote {
    padding: 0;
    border: none;
    background-color: transparent;
    box-shadow: none; }
    @media (min-width: 768px) {
      body.page_contact .form_quote .form_group {
        padding: 0 0.25em 0.1em; }
      body.page_contact .form_quote .form_left,
      body.page_contact .form_quote .form_right {
        width: 50%; }
      body.page_contact .form_quote .form_left {
        float: left;
        clear: both; }
      body.page_contact .form_quote .form_right {
        float: right; }
      body.page_contact .form_quote .form_clear {
        clear: both; }
      body.page_contact .form_quote .form_state {
        width: 20%;
        clear: none; }
      body.page_contact .form_quote .form_zip {
        width: 30%; } }

.form_review .review_text {
  margin-bottom: 1em; }
  .form_review .review_text .form_control {
    height: 200px; }

.radio_group {
  margin-bottom: 1em;
  line-height: 1em; }
  .radio_group > label {
    position: relative;
    display: inline-block;
    margin-right: 0.5em;
    min-width: 120px;
    vertical-align: baseline;
    top: -0.35em; }
  .radio_group .error-message {
    clear: both;
    margin-top: 0.75em; }

.rating_recommend p {
  margin: 0.4em 0 0;
  padding: 0; }

.rating_recommend .error-message {
  clear: both;
  margin-top: 0.75em; }

.rating_recommend .radio_yn,
.rating_recommend .radio_yn-label {
  box-sizing: border-box;
  display: inline-block;
  width: 70px;
  height: 36px;
  margin: 0 0 0 -5px;
  padding: 0.3em 0;
  font-size: 1.3rem;
  line-height: 1em;
  text-align: left;
  vertical-align: top;
  border: 1px solid #ccc;
  outline: none;
  cursor: pointer; }
  @media (min-width: 768px) {
    .rating_recommend .radio_yn,
    .rating_recommend .radio_yn-label {
      height: 39px; } }

.rating_recommend .radio_yn:checked + .radio_yn-label {
  background-color: #2b3990 !important;
  color: #fff; }

.rating_recommend .radio_yn:hover + .radio_yn-label {
  background-color: #aaa; }

.rating_recommend .radio_yn-label {
  margin-left: -70px;
  background-color: #fff;
  text-align: center; }

.rating {
  overflow: hidden;
  display: inline-block; }

.rating-input {
  float: right;
  width: 24px;
  height: 24px;
  padding: 0;
  margin: 0 0 0 -24px;
  opacity: 0; }

.rating-star {
  cursor: pointer;
  position: relative;
  float: right;
  display: block;
  width: 28px;
  height: 25px;
  background: url("/_/images/icons/star-rating.png") 0 -24px no-repeat; }

.rating-star:hover,
.rating-star:hover ~ .rating-star,
.rating-input:checked ~ .rating-star {
  background-position: 0 0; }

.gallery {
  overflow: hidden;
  margin-bottom: 1em; }

.img-captioned img {
  margin-bottom: 0.5em; }

.img-captioned span {
  display: block;
  margin: 0 0 1em;
  padding: 0 0.75em 0;
  font-size: 87.5%;
  line-height: 1.25em;
  transform: skew(-10deg); }

.gallery_sidebar img {
  margin-bottom: 0.5em; }

.gallery_lightbox img {
  padding: 4px;
  border: 1px solid #ccc;
  background-color: #fff; }

.gallery_lightbox > a {
  margin-bottom: 1%; }

@media (max-width: 575px) {
  .g2-xs > img, .g2-xs > a, .g2-xs > .img-captioned {
    display: block;
    float: left;
    width: 49.5%; }
    .g2-xs > img:nth-child(odd), .g2-xs > a:nth-child(odd), .g2-xs > .img-captioned:nth-child(odd) {
      clear: both; }
    .g2-xs > img:nth-child(even), .g2-xs > a:nth-child(even), .g2-xs > .img-captioned:nth-child(even) {
      float: right; } }

@media (min-width: 576px) and (max-width: 767px) {
  .g2-sm > img, .g2-sm > a, .g2-sm > .img-captioned {
    display: block;
    float: left;
    width: 49.5%; }
    .g2-sm > img:nth-child(odd), .g2-sm > a:nth-child(odd), .g2-sm > .img-captioned:nth-child(odd) {
      clear: both; }
    .g2-sm > img:nth-child(even), .g2-sm > a:nth-child(even), .g2-sm > .img-captioned:nth-child(even) {
      float: right; } }

@media (min-width: 768px) and (max-width: 991px) {
  .g2-md > img, .g2-md > a, .g2-md > .img-captioned {
    display: block;
    float: left;
    width: 49.5%; }
    .g2-md > img:nth-child(odd), .g2-md > a:nth-child(odd), .g2-md > .img-captioned:nth-child(odd) {
      clear: both; }
    .g2-md > img:nth-child(even), .g2-md > a:nth-child(even), .g2-md > .img-captioned:nth-child(even) {
      float: right; } }

@media (min-width: 992px) and (max-width: 1199px) {
  .g2-lg > img, .g2-lg > a, .g2-lg > .img-captioned {
    display: block;
    float: left;
    width: 49.5%; }
    .g2-lg > img:nth-child(odd), .g2-lg > a:nth-child(odd), .g2-lg > .img-captioned:nth-child(odd) {
      clear: both; }
    .g2-lg > img:nth-child(even), .g2-lg > a:nth-child(even), .g2-lg > .img-captioned:nth-child(even) {
      float: right; } }

@media (min-width: 1200px) {
  .g2-xl > img, .g2-xl > a, .g2-xl > .img-captioned {
    display: block;
    float: left;
    width: 49.5%; }
    .g2-xl > img:nth-child(odd), .g2-xl > a:nth-child(odd), .g2-xl > .img-captioned:nth-child(odd) {
      clear: both; }
    .g2-xl > img:nth-child(even), .g2-xl > a:nth-child(even), .g2-xl > .img-captioned:nth-child(even) {
      float: right; } }

@media (min-width: 576px) and (max-width: 767px) {
  .g3-sm > img, .g3-sm > a, .g3-sm > .img-captioned {
    display: block;
    float: left;
    width: 32.66667%;
    margin-right: 1%; }
    .g3-sm > img:nth-child(3n-2), .g3-sm > a:nth-child(3n-2), .g3-sm > .img-captioned:nth-child(3n-2) {
      clear: both; }
    .g3-sm > img:nth-child(3n), .g3-sm > a:nth-child(3n), .g3-sm > .img-captioned:nth-child(3n) {
      float: right;
      margin-right: 0; } }

@media (min-width: 768px) and (max-width: 991px) {
  .g3-md > img, .g3-md > a, .g3-md > .img-captioned {
    display: block;
    float: left;
    width: 32.66667%;
    margin-right: 1%; }
    .g3-md > img:nth-child(3n-2), .g3-md > a:nth-child(3n-2), .g3-md > .img-captioned:nth-child(3n-2) {
      clear: both; }
    .g3-md > img:nth-child(3n), .g3-md > a:nth-child(3n), .g3-md > .img-captioned:nth-child(3n) {
      float: right;
      margin-right: 0; } }

@media (min-width: 992px) and (max-width: 1199px) {
  .g3-lg > img, .g3-lg > a, .g3-lg > .img-captioned {
    display: block;
    float: left;
    width: 32.66667%;
    margin-right: 1%; }
    .g3-lg > img:nth-child(3n-2), .g3-lg > a:nth-child(3n-2), .g3-lg > .img-captioned:nth-child(3n-2) {
      clear: both; }
    .g3-lg > img:nth-child(3n), .g3-lg > a:nth-child(3n), .g3-lg > .img-captioned:nth-child(3n) {
      float: right;
      margin-right: 0; } }

@media (min-width: 1200px) {
  .g3-xl > img, .g3-xl > a, .g3-xl > .img-captioned {
    display: block;
    float: left;
    width: 32.66667%;
    margin-right: 1%; }
    .g3-xl > img:nth-child(3n-2), .g3-xl > a:nth-child(3n-2), .g3-xl > .img-captioned:nth-child(3n-2) {
      clear: both; }
    .g3-xl > img:nth-child(3n), .g3-xl > a:nth-child(3n), .g3-xl > .img-captioned:nth-child(3n) {
      float: right;
      margin-right: 0; } }

@media (min-width: 576px) and (max-width: 767px) {
  .g4-sm > img, .g4-sm > a, .g4-sm > .img-captioned {
    display: block;
    float: left;
    width: 24.25%;
    margin-right: 1%; }
    .g4-sm > img:nth-child(4n-3), .g4-sm > a:nth-child(4n-3), .g4-sm > .img-captioned:nth-child(4n-3) {
      clear: both; }
    .g4-sm > img:nth-child(4n), .g4-sm > a:nth-child(4n), .g4-sm > .img-captioned:nth-child(4n) {
      float: right;
      margin-right: 0; } }

@media (min-width: 768px) and (max-width: 991px) {
  .g4-md > img, .g4-md > a, .g4-md > .img-captioned {
    display: block;
    float: left;
    width: 24.25%;
    margin-right: 1%; }
    .g4-md > img:nth-child(4n-3), .g4-md > a:nth-child(4n-3), .g4-md > .img-captioned:nth-child(4n-3) {
      clear: both; }
    .g4-md > img:nth-child(4n), .g4-md > a:nth-child(4n), .g4-md > .img-captioned:nth-child(4n) {
      float: right;
      margin-right: 0; } }

@media (min-width: 992px) and (max-width: 1199px) {
  .g4-lg > img, .g4-lg > a, .g4-lg > .img-captioned {
    display: block;
    float: left;
    width: 24.25%;
    margin-right: 1%; }
    .g4-lg > img:nth-child(4n-3), .g4-lg > a:nth-child(4n-3), .g4-lg > .img-captioned:nth-child(4n-3) {
      clear: both; }
    .g4-lg > img:nth-child(4n), .g4-lg > a:nth-child(4n), .g4-lg > .img-captioned:nth-child(4n) {
      float: right;
      margin-right: 0; } }

@media (min-width: 1200px) {
  .g4-xl > img, .g4-xl > a, .g4-xl > .img-captioned {
    display: block;
    float: left;
    width: 24.25%;
    margin-right: 1%; }
    .g4-xl > img:nth-child(4n-3), .g4-xl > a:nth-child(4n-3), .g4-xl > .img-captioned:nth-child(4n-3) {
      clear: both; }
    .g4-xl > img:nth-child(4n), .g4-xl > a:nth-child(4n), .g4-xl > .img-captioned:nth-child(4n) {
      float: right;
      margin-right: 0; } }

.gallery_featured {
  padding: 1em;
  line-height: 1em;
  border: 1px solid #ccc;
  background-color: #fff; }
  .gallery_featured p {
    line-height: 1.6em; }
  @media (max-width: 575px) {
    .gallery_featured {
      margin-bottom: 1em; } }
  @media (min-width: 576px) {
    .gallery_featured {
      padding-right: 60px; } }
  @media (min-width: 768px) {
    .gallery_featured {
      margin-bottom: -7px;
      overflow: hidden; }
      .gallery_featured .gallery_cover {
        float: left;
        width: 55%;
        padding-right: 0.5em; }
      .gallery_featured .gallery_info {
        float: left;
        width: 45%;
        padding-left: 0.5em; }
      .gallery_featured .cover_thumbs {
        margin-bottom: 0; } }

.gallery_title {
  margin: 0.5em 0 0.5em;
  font-size: 1.5rem;
  font-weight: 800;
  font-style: italic;
  letter-spacing: -0.0375em;
  line-height: 1em;
  text-transform: uppercase; }

.cover_thumbs {
  overflow: hidden;
  margin: 1% 0 1em; }
  .cover_thumbs > a {
    -webkit-transition: opacity ease-in 300ms;
    -moz-transition: opacity ease-in 300ms;
    -ms-transition: opacity ease-in 300ms;
    -o-transition: opacity ease-in 300ms;
    transition: opacity ease-in 300ms;
    display: block;
    float: left;
    width: 24.25%;
    margin-right: 1%;
    opacity: 0.5; }
    .cover_thumbs > a:last-child {
      margin-right: 0; }
    .cover_thumbs > a:hover {
      -webkit-transition: opacity ease-in 300ms;
      -moz-transition: opacity ease-in 300ms;
      -ms-transition: opacity ease-in 300ms;
      -o-transition: opacity ease-in 300ms;
      transition: opacity ease-in 300ms;
      opacity: 1.0; }

.lightboxOverlay {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 99000;
  background-color: black;
  filter: progid:DXImageTransform.Microsoft.Alpha(Opacity=80);
  opacity: 0.8;
  display: none; }

.lightbox {
  position: absolute;
  left: 0;
  width: 100%;
  z-index: 99500;
  text-align: center;
  line-height: 0;
  font-weight: 400; }
  @media (min-width: 768px) {
    .lightbox {
      margin-top: 75px; } }

.lightbox .lb-image {
  display: block;
  width: 100%;
  max-width: 100%;
  height: auto;
  border-radius: 3px; }

.lightbox a img {
  border: none; }

.lb-outerContainer {
  position: relative;
  padding: 5px;
  overflow: hidden;
  background-color: white;
  *zoom: 1;
  margin: 0 auto;
  border-radius: 3px 3px 0 0; }

.lb-outerContainer:after {
  content: "";
  display: table;
  clear: both; }

.lb-loader {
  position: absolute;
  top: 43%;
  left: 0;
  height: 25%;
  width: 100%;
  text-align: center;
  line-height: 0; }

.lb-cancel {
  display: block;
  width: 32px;
  height: 32px;
  margin: 0 auto;
  background: url("/_/images/layout/loading.gif") no-repeat; }

.lb-nav {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  z-index: 10; }

.lb-container > .nav {
  left: 0; }

.lb-nav a {
  outline: none;
  background-image: url("data:image/gif;base64,R0lGODlhAQABAPAAAP///wAAACH5BAEAAAAALAAAAAABAAEAAAICRAEAOw=="); }

.lb-prev, .lb-next {
  height: 100%;
  cursor: pointer;
  display: block; }

.lb-nav a.lb-prev {
  width: 34%;
  left: 0;
  float: left;
  background: url("/_/images/layout/prev.png") left 48% no-repeat;
  filter: progid:DXImageTransform.Microsoft.Alpha(Opacity=0);
  opacity: 0;
  -webkit-transition: opacity 0.6s;
  -moz-transition: opacity 0.6s;
  -ms-transition: opacity 0.6s;
  -o-transition: opacity 0.6s;
  transition: opacity 0.6s; }

.lb-nav a.lb-prev:hover {
  filter: progid:DXImageTransform.Microsoft.Alpha(Opacity=100);
  opacity: 1; }

.lb-nav a.lb-next {
  width: 64%;
  right: 0;
  float: right;
  background: url("/_/images/layout/next.png") right 48% no-repeat;
  filter: progid:DXImageTransform.Microsoft.Alpha(Opacity=0);
  opacity: 0;
  -webkit-transition: opacity 0.6s;
  -moz-transition: opacity 0.6s;
  -ms-transition: opacity 0.6s;
  -o-transition: opacity 0.6s;
  transition: opacity 0.6s; }

.lb-nav a.lb-next:hover {
  filter: progid:DXImageTransform.Microsoft.Alpha(Opacity=100);
  opacity: 1; }

.lb-dataContainer {
  margin: 0 auto;
  padding: 7px 0;
  *zoom: 1;
  width: 100%;
  border-bottom-left-radius: 3px;
  border-bottom-right-radius: 3px;
  background-color: #fff;
  color: #495057; }

.lb-dataContainer:after {
  content: "";
  display: table;
  clear: both; }

.lb-data {
  padding: 0 4px; }

.lb-data .lb-details {
  width: 85%;
  float: left;
  text-align: left;
  line-height: 1.1em; }

.lb-data .lb-caption {
  display: inline-block;
  padding: 6px 5px 0;
  font-size: 1rem;
  font-weight: 800;
  text-transform: uppercase;
  line-height: 1em; }

.lb-data .lb-number {
  display: none !important;
  visibility: hidden !important; }

.lb-data .lb-close {
  display: block;
  float: right;
  margin-right: 5px;
  width: 30px;
  height: 30px;
  background: url("/_/images/icons/close-red.svg") center center no-repeat;
  background-size: 20px 20px;
  text-align: right;
  outline: none;
  filter: progid:DXImageTransform.Microsoft.Alpha(Opacity=70);
  opacity: 0.7;
  -webkit-transition: opacity 0.2s;
  -moz-transition: opacity 0.2s;
  -ms-transition: opacity 0.2s;
  -o-transition: opacity 0.2s;
  transition: opacity 0.2s; }

.lb-data .lb-close:hover {
  cursor: pointer;
  filter: progid:DXImageTransform.Microsoft.Alpha(Opacity=100);
  opacity: 1; }

body.modal-open .modal {
  overflow-x: hidden;
  overflow-y: auto; }

.modal {
  position: fixed;
  display: none;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.8);
  z-index: 99000;
  cursor: pointer; }
  .modal.show {
    display: block; }

.modal-dialog {
  position: relative;
  z-index: 99500;
  width: 90%;
  max-width: 640px;
  margin: 20px auto;
  padding: 15px;
  border-radius: 6px;
  border: 1px solid #ccc;
  background-color: #fff;
  cursor: default; }
  .modal-dialog p {
    margin-bottom: 0;
    padding: 10px 5px;
    font-size: 14px;
    line-height: 1.25em; }
    .modal-dialog p:nth-child(even) {
      background-color: #ddd; }

.modal-header {
  margin: 0 0 15px;
  padding: 0 0 10px;
  border-bottom: 1px solid #ccc; }

.modal-title {
  margin: 15px 0 0;
  font-size: 18px; }

.nav_menu {
  margin: 0;
  padding: 0;
  list-style-type: none; }

.menu_item {
  box-sizing: border-box;
  list-style-type: none; }
  .menu_item.active .menu_link {
    cursor: default; }
    .menu_item.active .menu_link:hover {
      cursor: default; }
  .menu_item.open .dropdown_menu {
    display: block;
    height: auto; }
  .menu_item.open .caret {
    -webkit-transform: rotate(180deg);
    -moz-transform: rotate(180deg);
    -o-transform: rotate(180deg);
    transform: rotate(180deg); }

.menu_link {
  -webkit-transition: all ease-in-out 300ms;
  -moz-transition: all ease-in-out 300ms;
  -ms-transition: all ease-in-out 300ms;
  -o-transition: all ease-in-out 300ms;
  transition: all ease-in-out 300ms;
  display: block;
  padding: 12px 15px;
  line-height: 1em;
  text-decoration: none; }
  .menu_link:hover, .menu_link:focus {
    -webkit-transition: all ease-in-out 300ms;
    -moz-transition: all ease-in-out 300ms;
    -ms-transition: all ease-in-out 300ms;
    -o-transition: all ease-in-out 300ms;
    transition: all ease-in-out 300ms;
    outline: none; }

.caret {
  display: inline-block;
  position: relative;
  vertical-align: top;
  width: 0.9em;
  height: 0.85em;
  background-image: url("/_/images/icons/dropdown-white.svg");
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center center; }

.dropdown-toggle:hover .caret,
.open .dropdown-toggle .caret {
  background-image: url("/_/images/icons/dropdown-white.svg"); }

.dropdown_menu {
  -webkit-transition: all ease-in-out 300ms;
  -moz-transition: all ease-in-out 300ms;
  -ms-transition: all ease-in-out 300ms;
  -o-transition: all ease-in-out 300ms;
  transition: all ease-in-out 300ms;
  display: none;
  position: relative;
  z-index: 95000;
  margin: 0;
  padding: 1em;
  min-width: 280px; }
  .dropdown_menu .menu_item {
    list-style-image: url(data:image/gif;base64,R0lGODlhAQABAIAAAAAAAP///yH5BAEAAAAALAAAAAABAAEAAAIBRAA7);
    text-align: left; }
  .dropdown_menu .menu_link {
    padding-left: 10px; }
    .dropdown_menu .menu_link:hover, .dropdown_menu .menu_link:focus {
      outline: none; }
  @media (min-width: 768px) {
    .dropdown_menu {
      position: absolute; } }

/* @import "modules/menus/nav_bar";*/
.mobile_button {
  line-height: 1em;
  text-align: left;
  background: transparent; }
  .mobile_button .mobile_toggle {
    width: 48px;
    height: 41px;
    padding: 10px 11px 9px;
    background-color: #f26622;
    border: none;
    border-radius: 4px;
    color: #fff; }
    .mobile_button .mobile_toggle:hover, .mobile_button .mobile_toggle:focus {
      outline: none;
      background-color: #f58752;
      cursor: pointer; }
  .mobile_button .button-bars {
    display: inline-block;
    height: 100%;
    width: 26px; }
  .mobile_button .icon-bar {
    -webkit-transition: transform ease-in-out 300ms;
    -moz-transition: transform ease-in-out 300ms;
    -ms-transition: transform ease-in-out 300ms;
    -o-transition: transform ease-in-out 300ms;
    transition: transform ease-in-out 300ms;
    display: block;
    width: 100%;
    height: 3px;
    border-radius: 4px;
    background-color: #fff; }
    .mobile_button .icon-bar:nth-child(2) {
      margin: 5px 0; }
  .mobile_button b {
    display: none; }
  .canvas-slid .mobile_button .icon-bar:nth-child(2) {
    visibility: hidden; }
  .canvas-slid .mobile_button .icon-bar:nth-child(1), .canvas-slid .mobile_button .icon-bar:nth-child(3) {
    -webkit-transition: transform ease-in-out 300ms;
    -moz-transition: transform ease-in-out 300ms;
    -ms-transition: transform ease-in-out 300ms;
    -o-transition: transform ease-in-out 300ms;
    transition: transform ease-in-out 300ms; }
  .canvas-slid .mobile_button .icon-bar:nth-child(1) {
    transform: rotate(45deg);
    margin-top: 9px; }
  .canvas-slid .mobile_button .icon-bar:nth-child(3) {
    transform: rotate(-45deg);
    margin-top: -16px; }

.nav_mobile {
  display: none;
  position: fixed;
  z-index: 999999;
  top: 0;
  right: auto;
  bottom: 0;
  left: 0;
  overflow-y: auto;
  width: 90%;
  max-width: 320px;
  height: 100%;
  padding: 30px 15px;
  border-right: 3px solid #fff;
  box-shadow: 2px 0 5px 0 rgba(0, 0, 0, 0.5);
  background-color: #f26622;
  color: #fff; }
  .nav_mobile.in {
    display: block; }
  @media (min-width: 768px) {
    .nav_mobile {
      display: none !important; } }
  .nav_mobile h4 {
    font-size: 1.2em;
    font-weight: 800;
    text-transform: uppercase; }
  .nav_mobile .nav_menu > .menu_item:not(:last-child) {
    border-bottom: 1px solid rgba(0, 0, 0, 0.3); }
  .nav_mobile .nav_menu > .menu_item:not(:first-child) {
    border-top: 1px solid rgba(255, 255, 255, 0.2); }
  .nav_mobile .nav_menu > .menu_item.active .menu_link {
    background-color: #fff;
    color: #2b3990; }
    .nav_mobile .nav_menu > .menu_item.active .menu_link:hover, .nav_mobile .nav_menu > .menu_item.active .menu_link:focus {
      outline: none;
      background-color: #fff;
      color: #2b3990; }
  .nav_mobile .nav_menu > .menu_item.open > .menu_link {
    background-color: rgba(255, 255, 255, 0.2); }
  .nav_mobile .nav_menu > .menu_item.open .dropdown_menu {
    background-color: rgba(255, 255, 255, 0.2); }
  .nav_mobile .nav_menu > .menu_item > .menu_link {
    padding: 12px 10px;
    font-weight: 600;
    background-color: transparent;
    color: #fff; }
    .nav_mobile .nav_menu > .menu_item > .menu_link:hover, .nav_mobile .nav_menu > .menu_item > .menu_link:focus {
      outline: none;
      background-color: #2b3990;
      color: #fff; }
  .nav_mobile .dropdown_menu .menu_item {
    font-size: 0.875em; }
  .nav_mobile .dropdown_menu .menu_link {
    background-color: transparent;
    color: #fff; }
    .nav_mobile .dropdown_menu .menu_link:hover, .nav_mobile .dropdown_menu .menu_link:focus {
      outline: none;
      background-color: rgba(0, 0, 0, 0.2);
      color: #fff; }
  .nav_mobile .mobile-toggle {
    font-size: 1.2em;
    color: #fff;
    text-decoration: none; }
    .nav_mobile .mobile-toggle img {
      display: inline-block;
      width: 0.8em;
      margin-right: 10px; }

/*@import "modules/menus/nav_top";
@import "modules/menus/nav--list";
@import "modules/menus/nav--pills"; */
.coverage {
  clear: both;
  padding: 30px; }
  .coverage .coverage_title {
    margin: 0 0 0.75rem;
    font-size: 1rem;
    font-weight: 800;
    text-transform: uppercase; }
  .coverage p {
    margin-bottom: 0.5rem;
    line-height: 1.4em; }
    .coverage p:last-of-type {
      margin-bottom: 0; }
  .coverage .coverage_county {
    font-size: 0.95rem;
    font-weight: 600; }
  .coverage .coverage_city {
    font-size: 0.9rem; }

.block.coverage {
  border: none;
  padding: 2em 0 1.75em;
  text-align: center;
  box-shadow: none; }
  .block.coverage .row {
    justify-content: center; }
  .block.coverage .coverage_map {
    padding-bottom: 1.5em; }
  @media (min-width: 992px) {
    .block.coverage .wrap {
      flex: 0 0 75%;
      max-width: 75%; } }
  @media (min-width: 1200px) {
    .block.coverage .wrap {
      flex: 0 0 66.66667%;
      max-width: 66.66667%; } }
  @media (min-width: 992px) {
    .page_front .block.coverage .row {
      align-items: center; }
    .page_front .block.coverage .coverage_map {
      flex: 0 0 50%;
      max-width: 50%;
      padding-bottom: 0; }
    .page_front .block.coverage .coverage_text {
      flex: 0 0 50%;
      max-width: 50%;
      text-align: left; } }
  @media (min-width: 1200px) {
    .page_front .block.coverage .coverage_map {
      flex: 0 0 58.33333%;
      max-width: 58.33333%; }
    .page_front .block.coverage .coverage_text {
      flex: 0 0 41.66667%;
      max-width: 41.66667%;
      text-align: left; } }

.page-title {
  padding: 0;
  color: #fff;
  border-bottom: 5px solid #fff;
  text-shadow: 0 1px 2px rgba(0, 0, 0, 0.4); }
  .page-title .title-wrap {
    padding: 2em 0;
    background-image: linear-gradient(45deg, #d54e0d, #f26622 40%, #f26622 60%, #f58752); }
  .page-title h1 {
    margin: 0;
    line-height: 1em; }
    .page-title h1 small {
      font-size: 80%;
      line-height: 1em;
      letter-spacing: 0.0175em;
      color: #fff; }
  @media (min-width: 768px) {
    .page-title {
      background-size: cover;
      background-position: center center;
      background-repeat: no-repeat;
      padding-top: 11em; }
      .page-title .title-wrap {
        padding: 1em 0;
        background-image: linear-gradient(45deg, rgba(213, 78, 13, 0.7), rgba(242, 102, 34, 0.7) 40%, rgba(242, 102, 34, 0.7) 60%, rgba(245, 135, 82, 0.7)); } }
  @media (min-width: 992px) {
    .page-title {
      padding-top: 14em; } }
  @media (min-width: 1200px) {
    .page-title {
      padding-top: 17em;
      background-size: 1400px auto; } }
  @media (min-width: 768px) {
    .no_pt_img .page-title {
      background-image: linear-gradient(45deg, #d54e0d, #f26622 40%, #f26622 60%, #f58752); }
      .no_pt_img .page-title .title-wrap {
        background-image: none;
        background-color: rgba(50, 55, 59, 0.2); } }
  @media (min-width: 768px) {
    .service_septic_pumping .page-title {
      background-image: url("/_/images/page-titles/septic-cleaning.jpg"); } }
  @media (min-width: 768px) {
    .service_septic_installation .page-title {
      background-image: url("/_/images/page-titles/septic-installation.jpg"); } }
  @media (min-width: 768px) {
    .service_septic_inspection .page-title {
      background-image: url("/_/images/page-titles/septic-inspection.jpg"); } }
  @media (min-width: 768px) {
    .page_reviews .page-title {
      background-image: url("/_/images/page-titles/reviews.jpg"); } }
  @media (min-width: 768px) {
    .page_about .page-title {
      background-image: url("/_/images/page-titles/about.jpg"); } }
  @media (min-width: 768px) {
    .page_contact .page-title {
      background-image: url("/_/images/page-titles/contact.jpg"); } }
  @media (min-width: 768px) {
    .sa_north_port .page-title {
      background-image: url("/_/images/page-titles/sa_north_port.jpg"); } }
  @media (min-width: 768px) {
    .sa_port_charlotte .page-title {
      background-image: url("/_/images/page-titles/sa_port_charlotte.jpg"); } }
  @media (min-width: 768px) {
    .sa_punta_gorda .page-title {
      background-image: url("/_/images/page-titles/sa_punta_gorda.jpg"); } }

.review p {
  padding: 1em;
  font-size: 1rem; }

.review .review_stars {
  display: block;
  margin: 0 0 0.5em;
  max-width: 150px; }

.review cite {
  display: block;
  margin-top: 0.5em;
  font-weight: 600; }

.main_reviews .review {
  margin: 0 0 15px;
  border: 3px solid #2b3990;
  background-color: #fff; }
  .main_reviews .review p {
    margin: 0; }
  .main_reviews .review:nth-child(even) {
    border-color: #f26622; }

.branding_logo {
  display: block;
  margin: 0 auto;
  line-height: 1em;
  text-align: center;
  color: #495057;
  text-decoration: none; }

.branding_slogan {
  display: block;
  max-width: 360px;
  margin: 0.5em auto 1em;
  line-height: 1em; }

.contact_bg {
  max-width: 60px;
  margin: 0 auto 0.5em; }

.contact_call {
  margin: 0;
  font-size: 1rem;
  line-height: 1em; }

.contact_title {
  display: block;
  margin-bottom: 0.125em;
  font-size: 1rem;
  font-weight: 800;
  line-height: 1em;
  letter-spacing: -0.0225em;
  text-transform: uppercase;
  color: #f26622; }

.contact_phone {
  display: inline-block;
  font-size: 1.8rem;
  font-weight: 800;
  line-height: 1em;
  letter-spacing: -0.04em;
  color: #2b3990; }
  .contact_phone:active {
    color: #2b3990; }
  .contact_phone:hover, .contact_phone:focus {
    color: #2b3990;
    outline: none; }

.contact_note {
  display: block;
  margin: 0.125em 0 0;
  font-size: 0.95rem;
  font-weight: 400;
  font-style: italic;
  line-height: 1.1em;
  letter-spacing: -0.0125em; }
  .contact_note b {
    display: block; }

.banner_visit {
  font-size: 15px;
  line-height: 1em; }

.visit_address {
  line-height: 1.2em; }
  .visit_address a {
    display: inline-block;
    padding: 0 1.75em;
    text-decoration: none;
    background-image: url("/_/images/icons/map-marker-alt.svg"), url("/_/images/icons/angle-right.svg");
    background-size: 1.2em, 1.2em;
    background-repeat: no-repeat, no-repeat;
    background-position: left center, right center;
    color: #495057; }
    .visit_address a:hover {
      background-image: url("/_/images/icons/map-marker-alt.svg"), url("/_/images/icons/angle-right-hover.svg");
      background-size: 1.2em, 1.2em;
      background-repeat: no-repeat, no-repeat;
      background-position: left center, right center; }
  .visit_address .visit_street {
    display: block; }

.banner_menu {
  border-bottom: 3px solid #fff;
  box-shadow: 0 0 18px 0 rgba(0, 0, 0, 0.8);
  background-image: linear-gradient(to right, #3749b7, #2b3990, #1f2969); }
  .banner_menu .nav_menu > .menu_item {
    display: block;
    float: left;
    width: 20%;
    transform: skew(-13deg); }
    .banner_menu .nav_menu > .menu_item:not(:last-child) {
      border-right: 3px solid #f2f2f2; }
  .banner_menu .menu_link {
    font-size: 0.925rem;
    letter-spacing: 0.025em; }
  .banner_menu .nav_menu > .menu_item > .menu_link {
    -webkit-transition: all ease-in-out 300ms;
    -moz-transition: all ease-in-out 300ms;
    -ms-transition: all ease-in-out 300ms;
    -o-transition: all ease-in-out 300ms;
    transition: all ease-in-out 300ms;
    display: block;
    padding: 18px 15px;
    font-size: 15px;
    line-height: 1em;
    text-align: center;
    text-transform: uppercase;
    background: transparent;
    color: #fff; }
    .banner_menu .nav_menu > .menu_item > .menu_link:hover {
      -webkit-transition: all ease-in-out 300ms;
      -moz-transition: all ease-in-out 300ms;
      -ms-transition: all ease-in-out 300ms;
      -o-transition: all ease-in-out 300ms;
      transition: all ease-in-out 300ms;
      background: #f26622; }
  .banner_menu .nav_menu > .menu_item.active > .menu_link {
    background-color: #f2f2f2;
    color: #f26622;
    font-weight: 700; }
  .banner_menu .nav_menu > .menu_item.open > .dropdown-toggle {
    background: #f26622; }
    .banner_menu .nav_menu > .menu_item.open > .dropdown-toggle:hover {
      background: #f26622; }
  .banner_menu .dropdown_menu {
    transform: skew(13deg);
    margin-left: 17px;
    z-index: -1;
    padding: 0.5em;
    border-top: 5px solid #fff;
    background-color: #f2f2f2;
    background-color: #dee2e6;
    box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.5); }
    .banner_menu .dropdown_menu .menu_item:not(:last-child) {
      border-bottom: 1px solid rgba(0, 0, 0, 0.2); }
    .banner_menu .dropdown_menu .menu_item:not(:first-child) {
      border-top: 1px solid white; }
    .banner_menu .dropdown_menu .menu_item.active .menu_link:link, .banner_menu .dropdown_menu .menu_item.active .menu_link:visited, .banner_menu .dropdown_menu .menu_item.active .menu_link:active, .banner_menu .dropdown_menu .menu_item.active .menu_link:hover, .banner_menu .dropdown_menu .menu_item.active .menu_link:focus {
      background-color: #f26622;
      color: #fff; }
      .banner_menu .dropdown_menu .menu_item.active .menu_link:link:before, .banner_menu .dropdown_menu .menu_item.active .menu_link:visited:before, .banner_menu .dropdown_menu .menu_item.active .menu_link:active:before, .banner_menu .dropdown_menu .menu_item.active .menu_link:hover:before, .banner_menu .dropdown_menu .menu_item.active .menu_link:focus:before {
        width: 0; }
    .banner_menu .dropdown_menu .menu_link:link, .banner_menu .dropdown_menu .menu_link:visited, .banner_menu .dropdown_menu .menu_link:active {
      color: #2b3990; }
    .banner_menu .dropdown_menu .menu_link:before {
      -webkit-transition: all ease-in-out 150ms;
      -moz-transition: all ease-in-out 150ms;
      -ms-transition: all ease-in-out 150ms;
      -o-transition: all ease-in-out 150ms;
      transition: all ease-in-out 150ms;
      position: relative;
      top: 0.1em;
      display: inline-block;
      width: 0;
      height: 0.9em;
      background: transparent url("/_/images/icons/angle-right-secondary.svg") left center no-repeat;
      background-size: contain;
      content: ""; }
    .banner_menu .dropdown_menu .menu_link:hover, .banner_menu .dropdown_menu .menu_link:focus {
      background-color: rgba(255, 255, 255, 0.9);
      color: #f26622; }
      .banner_menu .dropdown_menu .menu_link:hover:before, .banner_menu .dropdown_menu .menu_link:focus:before {
        -webkit-transition: all ease-in-out 150ms;
        -moz-transition: all ease-in-out 150ms;
        -ms-transition: all ease-in-out 150ms;
        -o-transition: all ease-in-out 150ms;
        transition: all ease-in-out 150ms;
        width: 1em; }
  @media (min-width: 992px) {
    .banner_menu .nav_menu {
      max-width: 780px;
      margin: 0 auto;
      padding: 0 60px; } }

.info_company {
  line-height: 1em;
  text-align: center; }
  .info_company .company_name {
    display: block;
    font-size: 1.2em; }
  .info_company .phone {
    font-size: 1.7em;
    line-height: 1.5em;
    font-weight: 800;
    color: #fff; }
  .info_company .company_hours {
    display: block;
    margin: 0 0 0.5em; }
  .info_company .company_address {
    margin: 0 0 0.5em; }
  .info_company .company_street {
    display: block; }
  .info_company .company_license small {
    display: block; }
  @media (min-width: 576px) {
    .info_company {
      text-align: left; }
      .info_company .company_address {
        margin-bottom: 0; } }

.info_copyright {
  font-size: 0.85rem;
  line-height: 1.3em;
  text-align: center; }
  .info_copyright a {
    font-size: 1rem;
    font-weight: 600;
    line-height: 1em;
    text-decoration: none;
    color: #fff; }
  @media (min-width: 768px) {
    .info_copyright ul > li {
      display: inline-block;
      margin-bottom: 0 !important; }
      .info_copyright ul > li:nth-child(2) {
        padding: 0 1em; } }
  .page_front .info_copyright {
    padding-top: 1em; }
    @media (min-width: 576px) {
      .page_front .info_copyright {
        text-align: right; } }
    @media (min-width: 768px) {
      .page_front .info_copyright {
        padding-top: 0; }
        .page_front .info_copyright ul > li {
          display: block;
          margin-bottom: 0.25em !important; }
          .page_front .info_copyright ul > li:nth-child(2) {
            padding: 0; } }

/* Slider */
.slick-slider {
  position: relative;
  display: block;
  box-sizing: border-box;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  -webkit-touch-callout: none;
  -khtml-user-select: none;
  -ms-touch-action: pan-y;
  touch-action: pan-y;
  -webkit-tap-highlight-color: transparent; }

.slick-list {
  position: relative;
  display: block;
  overflow: hidden;
  margin: 0;
  padding: 0; }

.slick-list:focus {
  outline: none; }

.slick-list.dragging {
  cursor: pointer;
  cursor: hand; }

.slick-slider .slick-track,
.slick-slider .slick-list {
  -webkit-transform: translate3d(0, 0, 0);
  -moz-transform: translate3d(0, 0, 0);
  -ms-transform: translate3d(0, 0, 0);
  -o-transform: translate3d(0, 0, 0);
  transform: translate3d(0, 0, 0); }

.slick-track {
  position: relative;
  top: 0;
  left: 0;
  bottom: 0;
  display: block;
  margin-left: auto;
  margin-right: auto; }

.slick-track:before,
.slick-track:after {
  display: table;
  content: ''; }

.slick-track:after {
  clear: both; }

.slick-loading .slick-track {
  visibility: hidden; }

.slick-slide {
  display: none;
  float: left;
  height: 100%;
  min-height: 1px; }

.slick-slide img {
  display: block;
  margin: 0; }

.slick-slide.slick-loading img {
  display: none; }

.slick-slide.dragging img {
  pointer-events: none; }

.slick-initialized .slick-slide {
  display: block; }

.slick-loading .slick-slide {
  visibility: hidden; }

.slick-vertical .slick-slide {
  display: block;
  height: auto;
  border: 1px solid transparent; }

.slick-arrow.slick-hidden {
  display: none; }

/* Slider */
.slick-loading .slick-list {
  background: #fff url("/_/images/layout/ajax-loader.gif") center center no-repeat; }

/* Arrows */
.slick-prev,
.slick-next {
  -webkit-transition: all ease-in-out 200ms;
  -moz-transition: all ease-in-out 200ms;
  -ms-transition: all ease-in-out 200ms;
  -o-transition: all ease-in-out 200ms;
  transition: all ease-in-out 200ms;
  z-index: 95000;
  position: absolute;
  display: block;
  height: 100%;
  top: 0;
  bottom: 0;
  width: 55px;
  font-size: 0px;
  line-height: 0px;
  cursor: pointer;
  background-color: rgba(0, 0, 0, 0.8);
  background-size: 50%;
  background-position: center center;
  background-repeat: no-repeat;
  color: transparent;
  padding: 0;
  border: none;
  outline: none;
  opacity: 0; }
  .slick-prev:hover,
  .slick-next:hover {
    -webkit-transition: all ease-in-out 200ms;
    -moz-transition: all ease-in-out 200ms;
    -ms-transition: all ease-in-out 200ms;
    -o-transition: all ease-in-out 200ms;
    transition: all ease-in-out 200ms;
    outline: none;
    opacity: 0.6; }

.slick-prev {
  left: 0;
  background-image: url("/_/images/icons/angle-left.svg");
  backgroud-position: center center; }

.slick-next {
  right: 0;
  background-image: url("/_/images/icons/angle-right.svg"); }

.slideshow .slide_caption {
  line-height: 1em;
  padding: 15px;
  text-shadow: 0 1px 1px rgba(0, 0, 0, 0.3);
  background-color: #32373b;
  color: #fff; }

.slideshow .caption_wrap {
  max-width: 1100px;
  margin: 0 auto; }

.slideshow .caption_title {
  margin: 0 0 0.5em;
  font-size: 1.2rem;
  font-weight: 800;
  line-height: 1em; }

.slideshow .caption_text {
  margin: 0;
  font-size: 0.95rem;
  line-height: 1.2em; }

.slideshow .caption_text + .caption_text {
  margin-top: 0.5em; }

@media (max-width: 575px) {
  .slideshow .slick-prev,
  .slideshow .slick-next {
    visibility: hidden; } }

@media (min-width: 576px) {
  .slideshow .slide_caption {
    padding: 15px 70px;
    padding: 15px 85px; }
  .slideshow .caption_title {
    font-size: 1.4rem; }
  .slideshow .caption_text {
    font-size: 1rem; } }

.slideshow_jumbo {
  margin: 0 auto;
  line-height: 0px; }
  .slideshow_jumbo .slide {
    z-index: 9500; }
  .slideshow_jumbo [class*="slide_placeholder"] {
    display: none;
    background-position: center center;
    background-size: cover; }
  .slideshow_jumbo .slide_caption {
    padding: 15px;
    line-height: 1em;
    background-color: rgba(242, 102, 34, 0.8);
    color: #fff; }
  .slideshow_jumbo .caption_title {
    margin: 0;
    font-size: 1rem;
    font-style: normal;
    font-weight: 900;
    font-family: "Montserrat", "Helvetica Neue", Helvetica, Arial, sans-serif;
    line-height: 1em;
    letter-spacing: 0;
    text-transform: uppercase; }
  @media (max-width: 767px) {
    .slideshow_jumbo .slide_placeholder--sm {
      display: block; } }
  @media (min-width: 768px) and (max-width: 991px) {
    .slideshow_jumbo .slide_placeholder--md {
      display: block; } }
  @media (min-width: 992px) and (max-width: 1199px) {
    .slideshow_jumbo .slide_placeholder--lg {
      display: block; } }
  @media (min-width: 1200px) {
    .slideshow_jumbo .slide_placeholder--xl {
      display: block; } }
  .slideshow_jumbo .slide--01 .slide_placeholder--sm {
    background-image: url("/_/images/slides/sm_01.jpg"); }
  .slideshow_jumbo .slide--01 .slide_placeholder--md {
    background-image: url("/_/images/slides/md_01.jpg"); }
  .slideshow_jumbo .slide--01 .slide_placeholder--lg {
    background-image: url("/_/images/slides/lg_01.jpg"); }
  .slideshow_jumbo .slide--01 .slide_placeholder--xl {
    background-image: url("/_/images/slides/xl_01.jpg"); }
  .slideshow_jumbo .slide--02 .slide_placeholder--sm {
    background-image: url("/_/images/slides/sm_02.jpg"); }
  .slideshow_jumbo .slide--02 .slide_placeholder--md {
    background-image: url("/_/images/slides/md_02.jpg"); }
  .slideshow_jumbo .slide--02 .slide_placeholder--lg {
    background-image: url("/_/images/slides/lg_02.jpg"); }
  .slideshow_jumbo .slide--02 .slide_placeholder--xl {
    background-image: url("/_/images/slides/xl_02.jpg"); }
  .slideshow_jumbo .slide--03 .slide_placeholder--sm {
    background-image: url("/_/images/slides/sm_03.jpg"); }
  .slideshow_jumbo .slide--03 .slide_placeholder--md {
    background-image: url("/_/images/slides/md_03.jpg"); }
  .slideshow_jumbo .slide--03 .slide_placeholder--lg {
    background-image: url("/_/images/slides/lg_03.jpg"); }
  .slideshow_jumbo .slide--03 .slide_placeholder--xl {
    background-image: url("/_/images/slides/xl_03.jpg"); }
  .slideshow_jumbo .slide--04 .slide_placeholder--sm {
    background-image: url("/_/images/slides/sm_04.jpg"); }
  .slideshow_jumbo .slide--04 .slide_placeholder--md {
    background-image: url("/_/images/slides/md_04.jpg"); }
  .slideshow_jumbo .slide--04 .slide_placeholder--lg {
    background-image: url("/_/images/slides/lg_04.jpg"); }
  .slideshow_jumbo .slide--04 .slide_placeholder--xl {
    background-image: url("/_/images/slides/xl_04.jpg"); }
  .slideshow_jumbo .slide--05 .slide_placeholder--sm {
    background-image: url("/_/images/slides/sm_05.jpg"); }
  .slideshow_jumbo .slide--05 .slide_placeholder--md {
    background-image: url("/_/images/slides/md_05.jpg"); }
  .slideshow_jumbo .slide--05 .slide_placeholder--lg {
    background-image: url("/_/images/slides/lg_05.jpg"); }
  .slideshow_jumbo .slide--05 .slide_placeholder--xl {
    background-image: url("/_/images/slides/xl_05.jpg"); }
  .slideshow_jumbo .slide--06 .slide_placeholder--sm {
    background-image: url("/_/images/slides/sm_06.jpg"); }
  .slideshow_jumbo .slide--06 .slide_placeholder--md {
    background-image: url("/_/images/slides/md_06.jpg"); }
  .slideshow_jumbo .slide--06 .slide_placeholder--lg {
    background-image: url("/_/images/slides/lg_06.jpg"); }
  .slideshow_jumbo .slide--06 .slide_placeholder--xl {
    background-image: url("/_/images/slides/xl_06.jpg"); }
  .slideshow_jumbo .slide--07 .slide_placeholder--sm {
    background-image: url("/_/images/slides/sm_07.jpg"); }
  .slideshow_jumbo .slide--07 .slide_placeholder--md {
    background-image: url("/_/images/slides/md_07.jpg"); }
  .slideshow_jumbo .slide--07 .slide_placeholder--lg {
    background-image: url("/_/images/slides/lg_07.jpg"); }
  .slideshow_jumbo .slide--07 .slide_placeholder--xl {
    background-image: url("/_/images/slides/xl_07.jpg"); }
  .slideshow_jumbo .slide--08 .slide_placeholder--sm {
    background-image: url("/_/images/slides/sm_08.jpg"); }
  .slideshow_jumbo .slide--08 .slide_placeholder--md {
    background-image: url("/_/images/slides/md_08.jpg"); }
  .slideshow_jumbo .slide--08 .slide_placeholder--lg {
    background-image: url("/_/images/slides/lg_08.jpg"); }
  .slideshow_jumbo .slide--08 .slide_placeholder--xl {
    background-image: url("/_/images/slides/xl_08.jpg"); }
  .slideshow_jumbo .slide--09 .slide_placeholder--sm {
    background-image: url("/_/images/slides/sm_09.jpg"); }
  .slideshow_jumbo .slide--09 .slide_placeholder--md {
    background-image: url("/_/images/slides/md_09.jpg"); }
  .slideshow_jumbo .slide--09 .slide_placeholder--lg {
    background-image: url("/_/images/slides/lg_09.jpg"); }
  .slideshow_jumbo .slide--09 .slide_placeholder--xl {
    background-image: url("/_/images/slides/xl_09.jpg"); }
  .slideshow_jumbo .slide--10 .slide_placeholder--sm {
    background-image: url("/_/images/slides/sm_10.jpg"); }
  .slideshow_jumbo .slide--10 .slide_placeholder--md {
    background-image: url("/_/images/slides/md_10.jpg"); }
  .slideshow_jumbo .slide--10 .slide_placeholder--lg {
    background-image: url("/_/images/slides/lg_10.jpg"); }
  .slideshow_jumbo .slide--10 .slide_placeholder--xl {
    background-image: url("/_/images/slides/xl_10.jpg"); }

.reviews_slider .slick-prev,
.reviews_slider .slick-next {
  width: 30px;
  background-color: transparent;
  background-size: 50%;
  opacity: 0.3; }
  .reviews_slider .slick-prev:hover,
  .reviews_slider .slick-next:hover {
    background-color: #f90;
    opacity: 0.8; }

.reviews_slider .slick-prev:hover {
  background-image: url("/_/images/icons/angle-left-white.svg"); }

.reviews_slider .slick-next:hover {
  background-image: url("/_/images/icons/angle-right-white.svg"); }

@media (min-width: 992px) {
  .reviews_slider .slick-prev,
  .reviews_slider .slick-next {
    width: 44px;
    background-size: 40%; } }

.gallery_slider .slick-prev {
  display: none !important; }

.gallery_slider .slick-prev,
.gallery_slider .slick-next {
  width: 45px;
  background-color: #3749b7;
  background-size: 50%;
  opacity: 1; }
  .gallery_slider .slick-prev:hover,
  .gallery_slider .slick-next:hover {
    background-color: #1f2969;
    opacity: 1; }

.youtube-player {
  position: relative;
  height: 0;
  overflow: hidden;
  max-width: 100%;
  background: #000; }
  .youtube-player.yt-16x9 {
    padding-bottom: 56.23%; }
  .youtube-player.yt-4x3 {
    padding-bottom: 75%; }

.youtube-player iframe {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 100;
  background: transparent; }

.youtube-player img {
  display: block;
  position: absolute;
  z-index: 2000;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  width: 100%;
  max-width: 100%;
  height: auto;
  margin: auto;
  border: none;
  cursor: pointer;
  opacity: 0.7;
  -webkit-transition: all ease-in-out 300ms;
  -moz-transition: all ease-in-out 300ms;
  -ms-transition: all ease-in-out 300ms;
  -o-transition: all ease-in-out 300ms;
  transition: all ease-in-out 300ms; }
  .youtube-player img:hover {
    opacity: 1.0; }

.youtube-player .play {
  position: absolute;
  z-index: 5000;
  height: 72px;
  width: 72px;
  left: 50%;
  top: 50%;
  margin-left: -36px;
  margin-top: -36px;
  background: url("/_/images/layout/play.png") no-repeat;
  cursor: pointer; }
  .youtube-player .play:hover + img {
    opacity: 1.0; }

.widget {
  margin: 0 0 15px;
  padding: 15px;
  font-size: 0.95rem;
  border: 1px solid #ddd;
  background-color: #fff; }
  .widget > *:last-child {
    margin-bottom: 0; }
  .widget.widget-default .widget-title {
    color: #495057; }
  .widget.widget-text {
    border-color: #495057; }
    .widget.widget-text .widget-title {
      background-color: #495057; }
  .widget.widget-primary {
    border-color: #2b3990; }
    .widget.widget-primary .widget-title {
      background-color: #2b3990; }
  .widget.widget-secondary {
    border-color: #f26622; }
    .widget.widget-secondary .widget-title {
      background-color: #f26622; }
  .widget.widget-highlight {
    border-color: #28a1c5; }
    .widget.widget-highlight .widget-title {
      background-color: #28a1c5; }
  .widget.widget-link {
    border-color: #17a2b8; }
    .widget.widget-link .widget-title {
      background-color: #17a2b8; }
  .widget.widget-review {
    border-color: #f90; }
    .widget.widget-review .widget-title {
      background-color: #f90; }
  .widget.widget-success {
    border-color: #28a745; }
    .widget.widget-success .widget-title {
      background-color: #28a745; }
  .widget.widget-danger {
    border-color: #dc3545; }
    .widget.widget-danger .widget-title {
      background-color: #dc3545; }
  .widget.widget-info {
    border-color: #6610f2; }
    .widget.widget-info .widget-title {
      background-color: #6610f2; }
  .widget.widget-warning {
    border-color: #ffc107; }
    .widget.widget-warning .widget-title {
      background-color: #ffc107; }

.widget-title {
  font-size: 1.125rem;
  font-weight: 800;
  text-transform: uppercase; }

.widget-title:not(.plain) {
  margin: -15px -15px 15px;
  padding: 12px 15px;
  background-color: #ddd;
  color: #fff; }

.widget-title.plain {
  margin: 0 0 5px; }

.widget_plain {
  padding: 0;
  border: none;
  box-shadow: none;
  background-color: transparent; }
  .widget_plain .widget-title {
    margin: 0 0 15px;
    padding: 0px;
    background-color: transparent;
    color: inherit; }

.widget_titled {
  padding: 0; }
  .widget_titled .widget-title {
    margin: 0; }

@media (min-width: 768px) and (max-width: 991px) {
  .widget-rwreviews {
    overflow: hidden; }
    .widget-rwreviews p {
      float: left;
      width: 49%; }
      .widget-rwreviews p:nth-child(odd) {
        clear: both; }
      .widget-rwreviews p:nth-child(even) {
        float: right; } }

.widget_hours li {
  margin: 0 !important;
  padding: 0.6em 0.5em;
  line-height: 1em; }
  .widget_hours li:hover {
    background-color: rgba(0, 0, 0, 0.1); }

.widget_hours b {
  display: inline-block;
  width: 110px; }

.widget_hours li:not(:last-child) {
  border-bottom: 1px solid #ccc; }

.widget_map {
  padding: 5px 5px 10px;
  border: 1px solid #ccc;
  background-color: #fff; }
  .widget_map .embed-responsive {
    padding-bottom: 56.27%; }
  .widget_map address {
    margin: 0;
    padding: 10px 5px 5px;
    line-height: 1.2em; }
    .widget_map address strong,
    .widget_map address .company_street {
      display: block; }
    .widget_map address strong {
      margin-bottom: 5px;
      font-size: 1.1rem;
      font-weight: 800;
      letter-spacing: -0.0325em;
      text-transform: uppercase; }
  .widget_map p {
    padding: 0 5px; }
  .widget_map .btn {
    background-color: #EA4335;
    border-color: #d62516; }
    .widget_map .btn:hover, .widget_map .btn:focus {
      background-color: #619DE5;
      border-color: #3582de; }
  @media (min-width: 576px) and (max-width: 767px) {
    .widget_map {
      overflow: hidden; }
      .widget_map address {
        display: inline-block;
        margin-right: 1em;
        float: left; }
      .widget_map p {
        margin-top: 1.25em; } }
  @media (min-width: 768px) and (max-width: 991px) {
    .widget_map {
      overflow: hidden; }
      .widget_map address {
        display: inline-block;
        margin-right: 1em;
        float: left; }
      .widget_map p {
        margin-top: 1.25em; } }
  @media (min-width: 992px) {
    .widget_map .embed-responsive {
      padding-bottom: 75%; } }

@media (max-width: 991px) {
  .widget_sm {
    max-width: 360px;
    margin: 0 auto 1em; } }

.widget_feedback {
  text-align: center; }
  .widget_feedback .widget-title {
    background-color: #495057; }
  .widget_feedback p {
    line-height: 1em;
    margin-bottom: 0.5em; }
    .widget_feedback p small {
      display: block;
      margin: 0.25em 0 0;
      font-size: 0.85rem;
      font-weight: 700;
      text-transform: uppercase; }
  .widget_feedback .btn {
    margin-bottom: 0 !important; }
  .widget_feedback > *:last-child {
    margin-bottom: 0 !important; }

.banner {
  padding: 10px 0 1.5em;
  position: relative;
  z-index: 8000;
  text-align: center;
  border-bottom: 10px solid #2b3990;
  background-color: #f2f2f2;
  background: linear-gradient(145deg, #fcfcfc 50%, #f2f2f2 50%);
  color: #495057; }
  .banner .mobile_button {
    padding-left: 10px; }
  .banner .branding_logo {
    max-width: 320px;
    margin-bottom: 0.5em; }
  .banner .banner_contact {
    position: relative; }
  @media (min-width: 576px) and (max-width: 767px) {
    .banner .branding_logo {
      margin-top: -30px; } }
  @media (max-width: 767px) {
    .banner {
      box-shadow: 0 1px 15px 0 rgba(0, 0, 0, 0.9); } }
  @media (min-width: 768px) {
    .banner {
      padding: 1em 0;
      border: none;
      background: linear-gradient(168deg, #fefefe 49.5%, #f2f2f2 49.5%); }
      .banner .branding_logo {
        display: inline-block;
        margin: 0; }
      .banner .banner_contact {
        text-align: right; }
      .banner .branding_slogan {
        margin: 0 auto; } }
  @media (min-width: 992px) {
    .banner {
      padding: 1em 0 1em;
      background: linear-gradient(170deg, #fefefe 45%, #f2f2f2 45%); }
      .banner .banner_branding {
        text-align: left; }
      .banner .branding_logo {
        max-width: 260px; } }

.content {
  padding: 2em 0; }

/* .page_front .content {
	padding-top: 2em;
	@include media-breakpoint-up(lg) {
		.main { padding-top: 1em; }
	}
} */
.main {
  /* 	@include media-breakpoint-only(lg) {
		.img--right,
		.img--left {
			float: none;
			margin-left: 0;
			width: auto;
		}
	} */ }

@media (max-width: 991px) {
  .sidebar {
    padding-top: 15px; } }

@media (min-width: 992px) {
  .sidebar .form_submit input {
    display: block;
    width: 100%; } }

@media (min-width: 992px) and (max-width: 1199px) {
  .sidebar .form_spam {
    position: relative;
    overflow: hidden;
    height: 75px; }
    .sidebar .form_spam .g-recaptcha {
      -webkit-transform: scale(0.85);
      -moz-transform: scale(0.85);
      -o-transform: scale(0.85);
      transform: scale(0.85);
      position: absolute;
      top: 0;
      left: -8px; } }

.sidebar > * {
  margin-bottom: 15px; }

.site_info {
  padding: 2em 0;
  font-size: 0.95em;
  border-top: 5px solid #fff;
  background-color: #1f2969;
  color: #fff; }

.front_jumbo {
  padding: 2em 0;
  background-color: #2b3990;
  background-image: url("/_/images/layout/jumbo_bg-sm.jpg");
  background-repeat: no-repeat;
  background-position: center center;
  background-size: cover; }
  @media (min-width: 992px) {
    .front_jumbo {
      background-image: url("/_/images/layout/jumbo_bg-lg.jpg"); }
      .front_jumbo .jumbo_marketing {
        flex: 0 0 66.66667%;
        max-width: 66.66667%; }
      .front_jumbo .jumbo_conversion {
        flex: 0 0 33.33333%;
        max-width: 33.33333%;
        padding-left: 0; } }

.jumbo_marketing {
  margin-bottom: 2em;
  font-weight: 700; }
  .jumbo_marketing .slideshow {
    text-align: center;
    border: 3px solid #fff;
    box-shadow: 0 20px 35px -20px rgba(0, 0, 0, 0.8); }
  @media (min-width: 576px) {
    .jumbo_marketing .caption_title {
      font-size: 1.5rem; } }
  @media (min-width: 992px) {
    .jumbo_marketing {
      margin-bottom: 0; }
      .jumbo_marketing .caption_title {
        font-size: 1.7rem; } }

.jumbo_conversion .form_quote {
  overflow: hidden;
  border: 3px solid #fff;
  background-color: rgba(255, 255, 255, 0.9);
  box-shadow: 0 20px 35px -20px rgba(0, 0, 0, 0.8); }

.jumbo_conversion .form_title {
  margin-bottom: 0.5em; }

.jumbo_conversion .form_group {
  padding: 0 0.5em 0.1em; }

@media (min-width: 768px) and (max-width: 991px) {
  .jumbo_conversion .form_title small {
    display: inline;
    font-size: inherit; }
    .jumbo_conversion .form_title small:before {
      display: inline-block;
      padding-right: 0.25em;
      content: "-"; }
  .jumbo_conversion .form_left {
    width: 50%;
    padding-right: 0.25em; }
    .jumbo_conversion .form_left .form_group {
      padding-right: 0; }
  .jumbo_conversion .form_right {
    width: 50%;
    padding-left: 0.25em; }
    .jumbo_conversion .form_right .form_group {
      padding-left: 0; }
  .jumbo_conversion .form_comments textarea {
    height: 133px; } }

@media (min-width: 992px) {
  .jumbo_conversion .btn_submit {
    display: block;
    width: 100%; } }

@media (min-width: 992px) and (max-width: 1199px) {
  .jumbo_conversion .form_quote {
    padding-bottom: 0.4em; }
  .jumbo_conversion .form_comments textarea {
    height: 114px;
    min-height: 0; }
  .jumbo_conversion .form_spam {
    height: 75px; }
    .jumbo_conversion .form_spam .g-recaptcha {
      top: -3px;
      left: -5px;
      transform: scale(0.915); } }

@media (min-width: 1200px) {
  .jumbo_conversion .form_quote {
    padding-bottom: 0.4em; }
  .jumbo_conversion .form_comments textarea {
    height: 113px;
    min-height: 0; } }

.front_title {
  padding: 1em 0;
  border-top: 3px solid #fff;
  background: #2b3990; }
  @media (min-width: 768px) {
    .front_title {
      background-image: linear-gradient(to right, #1f2969, #3749b7 25%, #3749b7 75%, #1f2969); } }
  .front_title.front_emergency {
    background-color: #dc3545; }
    @media (min-width: 768px) {
      .front_title.front_emergency {
        background-image: linear-gradient(to right, #a71d2a, #dc3545 25%, #dc3545 75%, #a71d2a); } }

.front_estimate .estimate_title span {
  display: none; }
  @media (min-width: 992px) {
    .front_estimate .estimate_title span {
      display: inline; } }

.title_title {
  margin: 0;
  font-family: "Montserrat", "Helvetica Neue", Helvetica, Arial, sans-serif;
  font-weight: 900;
  color: #f8f9fa; }
  .title_title p {
    margin: 0;
    font-size: 1.1rem;
    line-height: 1em;
    text-align: center;
    text-transform: uppercase; }
  .title_title strong {
    display: block;
    margin: 0 0 0.325em;
    font-size: 1.3rem;
    line-height: 1em;
    font-weight: 900; }
  .title_title .phone {
    color: inherit; }
  .title_title small {
    display: block;
    margin: 0.5em 0 0;
    font-weight: 700;
    line-height: 1em; }
  @media (min-width: 768px) {
    .title_title p {
      font-size: 1.3rem; }
    .title_title strong {
      font-size: inherit;
      display: inline;
      margin: 0; }
    .title_title small {
      margin-top: 0.25em; } }
  @media (min-width: 992px) {
    .title_title p {
      font-size: 1.5rem; } }

.front_welcome {
  padding: 2.5em 0;
  background-color: #fff;
  text-align: center;
  background: linear-gradient(165deg, #fcfcfc 50%, #f2f2f2 50%); }

.welcome_title h1 {
  margin: 0;
  font-size: 1.6rem;
  line-height: 1em;
  color: #2b3990; }
  .welcome_title h1 small {
    margin-top: 0.125em;
    font-size: 70%;
    line-height: 1em;
    letter-spacing: 0.125em;
    word-spacing: 0.175em;
    text-transform: uppercase;
    color: #868e96; }
  .welcome_title h1:before {
    content: "Top-Rated ";
    color: #f26622; }

.welcome_title p {
  margin: 0.5em 0 0;
  font-size: 1rem;
  font-weight: 700;
  font-family: "Montserrat", "Helvetica Neue", Helvetica, Arial, sans-serif;
  text-transform: uppercase;
  color: #adb5bd; }

@media (min-width: 768px) {
  .welcome_title h1 {
    font-size: 1.9rem; } }

@media (min-width: 992px) {
  .welcome_title h1 {
    font-size: 2.2rem; }
    .welcome_title h1 small {
      font-size: 67.25%; } }

@media (min-width: 768px) {
  .welcome_social {
    justify-content: center; }
    .welcome_social .wrap {
      flex: 0 0 83.33333%;
      max-width: 83.33333%; } }

@media (min-width: 1200px) {
  .welcome_social .wrap {
    flex: 0 0 66.66667%;
    max-width: 66.66667%; } }

.welcome_social .wrap {
  margin: 2em 15px 0;
  border-top: 1px solid #ccc;
  border-bottom: 1px solid #ccc; }

.welcome_social ul {
  display: block;
  width: 100%;
  margin: 0 auto; }
  .welcome_social ul > li {
    display: block;
    float: left;
    width: 33.33333%;
    margin: 0 !important;
    padding: 0 10px; }
    .welcome_social ul > li a {
      -webkit-transition: all ease-in-out 150ms;
      -moz-transition: all ease-in-out 150ms;
      -ms-transition: all ease-in-out 150ms;
      -o-transition: all ease-in-out 150ms;
      transition: all ease-in-out 150ms;
      display: block;
      margin: 0 auto;
      padding: 0.5em;
      max-width: 100px; }
      .welcome_social ul > li a:hover {
        -webkit-transition: all ease-in-out 150ms;
        -moz-transition: all ease-in-out 150ms;
        -ms-transition: all ease-in-out 150ms;
        -o-transition: all ease-in-out 150ms;
        transition: all ease-in-out 150ms;
        padding: 0; }

.welcome_reviews .wreview_title {
  margin: 2.5em 0 1.5em; }

.welcome_reviews .review_slider_wrapper {
  position: relative; }

.welcome_reviews .review_bg {
  display: none;
  max-width: 170px;
  transform: rotate(10deg); }
  @media (min-width: 576px) {
    .welcome_reviews .review_bg {
      display: block;
      position: absolute;
      top: 5px;
      left: 33%;
      opacity: 0.07; } }
  @media (min-width: 768px) {
    .welcome_reviews .review_bg {
      left: 37%; } }
  @media (min-width: 992px) {
    .welcome_reviews .review_bg {
      max-width: 150px;
      top: 0;
      left: 42%; } }
  @media (min-width: 1200px) {
    .welcome_reviews .review_bg {
      left: 43%; } }

.welcome_reviews .review {
  position: relative; }
  .welcome_reviews .review p {
    max-width: 740px;
    margin: 0 auto;
    padding: 0 30px; }
  .welcome_reviews .review .review_stars {
    margin: 0 auto 0.5em; }
  @media (max-width: 767px) {
    .welcome_reviews .review .review_stars {
      margin-top: 1.5em; }
    .welcome_reviews .review.review_first .review_stars {
      margin-top: 0; } }
  @media (min-width: 768px) {
    .welcome_reviews .review {
      padding: 1em; } }
  @media (min-width: 992px) {
    .welcome_reviews .review {
      padding: 1em 1em 0; }
      .welcome_reviews .review p {
        font-size: 1.2rem; } }
  @media (min-width: 1200px) {
    .welcome_reviews .review p {
      max-width: 768px; } }

.welcome_reviews .reviews_more {
  padding-top: 1em; }

.front_services {
  padding: 2em 0;
  text-align: center;
  border-bottom: 1px solid #ccc;
  background-color: #e9ecef; }

.services_title {
  text-align: center; }
  .services_title h2 {
    display: block;
    margin: 0;
    font-size: 1.75rem;
    font-weight: 800;
    text-transform: uppercase;
    letter-spacing: -0.0375em;
    line-height: 1em; }

.feature {
  padding: 60px 0;
  background-size: cover;
  background-position: center center;
  -webkit-transition: all ease-in-out 300ms;
  -moz-transition: all ease-in-out 300ms;
  -ms-transition: all ease-in-out 300ms;
  -o-transition: all ease-in-out 300ms;
  transition: all ease-in-out 300ms; }
  .feature:hover {
    -webkit-transition: all ease-in-out 300ms;
    -moz-transition: all ease-in-out 300ms;
    -ms-transition: all ease-in-out 300ms;
    -o-transition: all ease-in-out 300ms;
    transition: all ease-in-out 300ms; }
  .feature h3 {
    margin: 0;
    padding: 0.5em;
    font-size: 1.275rem;
    font-weight: 600;
    letter-spacing: 0;
    line-height: 1em;
    text-shadow: 0 1px 3px rgba(0, 0, 0, 0.7);
    text-transform: uppercase;
    background-color: rgba(31, 41, 105, 0.9);
    color: #fff; }
    .feature h3 strong {
      display: block;
      font-size: 120%;
      font-weight: 800;
      letter-spacing: -0.0325em;
      line-height: 1em; }
  @media (max-width: 767px) {
    .feature {
      margin-bottom: 15px; } }
  @media (min-width: 768px) and (max-width: 991px) {
    .feature h3 {
      font-size: 1.125rem; }
      .feature h3 strong {
        font-size: 110%;
        letter-spacing: -0.0425em; } }
  @media (min-width: 768px) {
    .feature {
      flex: 0 0 33.33333%;
      max-width: 33.33333%;
      padding: 60px 0;
      border: 3px solid #e9ecef;
      border-top: none;
      border-bottom: none; } }
  @media (min-width: 992px) {
    .feature {
      padding: 90px 0; } }
  @media (min-width: 1200px) {
    .feature {
      padding: 110px 0; } }

.feature_1 {
  background-image: url("/_/images/services/feat_1.jpg"); }
  @media (min-width: 576px) and (max-width: 767px) {
    .feature_1 {
      margin-bottom: 0;
      padding: 90px 0;
      border-bottom: 10px solid #e9ecef; }
      .feature_1 h3 {
        font-size: 1.425rem;
        letter-spacing: 0.015em; }
        .feature_1 h3 strong {
          font-size: 130%; } }
  @media (min-width: 576px) {
    .feature_1 {
      background-image: url("/_/images/services/feat_1-lg.jpg"); } }
  @media (min-width: 768px) {
    .feature_1 {
      margin-bottom: 0; } }

.feature_2 {
  background-image: url("/_/images/services/feat_2.jpg"); }
  .feature_2 h3 {
    background-color: rgba(242, 102, 34, 0.8); }
  @media (min-width: 576px) and (max-width: 767px) {
    .feature_2 {
      flex: 0 0 50%;
      max-width: 50%;
      border-right: 5px solid #e9ecef; } }
  @media (min-width: 576px) {
    .feature_2 {
      margin-bottom: 0;
      background-image: url("/_/images/services/feat_2-lg.jpg"); } }

.feature_3 {
  background-image: url("/_/images/services/feat_3.jpg"); }
  @media (min-width: 576px) and (max-width: 767px) {
    .feature_3 {
      flex: 0 0 50%;
      max-width: 50%;
      border-left: 5px solid #e9ecef; } }
  @media (min-width: 576px) {
    .feature_3 {
      margin-bottom: 0;
      background-image: url("/_/images/services/feat_3-lg.jpg"); } }

.services_more p {
  margin: 1em 0 0; }

.front_about {
  padding: 2em 0 1em; }
  .front_about .about_img .embed-responsive {
    padding-bottom: 56.27%; }
  @media (min-width: 992px) {
    .front_about {
      padding: 1.5em 0; }
      .front_about .row {
        align-items: center; }
      .front_about .about_img {
        flex: 0 0 41.66667%;
        max-width: 41.66667%;
        padding-right: 0; }
        .front_about .about_img .embed-responsive {
          padding-bottom: 75%; }
      .front_about .about_txt {
        flex: 0 0 58.33333%;
        max-width: 58.33333%;
        padding-left: 0; } }
  @media (min-width: 1200px) {
    .front_about .about_img {
      flex: 0 0 50%;
      max-width: 50%; }
    .front_about .about_txt {
      flex: 0 0 50%;
      max-width: 50%; } }

@media (max-width: 575px) {
  .about_img .about_map {
    margin-bottom: 1em; } }

@media (min-width: 576px) {
  .about_img {
    position: relative; }
    .about_img .btn {
      position: absolute;
      top: 10px;
      right: 25px;
      background-color: #EA4335;
      border-color: #d62516; }
      .about_img .btn:hover, .about_img .btn:focus {
        background-color: #ef6e63;
        background-color: #619DE5;
        border-color: #3582de; } }

@media (min-width: 992px) {
  .about_img .btn {
    top: auto;
    right: auto;
    bottom: 22px;
    left: 20px; } }

.about_txt .icar_tech {
  max-width: 200px;
  margin: 0 0 1em;
  padding: 1em 1.5em;
  border: 1px solid #006CB4;
  background-color: #fff;
  font-size: 0.925rem;
  font-weight: 800;
  line-height: 1em;
  letter-spacing: -0.0325em;
  text-align: center;
  text-transform: uppercase; }
  .about_txt .icar_tech img {
    max-width: 195px;
    margin: 0 auto; }

.about_txt ul > li {
  font-weight: 600;
  letter-spacing: -0.0225em; }

@media (min-width: 576px) {
  .about_txt .icar_tech {
    float: right;
    margin: 0 0 0.5em 0.5em; } }

@media (min-width: 768px) and (max-width: 991px) {
  .about_txt .about_title br {
    display: none; }
  .about_txt .icar_tech {
    max-width: none;
    width: 300px;
    margin: 3.5em 0 0 0; } }

@media (min-width: 768px) {
  .about_txt {
    text-align: left; } }

@media (min-width: 992px) {
  .about_txt .about_wrap {
    background-image: none;
    padding-left: 30px; }
  .about_txt .about_title {
    margin-top: 0; }
  .about_txt p {
    margin-bottom: 0; }
  .about_txt ul > li {
    font-size: 1.15rem; } }

@media (min-width: 1200px) {
  .about_txt ul > li {
    font-size: 1.25rem; } }

.about_title {
  margin: 1em 0 0.5em;
  font-size: 1.4rem;
  font-weight: 800;
  font-style: italic;
  letter-spacing: -0.0475em;
  text-transform: uppercase; }
  @media (min-width: 576px) {
    .about_title {
      font-size: 1.7rem; } }
