.banner_menu {

	border-bottom: 3px solid $white;
	box-shadow: 0 0 18px 0 rgba(0,0,0,0.8);
	background-image: linear-gradient( to right, theme(primary, light), theme(primary, base), theme(primary, dark) );

	.nav_menu > .menu_item {
		display: block;
		float: left;
		width: percentage(1/5);
		&:not(:last-child) { border-right: 3px solid #f2f2f2 }
		transform: skew(-13deg);
	}

		.menu_link {
			font-size: 0.925rem;
			letter-spacing: 0.025em;
		}

	.nav_menu > .menu_item > .menu_link {
		@include transition(all ease-in-out 300ms);
		display: block;
		padding: 18px 15px;
		font-size: 15px;
		line-height: 1em;
		text-align: center;
		text-transform: uppercase;
		background: transparent;
		color: $white;
		&:hover {
			@include transition(all ease-in-out 300ms);
			background: theme(secondary, base);
		}
	}

	.nav_menu > .menu_item.active > .menu_link {
		background-color: #f2f2f2;
		color: theme(secondary, base);
		font-weight: 700;
	}


	.nav_menu > .menu_item.open > .dropdown-toggle {
		background: theme(secondary, base);
		&:hover {
			background: theme(secondary, base);
		}
	}

		.dropdown_menu {
			transform: skew(13deg);
			margin-left: 17px;
			z-index: -1;
			padding: 0.5em;
			//border: 1px solid #aaa;
			border-top: 5px solid $white;
			background-color: #f2f2f2;
			background-color: $gray-300;
			box-shadow: 0 2px 5px 0 rgba(0,0,0,0.5);
			.menu_item:not(:last-child) { border-bottom: 1px solid rgba(0,0,0,0.2) }
			.menu_item:not(:first-child) { border-top: 1px solid rgba(255,255,255,1) }
			.menu_item.active .menu_link {
				&:link, &:visited, &:active, &:hover, &:focus {
					background-color: theme(secondary, base);
					color: $white;
					&:before {
						width: 0;
					}
				}
			}
			.menu_link {
				&:link, &:visited, &:active {
					color: theme(primary, base);
				}
				&:before {
					@include transition(all ease-in-out 150ms);
					position: relative;
					top: 0.1em;
					display: inline-block;
					width: 0;
					height: 0.9em;
					background: transparent url("/_/images/icons/angle-right-secondary.svg") left center no-repeat;
					background-size: contain;
					content: "";
				}
				&:hover, &:focus {
					background-color: rgba($white,0.9);
					color: theme(secondary, base);
					&:before {
						@include transition(all ease-in-out 150ms);
						width: 1em;
					}
				}
			}
		}

	@include media-breakpoint-up(lg) {
		.nav_menu {
			max-width: 780px;
			margin: 0 auto;
			padding: 0 60px;
		}
	}

}



