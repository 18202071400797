html {

	font-size: 16px;
	font-family: $font-family-default;
	line-height: 1.525em;
	-webkit-text-size-adjust: 100%;

	@include media-breakpoint-only(md) { font-size: 18px; }
	@include media-breakpoint-up(lg) { font-size: 16px;	}
	@include media-breakpoint-up(xl) { font-size: 17px;	}

}

body {
	font-size: 1rem;
	line-height: inherit;
}

p {
	margin: 0 0 1em;
	&.lead {
		font-size: 1.175rem;
		line-height: (1.5 * 1.02) * 1em;
	}
}


.b0 { font-weight: 400; }
b, strong, .b1 { font-weight: 700; }
.bolder, .strong, .b2 { font-weight: 700; }

address {
	margin-bottom: 1em;
	font-style: normal;
}

