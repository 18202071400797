body {

	&:not(.page_front) {
			
		@include media-breakpoint-up(md) {

			height: 100%;
			padding-bottom: 83px;

			.site_info {
				position: absolute;
				bottom: 0;
				width: 100%;
			}

		}
		
	}


}