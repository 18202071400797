
	.branding_logo {
		display: block;
		margin: 0 auto;
		line-height: 1em;
		text-align: center;
		color: theme(text, base);
		text-decoration: none;
	}

		.branding_slogan {
			display: block;
			max-width: 360px;
			margin: 0.5em auto 1em;
			line-height: 1em;
		}
